import React, { type ForwardedRef } from "react";
import { type HTMLMotionProps, motion } from "framer-motion";

/**
 * A Framer Motion div with a standard fade transition.
 */
export const MotionFade = React.forwardRef(function MotionFade_(
  props: HTMLMotionProps<"div">,
  ref: ForwardedRef<HTMLDivElement>
) {
  return <motion.div {...props} ref={ref} />;
});

export const motionFadeAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    duration: 0.15,
  },
};

MotionFade.defaultProps = motionFadeAnimation;
