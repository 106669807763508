import type { User } from "shared/types";
import { UserFragment } from "~/queries/fragments/UserFragment";
import type { FetchedUser, UserSettings } from "~/types";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";

/**
 * GraphQL query for fetching the authenticated user.
 */
const UserQuery = `
  query UserQuery {
    viewer {
      ${UserFragment}
    }
    userSettings {
      showFullUserNames
    }
  }
`;

/**
 * Return type of the UserQuery.
 */
interface UserQueryReturn {
  viewer: FetchedUser;
  userSettings: UserSettings;
}

/**
 * Fetches properties on the authenticated user for logging with Sentry.
 *
 * @returns Properties of the authenticated user for logging with Sentry.
 */
export async function fetchUser(): Promise<{ user: User; userSettings: UserSettings }> {
  const response = await fetchLinear<UserQueryReturn>([UserQuery]);

  const user = {
    ...response.viewer,
    teams: flattenConnection(response.viewer.teams),
  };

  const userSettings = response.userSettings;

  return {
    user,
    userSettings,
  };
}
