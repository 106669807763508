import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export function CheckIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <svg>
        <path d="M4.2996 7.23968C4.01775 6.93614 3.5432 6.91857 3.23966 7.20042C2.93613 7.48227 2.91856 7.95682 3.20041 8.26035L6.45041 11.7603C6.7612 12.095 7.29647 12.0766 7.58346 11.7212L12.8335 5.22127C13.0937 4.89904 13.0435 4.42683 12.7213 4.16657C12.399 3.9063 11.9268 3.95654 11.6665 4.27877L6.96051 10.1053L4.2996 7.23968Z" />
      </svg>
    </Icon>
  );
}
