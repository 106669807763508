import type { FigmaEditorType } from "shared/types";
import { gridSpace, gridSpacePx } from "./gridSpace";

export const editorXGridSpace: Record<FigmaEditorType, number> = {
  dev: 4,
  figma: 3,
  figjam: 3, // NOTE: The plugin is not used by figjam.
};

/**
 * The px number of horizontal padding in the panel for different editor types.
 */
export function panelXPadding(): number {
  const editorType = self.document.documentElement.dataset.editorType as FigmaEditorType;

  return gridSpace(editorXGridSpace[editorType]);
}

/**
 * The px string of horizontal padding in the panel for different editor types.
 */
export function panelXPaddingPx(): string {
  const editorType = self.document.documentElement.dataset.editorType as FigmaEditorType;

  return gridSpacePx(editorXGridSpace[editorType]);
}
