import React from "react";
import styled from "styled-components";
import { FrameIcon } from "@linear/orbiter/icons/figma/FrameIcon";
import { PageIcon } from "@linear/orbiter/icons/figma/PageIcon";
import { SectionIcon } from "@linear/orbiter/icons/figma/SectionIcon";
import { ComponentIcon } from "@linear/orbiter/icons/figma/ComponentIcon";
import { InstanceIcon } from "@linear/orbiter/icons/figma/InstanceIcon";
import { gridSpacePx } from "~/styles/gridSpace";

interface NodeIconProps {
  type: BaseNode["type"];
}

/**
 * Shows an icon for a Figma node type.
 */
export function NodeIcon(props: NodeIconProps) {
  const { type } = props;

  let Icon: React.ReactNode = null;
  if (type === "PAGE") {
    Icon = <PageIcon />;
  } else if (type === "FRAME") {
    Icon = <FrameIcon />;
  } else if (type === "COMPONENT") {
    Icon = <ComponentIcon />;
  } else if (type === "INSTANCE") {
    Icon = <InstanceIcon />;
  } else if (type === "SECTION") {
    Icon = <SectionIcon />;
  }

  return <StyledNodeIcon>{Icon}</StyledNodeIcon>;
}

const StyledNodeIcon = styled.div`
  height: ${gridSpacePx(3)};
  width: ${gridSpacePx(3)};

  svg {
    display: flex; // Centers the icon
    height: 100%;
    width: 100%;
  }
`;
