import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export function MenuIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <svg>
        <path d="M3 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
      </svg>
    </Icon>
  );
}
