import React from "react";
import styled from "styled-components";
import type { Issue } from "shared/types";
import { Text } from "@linear/orbiter/components/Text";
import { handleExternalClick } from "~/utils/links";

interface IssueIdentifierProps extends React.PropsWithChildren {
  className?: string;
  url: Issue["url"];
}

/**
 * Monospace text for an issue identifier.
 */
export function IssueIdentifier(props: IssueIdentifierProps) {
  return (
    <StyledAnchorTag className={props.className} href={props.url} onClick={handleExternalClick}>
      <code className="flex-shrink-0">
        <Text color="labelMuted">{props.children}</Text>
      </code>
    </StyledAnchorTag>
  );
}

const StyledAnchorTag = styled.a`
  color: ${props => props.theme.color.labelMuted};
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;
