import React, { useState, useLayoutEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { truncateMultiline } from "@linear/orbiter/styles/mixins";
import { Text, type TextProps } from "@linear/orbiter/components/Text";
import { gridSpacePx } from "~/styles/gridSpace";
import { LinkButton } from "./LinkButton";
import { Markdown } from "./Markdown";

interface LinkedIssueListItemCondensedDescriptionProps extends TextProps {
  /** The description markdown content. */
  children: string;
}

export function LinkedIssueListItemCondensedDescription(props: LinkedIssueListItemCondensedDescriptionProps) {
  const { children, ...rest } = props;

  return (
    // Add inert to prevent interaction with condensed issue descriptions
    // @ts-expect-error Inert isn't supported in typescript yet
    <StyledIssueDescription condensed {...rest} inert={""}>
      <Markdown disableLinks isStyled={false}>
        {children || "No description"}
      </Markdown>
    </StyledIssueDescription>
  );
}

interface LinkedIssueListItemDescriptionProps {
  /** The description markdown content. */
  children: string;
  /** Truncates the text after a certain number of lines. */
  multiline?: number;
}

export function LinkedIssueListItemDescription(props: LinkedIssueListItemDescriptionProps) {
  const { children, multiline = 5, ...rest } = props;
  const descriptionRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandedButton, setShowExpandedButton] = useState(false);

  useLayoutEffect(() => {
    if (isExpanded) {
      // If the description is expanded, show the expand button
      setShowExpandedButton(true);
    } else if (descriptionRef.current) {
      // Check to see if the content is overflowing to show the expanded button
      setShowExpandedButton(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
    }
  }, [isExpanded]);

  return (
    <DescriptionFlex>
      <StyledIssueDescription multiline={isExpanded ? 0 : multiline} ref={descriptionRef} {...rest}>
        <Markdown>{children}</Markdown>
      </StyledIssueDescription>
      {showExpandedButton && (
        <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>Show {isExpanded ? "less" : "more"}</ExpandButton>
      )}
    </DescriptionFlex>
  );
}

const StyledIssueDescription = styled(Text)<{
  /** If the description is in a summarized state. */
  condensed?: boolean;
  /** How many lines the description should truncate to. */
  multiline?: number;
}>`
  color: ${props => (props.condensed ? "var(--figma-color-text-secondary)" : undefined)};
  flex-grow: 1;
  min-width: 0;

  ${props =>
    props.condensed || props.multiline !== 0
      ? css`
          ${truncateMultiline(props.multiline || 1)};

          // Required to work with truncateMultiline and long text
          white-space: normal;
        `
      : undefined}

  img {
    display: ${props => (props.condensed ? "none" : undefined)};
  }

  pre {
    padding: ${props => (props.condensed ? 0 : undefined)};

    // When the description is not summarized, allow code blocks to scroll
    overflow: ${props => (!props.condensed ? "scroll" : "initial")};

    // When the description is summarized, add truncation for code blocks
    white-space: ${props => (props.condensed ? "normal" : "pre")};
  }

  pre,
  code {
    background: ${props => (props.condensed ? "transparent" : undefined)};
  }

  a:hover {
    cursor: ${props => (props.condensed ? "default" : undefined)};
  }

  // Inline display of task lists & list items in the condensed view
  .contains-task-list,
  .task-list-item {
    display: ${props => (props.condensed ? "inline" : undefined)};

    // Hide the task list checkbox in the condensed view
    input {
      display: ${props => (props.condensed ? "none" : undefined)};
    }
  }
`;

const DescriptionFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gridSpacePx(2)};
`;

const ExpandButton = styled(LinkButton)`
  align-self: flex-start;

  &:not(:disabled):hover {
    color: var(--figma-color-text-secondary);
  }
`;
