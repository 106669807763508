import { useState } from "react";
import { Message, type PluginMessage, SelectNodesMessage } from "shared/messages";
import { FigmaSelection } from "~/store/UIFigma";
import { postPluginMessage } from "~/utils/postPluginMessage";
import { useMessageEventListener } from "./useMessageEventListener";

/**
 * Returns a function for selecting nodes on the canvas by Figma node ID.
 */
export function useSelectNodes(
  opts: {
    /**
     * Callback when the nodes have been selected on the canvas.
     */
    onSelect?: (selection: FigmaSelection) => void;
  } = {}
) {
  const [selecting, setSelecting] = useState<boolean>(false);
  const { onSelect } = opts;

  useMessageEventListener(
    selecting && onSelect
      ? (pluginMessage: PluginMessage) => {
          if (pluginMessage.message === Message.SELECTED_NODES || pluginMessage.message === Message.SELECTED_PAGE) {
            onSelect(new FigmaSelection(pluginMessage.data));
            setSelecting(false);
          }
        }
      : undefined
  );

  return function selectNodes(nodeIds: string[]) {
    setSelecting(true);
    postPluginMessage(new SelectNodesMessage(nodeIds));
  };
}
