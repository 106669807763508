import React from "react";
import styled from "styled-components";
import { Avatar, type AvatarProps, type AvatarSize } from "@linear/orbiter/components/Avatar";
import { UnassignedIcon } from "@linear/orbiter/icons/base/UnassignedIcon";
import { gridSpacePx } from "~/styles/gridSpace";

export function UserAvatar(props: AvatarProps) {
  const { modelId, src, size, ...rest } = props;

  if (!modelId && !src) {
    return <UnassignedAvatar $size={size} />;
  }

  return <Avatar modelId={modelId} src={src} size={size} {...rest} />;
}

const UnassignedAvatar = styled(UnassignedIcon)<{ $size?: AvatarSize }>`
  --avatar-size: ${props => {
    switch (props.$size) {
      case "micro":
        return gridSpacePx(3);
      case "tiny":
        return gridSpacePx(4);
      default:
        return gridSpacePx(4);
    }
  }};

  height: var(--avatar-size);
  width: var(--avatar-size);
`;
