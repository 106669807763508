import * as React from "react";
import { useTheme } from "styled-components";
/*
 * NOTE: Displaying cycle progress on an icon is currently missing due to the
 * amount of required logic being coupled with client.
 *
 * Ref: USP-3083
 */

export function CycleIconSmall(props: { className?: string }) {
  const theme = useTheme();
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={theme.color.labelMuted} className={props.className}>
      <path d="M7.125 2.01655C7.125 1.4768 6.64899 1.05501 6.12893 1.19947C3.17141 2.02102 1 4.74235 1 7.97282C1 11.8538 4.13401 15 8 15C11.866 15 15 11.8538 15 7.97282C15 4.74235 12.8286 2.02102 9.87107 1.19947C9.35101 1.05501 8.875 1.4768 8.875 2.01655V2.01655C8.875 2.45457 9.19479 2.82058 9.61155 2.95538C11.7226 3.63819 13.25 5.62644 13.25 7.97282C13.25 10.8836 10.8995 13.2432 8 13.2432C5.10051 13.2432 2.75 10.8836 2.75 7.97282C2.75 5.62644 4.27737 3.63819 6.38845 2.95538C6.80521 2.82058 7.125 2.45457 7.125 2.01655V2.01655Z" />
      <path d="M6.95588 5.28329L10.6901 7.43926C11.0235 7.63171 11.0235 8.11283 10.6901 8.30528L6.95588 10.4612C6.62255 10.6537 6.20588 10.4131 6.20588 10.0282L6.20588 5.71631C6.20588 5.33141 6.62255 5.09084 6.95588 5.28329Z" />
    </svg>
  );
}

export function NoCycleIcon(props: { className?: string }) {
  const theme = useTheme();
  const { radius, strokeWidth, strokeDasharray, strokeDashoffset } = useCircleValues();
  return (
    <svg
      stroke={theme.color.labelMuted}
      width="14"
      height="14"
      viewBox="0 0 16 16"
      className={props.className}
      style={{ overflow: "visible", transform: "scale(0.9)" }}
    >
      <circle
        cx="8"
        cy="8"
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  );
}

function useCircleValues() {
  return React.useMemo(() => {
    const radius = 8;
    const strokeWidth = 2;
    const perimeter = Math.PI * 2 * radius;
    // 24 because we want 12 dashes and 12 gaps of the same size, kind of like the backlog icon
    const strokeDasharrayUnit = perimeter / 24;

    return {
      radius,
      strokeWidth,
      perimeter,
      strokeDasharray: strokeDasharrayUnit,
      // Offset by half unit to ensure dashes are properly centered top/bottom/left/right
      strokeDashoffset: 0.5 * strokeDasharrayUnit,
    };
  }, []);
}
