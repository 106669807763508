import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { AuthenticationMessage, ClearStorageMessage, LinkIssueMessage, RemoveIssueLinksMessage } from "shared/messages";
import type { Attachment, Issue, User } from "shared/types";
import type { IssueLink, IssueLinksMap } from "shared/IssueLink";
import * as Sentry from "@sentry/react";
import type { LinkedIssues, UserSettings } from "~/types";
import { postPluginMessage } from "~/utils/postPluginMessage";
import { uiFigma } from "~/store/UIFigma";
import { IssueLinksHelper } from "~/store/IssueLinksHelper";
import { fetchUser } from "~/queries/fetchUser";

export class Store {
  /** True if we're waiting for the plugin to send initial state. */
  public initializingAuth = true;

  /**
   * True if we're waiting for the plugin to find issue links from issues with
   * Figma URLs containing the current file's fileKey.
   */
  public initializingIssueLinks = true;

  /** The users accessToken if signed in. */
  public accessToken?: string = undefined;

  /**
   * The authenticated user if signed in.
   */
  public user?: User = undefined;

  /**
   * The authenticated user's preferences.
   */
  public userSettings?: UserSettings = undefined;

  /**
   * Configures authenticated user state in the store.
   *
   * @param accessToken The user's access token.
   */
  public async setUser(accessToken: string | undefined) {
    this.initializingAuth = accessToken !== undefined;
    this.accessToken = accessToken;

    if (!accessToken) {
      this.user = undefined;
      Sentry.setUser(null);
      return;
    }

    const { user, userSettings } = await fetchUser();
    const userDetails = {
      id: user.id,
      name: user.name,
      email: user.email,
      isAdmin: user.admin,
      organizationId: user.organization.id,
      organizationName: user.organization.name,
    };

    Sentry.setUser(userDetails);

    runInAction(() => {
      this.user = user;
      this.userSettings = userSettings;
    });
  }

  /** Logs out the current user. */
  public logout() {
    void this.setUser(undefined);

    postPluginMessage(
      new AuthenticationMessage({
        accessToken: undefined,
      })
    );
  }

  /**
   * Clears all data stored in the plugin, an escape-hatch while we continue to
   * test the plugin.
   */
  public clearStorage() {
    this.logout();

    postPluginMessage(new ClearStorageMessage());
    this.allIssueLinks = {};
    this.allLinkedIssues = {};
  }

  /**
   * Issue IDs associated with Figma nodes stored as plugin data. This is only
   * set after initial plugin load when all issues matching the current Figma
   * file are fetched.
   */
  public allIssueLinks: Record<string, IssueLinksMap> = {};

  /** Issue links found from the plugin on the current page. */
  public get issueLinks(): IssueLinksMap {
    return this.allIssueLinks[uiFigma.currentPage.id] ?? {};
  }

  /** Update the issue links for the current page. */
  public set issueLinks(issueLinks: IssueLinksMap) {
    this.allIssueLinks[uiFigma.currentPage.id] = issueLinks;
  }

  /** Issues fetched for each Figma page. */
  public allLinkedIssues: Record<string, LinkedIssues> = {};

  /** Issues linked to the current Figma page. */
  public get linkedIssues(): LinkedIssues {
    return this.allLinkedIssues[uiFigma.currentPage.id] ?? {};
  }

  /** Update the linked issues for the current page. */
  public set linkedIssues(linkedIssues: LinkedIssues) {
    this.allLinkedIssues[uiFigma.currentPage.id] = linkedIssues;
  }

  /**
   * Link an issue to nodes on the Figma canvas by the issue's attachment URL.
   */
  public linkIssue(issue: Issue, attachmentsCreated: Attachment[]): Issue {
    this.linkedIssues[issue.id] = issue;

    postPluginMessage(new LinkIssueMessage(issue, attachmentsCreated));

    return issue;
  }

  /**
   * Removes an issue linked to nodes on the Figma canvas.
   */
  public removeLinkedIssue(issueId: Issue["id"]): void {
    const issueLinks = this.issueLinks[issueId];

    delete this.linkedIssues[issueId];
    delete this.issueLinks[issueId];

    postPluginMessage(new RemoveIssueLinksMessage(issueLinks));
  }

  /**
   * Removes issue links.
   */
  public removeIssueLinks(issueLinks: IssueLink[]): void {
    issueLinks.forEach(issueLink => {
      const issueId = issueLink.issueId;

      this.issueLinks[issueId] =
        // Issue links for this issue may no longer exist if the issue link was
        // removed on plugin startup.
        this.issueLinks[issueId]?.filter(link => {
          return link.node.id !== issueLink.node.id;
        }) ?? [];

      if (this.issueLinks[issueId].length === 0) {
        delete this.issueLinks[issueId];
        delete this.linkedIssues[issueId];
      }
    });

    if (issueLinks.length > 0) {
      // Can't postMessage to the plugin using a Proxy object
      postPluginMessage(new RemoveIssueLinksMessage(IssueLinksHelper.toJS(issueLinks)));
    }
  }

  public constructor() {
    makeObservable(this, {
      accessToken: observable,
      allIssueLinks: observable,
      allLinkedIssues: observable,
      clearStorage: action,
      initializingAuth: observable,
      initializingIssueLinks: observable,
      issueLinks: computed,
      linkIssue: action,
      linkedIssues: computed,
      logout: action,
      removeLinkedIssue: action,
      setUser: action,
      user: observable,
    });
  }
}

export const store = new Store();
