import React, { useEffect, useRef, useState } from "react";
import type { State } from "shared/types";
import { StateType } from "@linear/common/models/WorkflowStateType";
import { Combobox } from "~/components/Combobox";
import { useTeamWorkflow } from "~/queries/useTeamWorkflow";
import { WorkflowStateIcon } from "../WorkflowStateIcon";

const emptyState = {
  id: null,
  name: "Backlog",
  type: StateType.backlog,
} as unknown as State;

interface StatusInputControlProps extends React.PropsWithChildren {
  /** Callback when a status is selected. */
  onSelectStatus: (status: State | null) => void;
  /** The currently selected status. */
  selectedStatus: State | null;
  /** ID of the team to search for statuses in. */
  teamId?: string;
}

/**
 * Finds the default workflow state for a team. If a default state is not set,
 * find the first workflow state with a Backlog or Unstarted status type.
 *
 * FIXME (USP-3667): Move this to a model method on Team.
 */
function findDefaultWorkflowState(teamWorkflow: State[]): State {
  const defaultState = teamWorkflow.find(({ id }) => id === teamWorkflow[0].team.defaultIssueState?.id);

  if (defaultState) {
    return defaultState;
  }

  const backlogState = teamWorkflow.find(({ type }) => type === StateType.backlog);
  if (backlogState) {
    return backlogState;
  }

  const unstartedState = teamWorkflow.find(({ type }) => type === StateType.unstarted);
  if (unstartedState) {
    return unstartedState;
  }

  return teamWorkflow[0];
}

/**
 * Button and context menu for selecting an issue status.
 */
export function StatusInputControl(props: StatusInputControlProps) {
  const { children, onSelectStatus, selectedStatus, teamId } = props;

  const [statusOptions, setStatusOptions] = useState<State[]>([emptyState]);

  const teamWorkflow = useTeamWorkflow(teamId || null);
  const previousTeamId = useRef<string | undefined>(teamId);

  function onStatusSearch(query: string) {
    if (!query) {
      setStatusOptions(teamWorkflow.data ?? [emptyState]);
      return;
    }

    if (teamWorkflow.data) {
      setStatusOptions(
        teamWorkflow.data.filter(state => {
          return state.name.toLowerCase().includes(query.toLowerCase());
        })
      );
    }
  }

  // If no status is selected, select the team default.
  useEffect(() => {
    if (!teamWorkflow.data) {
      return;
    }

    // Update the list of status options once team data is available
    setStatusOptions(teamWorkflow.data);

    if (!selectedStatus) {
      const defaultState = findDefaultWorkflowState(teamWorkflow.data);
      onSelectStatus(defaultState);
    }
  }, [teamWorkflow.data]);

  // When the team changes and the selected status isn't in the team, select
  // the backlog status and reset state
  useEffect(() => {
    if (!teamWorkflow.data) {
      return;
    }

    if (previousTeamId.current !== teamId) {
      previousTeamId.current = teamId;

      const sameStatusInTeam = teamWorkflow.data.find(status => status.name === selectedStatus?.name);

      if (sameStatusInTeam) {
        onSelectStatus(sameStatusInTeam);
      } else {
        const defaultState = findDefaultWorkflowState(teamWorkflow.data);
        onSelectStatus(defaultState);
      }
    }
  }, [teamWorkflow.data, previousTeamId, selectedStatus]);

  return (
    <Combobox
      items={statusOptions.map(status => ({
        image: <WorkflowStateIcon state={status} />,
        key: status.id,
        label: status.name,
        value: status,
      }))}
      isLoading={teamWorkflow.isLoading}
      placeholder="Change status…"
      onSelect={item => {
        onSelectStatus(item.value);
      }}
      onSearch={onStatusSearch}
      selectedItem={
        selectedStatus
          ? {
              key: selectedStatus.id,
              image: <WorkflowStateIcon state={selectedStatus} />,
              label: selectedStatus.name,
              value: selectedStatus,
            }
          : {
              image: <WorkflowStateIcon state={emptyState} />,
              key: emptyState.id,
              label: emptyState.name,
              value: emptyState,
            }
      }
    >
      {children}
    </Combobox>
  );
}
