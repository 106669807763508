import { TeamFragment } from "~/queries/fragments/TeamFragment";

/* A template string fragment for fetching a User across the application. */
export const UserFragment = `
  admin
  avatarUrl
  displayName
  email
  id
  name
  organization {
    logoUrl
    id
    name
  }
  teams {
    edges {
      node {
        ${TeamFragment}
      }
    }
  }
`;
