import { OpenInBrowserMessage } from "shared/messages";
import { postPluginMessage } from "./postPluginMessage";

/**
 * Handles external link clicks by opening them in the user's default browser.
 *
 * Note this is required due to the ability to run Figma plugins inside of VSCode. It's not possible to
 * use window.open or target="_blank". See docs:
 * https://www.figma.com/plugin-docs/working-in-dev-mode/#dev-mode-plugins-in-visual-studio-code
 *
 * @param event - The click event.
 */
export function handleExternalClick(event: MouseEvent | React.MouseEvent<HTMLAnchorElement>) {
  if (event.currentTarget && "getAttribute" in event.currentTarget) {
    const url = event.currentTarget.getAttribute("href");
    if (!url) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    openExternalLink(url);
  }
}

/**
 * Opens a link in the user's default browser.
 *
 * @param url - The URL to open.
 */
export function openExternalLink(url: string) {
  postPluginMessage(new OpenInBrowserMessage(url));
}
