import React from "react";
import styled from "styled-components";
import type { Issue } from "shared/types";
import { Flex } from "@linear/orbiter/components/Flex";
import { LoadingIcon } from "@linear/orbiter/icons/base/LoadingIcon";
import { Text } from "@linear/orbiter/components/Text";
import { useUserAssignedIssues } from "~/queries/useUserAssignedIssues";
import { IssueToLinkListItem } from "~/components/IssueToLinkListItem";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { Scrollable } from "~/components/Scrollable";

interface AssignedIssuesListProps {
  onIssueLinked: (linkedIssue: Issue) => void;
  onIssueSelect: (issue: Issue) => void;
}

export function AssignedIssuesList(props: AssignedIssuesListProps) {
  const { onIssueSelect, onIssueLinked } = props;
  const issues = useUserAssignedIssues();

  const maxIssueIdentifierCharacters = issues.data?.reduce((max, issue) => Math.max(max, issue.identifier.length), 0);

  const AssignedIssues =
    issues.data?.map(issue => {
      return (
        <IssueToLinkListItem
          key={issue.id}
          issue={issue}
          maxIssueIdentifierCharacters={maxIssueIdentifierCharacters}
          onIssueSelect={onIssueSelect}
          onIssueLinked={onIssueLinked}
        />
      );
    }) ?? [];

  return (
    <Scrollable className="pb-2" column grow={1}>
      <AssignedIssuesListHeader>Assigned issues</AssignedIssuesListHeader>
      {issues.isLoading ? (
        <Flex className="m-4" gap={gridSpace(2)}>
          <MutedLoadingIcon />
          <Text color="labelMuted">Loading…</Text>
        </Flex>
      ) : (
        AssignedIssues
      )}
    </Scrollable>
  );
}

const AssignedIssuesListHeader = styled(Text)`
  color: ${props => props.theme.color.labelMuted};
  margin: ${gridSpacePx(4)} var(--panel-x-padding) ${gridSpacePx(2)};
`;

const MutedLoadingIcon = styled(LoadingIcon)`
  fill: ${props => props.theme.color.labelMuted};
`;
