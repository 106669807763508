import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { MinusIcon } from "@linear/orbiter/icons/figma/MinusIcon";
import { PlusIcon } from "@linear/orbiter/icons/figma/PlusIcon";
import { gridSpacePx } from "~/styles/gridSpace";
import { LargeIconButton } from "./IconButton";
import { TextTitle } from "./TextTitle";

interface AddIssueHeaderProps {
  isCreatingIssue: boolean;
  onIconClick: () => void;
}

export const AddIssueHeader = observer(function AddIssueHeader_(props: AddIssueHeaderProps) {
  const { isCreatingIssue, onIconClick } = props;

  return (
    <AddIssueFlex align="center" justify="space-between">
      <TextTitle>Link an issue</TextTitle>
      <LargeIconButton
        onClick={onIconClick}
        inset="right"
        aria-label={isCreatingIssue ? "Close new issue form" : "Create new issue"}
      >
        {isCreatingIssue ? <MinusIcon /> : <PlusIcon />}
      </LargeIconButton>
    </AddIssueFlex>
  );
});

const AddIssueFlex = styled(Flex)`
  padding: ${gridSpacePx(1)} var(--panel-x-padding);
`;
