import React, { useLayoutEffect } from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { observer } from "mobx-react-lite";
import type { FigmaEditorTheme } from "shared/types";
import { runInAction } from "mobx";
import { PluginMessageHandler } from "~/components/PluginMessageHandler";
import { Providers } from "~/providers";
import { objToCSS } from "~/utils/objToCSS";
import { AuthenticationRequired } from "~/components/AuthenticationRequired";
import { App } from "~/components/App";
import { Config } from "~/config";
import { uiFigma } from "./store/UIFigma";

Sentry.init({
  dsn: Config.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
});

const root = createRoot(self.document.getElementById("root")!);

const UI = observer(function UI() {
  useLayoutEffect(() => {
    // Initialize the editor theme into stored state since it's unavailable
    // from the plugin API
    runInAction(() => {
      uiFigma.editorTheme = self.document.documentElement.dataset.theme as FigmaEditorTheme;
    });
  }, []);

  return (
    <Providers>
      <AuthenticationRequired>
        <App />
      </AuthenticationRequired>
      <PluginMessageHandler />
    </Providers>
  );
});

if (self === top) {
  // Redirect to linear.app if we're not in a Figma iframe
  window.location.href = "https://linear.app";
} else {
  root.render(<UI />);
}

const queryParams = new URLSearchParams(location.search);

// Dark mode
if (queryParams.get("isDarkMode") === "true") {
  self.document.documentElement.dataset.theme = "dark";
} else {
  self.document.documentElement.dataset.theme = "light";
}

// Editor type
self.document.documentElement.dataset.editorType = queryParams.get("editorType") ?? "figma";

try {
  const figmaStylesString = queryParams.get("figmaStyles");

  if (figmaStylesString) {
    const stylesheet = self.document.getElementById("figma-styles") as HTMLStyleElement;
    const styles = objToCSS(JSON.parse(figmaStylesString));

    stylesheet.innerHTML = `
      :root {
        ${styles}
      }
    `;
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.error("Unable to parse styles from Figma");
}
