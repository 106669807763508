import { useState } from "react";
import useSWR, { type SWRResponse } from "swr";
import { UpdateAllIssueLinksMessage } from "shared/messages";
import { runInAction } from "mobx";
import type { Issue } from "shared/types";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import { IssueFragment } from "~/queries/fragments/IssueFragment";
import type { Connection, FetchedIssue } from "~/types";
import { postPluginMessage } from "~/utils/postPluginMessage";
import { store } from "~/store";
import { flattenFetchedIssue } from "~/utils/flattenFetchedIssue";

// Maximum issues that the query complexity allows
const ISSUES_PER_PAGE = 25;

const IssuesWithFigmaQuery = `
  query IssuesWithFigma($cursor: String, $fileKey: String!) {
    issueFigmaFileKeySearch(
      fileKey: $fileKey,
      first: ${ISSUES_PER_PAGE}
      after: $cursor,
    ) {
      edges {
        node {
          ${IssueFragment}
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

interface IssuesWithFigmaQueryPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}

interface IssuesWithFigmaQueryReturn {
  issueFigmaFileKeySearch: Connection<FetchedIssue> & {
    pageInfo: IssuesWithFigmaQueryPageInfo;
  };
}

/** Returns issues associated with Figma links. */
export function useIssuesWithFigma(fileKey: string | undefined): SWRResponse<boolean> {
  const [previousPageInfo, setPreviousPageInfo] = useState<IssuesWithFigmaQueryPageInfo | null>(null);

  const preventQuery = !fileKey || previousPageInfo?.hasNextPage === false;

  const issuesWithFigmaSWR = useSWR(
    preventQuery ? null : [IssuesWithFigmaQuery, { fileKey, cursor: previousPageInfo?.endCursor }],
    async args => {
      runInAction(() => (store.initializingIssueLinks = true));

      const response = await fetchLinear<IssuesWithFigmaQueryReturn>(args);
      const issues = flattenConnection(response.issueFigmaFileKeySearch);

      const syncedIssueData = issues.reduce(
        (acc, issue) => {
          acc[issue.id] = flattenFetchedIssue(issue);

          return acc;
        },
        {} as Record<Issue["id"], Issue>
      );

      /**
       * Reconcile plugin data with issues that have Figma links with the fileKey.
       */
      postPluginMessage(new UpdateAllIssueLinksMessage(syncedIssueData));

      setPreviousPageInfo(response.issueFigmaFileKeySearch.pageInfo);

      // Prevent refetching the query by always returning true
      return true;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  return issuesWithFigmaSWR;
}
