import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { gridSpacePx } from "~/styles/gridSpace";

export const StyledInputControlButton = styled.button`
  background-color: var(--figma-color-bg);
  border-radius: 4px;
  border: 1px solid var(--figma-color-border);
  color: var(--figma-color-text);
  align-items: center;
  display: flex;
  height: ${gridSpacePx(6)};
  font-size: 1rem;
  padding: ${gridSpacePx(1)};
  position: relative;
  white-space: nowrap;
  width: 100%;

  &[data-state="open"] {
    background-color: var(--figma-color-bg-hover);
  }

  &:not(:disabled):hover {
    background: var(--figma-color-bg-hover);
  }

  &:not(:disabled):focus-visible {
    border: 1px solid var(--color-accent);
    outline: none;
  }
`;

/**
 * Spacing out and truncating the content in an input control button
 */
export const InputControlButtonContent = styled(Flex)`
  align-items: center;
  gap: ${gridSpacePx(1)};
  width: 100%;
`;

interface InputControlButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const InputControlButton = React.forwardRef(function InputControlButton_(
  props: InputControlButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { children, ...rest } = props;

  return (
    <StyledInputControlButton {...rest} ref={ref}>
      <InputControlButtonContent>{children}</InputControlButtonContent>
    </StyledInputControlButton>
  );
});

const StyledInlineInputControlButton = styled.div<{ flex?: number }>`
  flex: ${props => props.flex};
  max-width: fit-content;
  min-width: 0;
`;

interface InlineInputControlButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  flex?: number;
}

/**
 * Used for displaying an input control button in horizontal list of other input controls.
 */
export const InlineInputControlButton = React.forwardRef(function InlineInputControlButton_(
  props: InlineInputControlButtonProps,
  ref: React.RefObject<HTMLButtonElement>
) {
  const { children, flex, ...buttonProps } = props;

  return (
    <StyledInlineInputControlButton flex={flex}>
      <InputControlButton {...buttonProps} ref={ref}>
        {children}
      </InputControlButton>
    </StyledInlineInputControlButton>
  );
});

/**
 * Displays the text label associated with an inline input control button when
 * the viewport is wide enough.
 */
export const InlineInputControlButtonLabel = styled(Text)`
  display: none;

  @media (min-width: 420px) {
    display: block;
  }
`;

InlineInputControlButtonLabel.defaultProps = {
  truncate: true,
};
