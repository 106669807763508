import { computed, makeObservable, observable } from "mobx";
import { FigmaSelectionType, type NodeSelectedMessage, type PageSelectedMessage } from "shared/messages";
import { FigmaEditorTheme, FigmaEditorType, type SelectedNode } from "shared/types";

type SelectionMessage = NodeSelectedMessage | PageSelectedMessage;

/**
 * Representation of the current selection on the Figma canvas.
 */
export class FigmaSelection {
  public readonly nodes: SelectedNode[];

  public readonly type: FigmaSelectionType;

  public get isPage(): boolean {
    return this.type === FigmaSelectionType.PAGE;
  }

  /**
   * Determines if there's an intersection between this selection and a given
   * set of nodes.
   *
   * @param nodes The nodes to check for intersection.
   * @returns If the same node exists between the provided selection and this
   * one.
   */
  public includes(nodes: SelectedNode[]): boolean {
    // FIXME: May need to narrow down the provided nodes to the current page
    // ID. Currently, a FigmaSelection instance doesn't know about the page
    // since the selection is represented as an empty array.
    if (this.isPage) {
      return nodes.some(node => node.type === "PAGE");
    }

    return this.nodes.some(node => nodes.some(n => n.id === node.id));
  }

  public constructor(data: SelectionMessage["data"]) {
    this.nodes = data.nodes;
    this.type = data.selectionType;

    makeObservable(this, {
      isPage: computed,
      nodes: observable,
      type: observable,
    });
  }
}

/**
 * UI representation of the Figma canvas.
 */
export class UIFigma {
  public currentPage: {
    id: string;
    name: string;
    type: "PAGE";
  } = {
    id: "",
    name: "",
    type: "PAGE",
  };

  public file: {
    id: string;
    name: string;
  } = {
    id: "",
    name: "",
  };

  public fileKey: string = "";

  /** The current theme in the editor. */
  public editorTheme: FigmaEditorTheme = FigmaEditorTheme.Light;

  /** The edit mode the plugin is being ran from. */
  public editorType: FigmaEditorType = FigmaEditorType.Dev;

  public selection: FigmaSelection = new FigmaSelection({
    nodes: [],
    selectionType: FigmaSelectionType.PAGE,
  });

  public constructor() {
    makeObservable(this, {
      currentPage: observable,
      editorTheme: observable,
      editorType: observable,
      file: observable,
      fileKey: observable,
      selection: observable,
    });
  }
}

export const uiFigma = new UIFigma();
