/** Base types of workflow states. */
export enum StateType {
  triage = "triage",
  backlog = "backlog",
  unstarted = "unstarted",
  started = "started",
  completed = "completed",
  canceled = "canceled",
}

export namespace StateTypeHelper {
  /** The absolute order of the state types. */
  export const statusOrdering: { [k in StateType]: number } = Object.freeze({
    [StateType.triage]: 0,
    [StateType.backlog]: 1,
    [StateType.unstarted]: 2,
    [StateType.started]: 3,
    [StateType.completed]: 4,
    [StateType.canceled]: 5,
  });

  /** The custom order of the state types. */
  export const customStatusOrdering: { [k in StateType]: number } = Object.freeze({
    [StateType.started]: 0,
    [StateType.unstarted]: 1,
    [StateType.backlog]: 2,
    [StateType.triage]: 3,
    [StateType.completed]: 4,
    [StateType.canceled]: 5,
  });

  export const all: StateType[] = Object.values(StateType).filter(key => typeof key === "string") as StateType[];
  export const allWithoutTriage: StateType[] = all.filter(v => v !== StateType.triage);
  /**
   * Map our state category names to other common category names. Useful when trying to match our categories to those in
   * other systems.
   */
  export const categoryNameMappings: { [key in StateType]: string[] } = {
    [StateType.triage]: [],
    [StateType.backlog]: [],
    [StateType.unstarted]: [],
    [StateType.started]: [],
    [StateType.completed]: ["done", "resolved"],
    [StateType.canceled]: ["cancelled"],
  };

  /**
   * The state types considered "closed".
   */
  export const closedStateTypes = [StateType.completed, StateType.canceled];
}
