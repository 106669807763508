import React from "react";
import type { Issue } from "shared/types";
import styled from "styled-components";
import { LoadingIcon } from "@linear/orbiter/icons/base/LoadingIcon";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { useSearchIssues } from "~/queries/useSearchIssues";
import { gridSpacePx } from "~/styles/gridSpace";
import { Scrollable } from "~/components/Scrollable";
import { IssueToLinkListItem } from "./IssueToLinkListItem";

interface IssueSearchResultsListProps {
  query: string;
  onIssueSelect: (issue: Issue) => void;
  onIssueLinked: (linkedIssue: Issue) => void;
}

export function IssueSearchResultsList(props: IssueSearchResultsListProps) {
  const { onIssueLinked, onIssueSelect, query } = props;
  const issues = useSearchIssues(query);

  const maxIssueIdentifierCharacters = issues.data?.reduce((max, issue) => Math.max(max, issue.identifier.length), 0);

  const IssueSearchResults =
    issues.data?.map(issue => {
      return (
        <IssueToLinkListItem
          key={issue.id}
          issue={issue}
          maxIssueIdentifierCharacters={maxIssueIdentifierCharacters}
          onIssueSelect={onIssueSelect}
          onIssueLinked={onIssueLinked}
        />
      );
    }) ?? [];

  if (issues.isLoading) {
    return (
      <IssueResultsFlex>
        <MutedLoadingIcon />
        <Text color="labelMuted">Loading…</Text>
      </IssueResultsFlex>
    );
  }

  return (
    <Scrollable className="pb-2" column grow={1}>
      {issues.data?.length === 0 ? (
        <IssueResultsFlex>
          <Text color="labelMuted">No issues found</Text>
        </IssueResultsFlex>
      ) : (
        <>{IssueSearchResults}</>
      )}
    </Scrollable>
  );
}

const IssueResultsFlex = styled(Flex)`
  gap: ${gridSpacePx(2)};
  margin: ${gridSpacePx(4)} var(--panel-x-padding);
`;

const MutedLoadingIcon = styled(LoadingIcon)`
  fill: ${props => props.theme.color.labelMuted};
  height: 14px;
  width: 14px;
`;
