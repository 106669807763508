import type { Issue, Priority } from "shared/types";
import useSWRMutation, { type SWRMutationResponse } from "swr/mutation";
import { IssueFragment } from "~/queries/fragments/IssueFragment";
import type { FetchedIssue } from "~/types";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenFetchedIssue } from "~/utils/flattenFetchedIssue";

const CreateIssue = `
  mutation CreateIssue(
    $assigneeId: String,
    $description: String,
    $priority: Int,
    $projectId: String,
    $stateId: String,
    $teamId: String!
    $title: String!,
  ) {
    issueCreate(
      input: {
        assigneeId: $assigneeId,
        description: $description,
        priority: $priority,
        projectId: $projectId,
        stateId: $stateId,
        teamId: $teamId,
        title: $title
      }
    ) {
      issue {
        ${IssueFragment}
      }
    }
  }
`;

interface CreateIssueReturn {
  issueCreate: {
    issue: FetchedIssue;
  };
}

interface CreateIssueInput {
  assigneeId?: string;
  description?: string;
  priority?: Priority;
  projectId?: string;
  stateId?: string;
  teamId: string;
  title: string;
}

export function useCreateIssue(): SWRMutationResponse<Issue> {
  return useSWRMutation(CreateIssue, async (query, { arg }: { arg: CreateIssueInput }) => {
    const response = await fetchLinear<CreateIssueReturn>([query, arg]);
    return flattenFetchedIssue(response.issueCreate.issue);
  });
}
