/**
 * The base for the manifest file generated by the build-manifest script during
 * the build step.
 */
export const manifest = {
  name: "Linear",
  id: "1221187540287746170",
  api: "1.0.0",
  main: "build-plugin/assets/figma.js",
  capabilities: ["inspect", "vscode"],
  editorType: ["figma", "dev"],
  permissions: ["filekey"],
};
