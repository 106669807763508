import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { AnimatePresence, motion } from "framer-motion";
import type { Issue } from "shared/types";
import { ErrorBoundary } from "@sentry/react";
import { Flex } from "@linear/orbiter/components/Flex";
import { useKeyPressEvent } from "@linear/orbiter/hooks/react-use";
import { AddIssue } from "~/components/AddIssue";
import { LinkedIssuesList } from "~/components/LinkedIssuesList";
import { FooterBar } from "~/components/FooterBar";
import { useUserAssignedIssues } from "~/queries/useUserAssignedIssues";
import { store } from "~/store";
import { ErrorFallback } from "~/components/ErrorFallback";
import { gridSpacePx } from "~/styles/gridSpace";
import { AddIssueHeader } from "./AddIssueHeader";
import { MotionFade } from "./MotionFade";

/** Animation variants between the list and add issue flow. */
const listAnimationVariants = {
  /** Fade out the list when there are no issues displayed */
  fade: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      duration: 0.15,
    },
  },
  /** Slide and fade the issue list out. */
  slide: {
    initial: { opacity: 0, y: "100%" },
    animate: { opacity: 1, y: 0, transition: { opacity: { delay: 0.05 } } },
    exit: {
      opacity: 0,
      y: "100%",
      transition: {
        opacity: {
          duration: 0.1,
        },
      },
    },
    transition: {
      duration: 0.3,
    },
  },
};

export const App = observer(function App_() {
  const [isCreatingIssue, setIsCreatingIssue] = useState(false);
  const [recentlyLinkedIssue, setRecentlyLinkedIssue] = useState<Issue | null>(null);

  /**
   * Prefetches user's assigned issues to prevent reveal animation jankiness.
   */
  useUserAssignedIssues();

  // Clear the recently selected issue
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (recentlyLinkedIssue) {
      timeoutId = setTimeout(() => {
        setRecentlyLinkedIssue(null);
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [recentlyLinkedIssue]);

  // Add event handling for meta+a to select all text in text inputs since
  // Figma blocks it
  useKeyPressEvent(event => {
    const targetIsInput = event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement;

    if (event.metaKey && event.key === "a" && targetIsInput) {
      event.preventDefault();
      event.target.select();
      return true;
    }

    return false;
  });

  const hasLinkedIssues = Object.keys(store.linkedIssues).length > 0;
  const animationVariant = hasLinkedIssues ? listAnimationVariants.slide : listAnimationVariants.fade;

  return (
    <ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorBoundaryFlex>
          <ErrorFallback error={error} resetError={resetError} />
        </ErrorBoundaryFlex>
      )}
      onReset={() => {
        location.reload();
      }}
    >
      <Flex className="h-full w-full" column>
        <AnimatePresence initial={false} mode="popLayout">
          {isCreatingIssue ? (
            <MotionFlex
              key="add-issue"
              transition={{
                duration: 0.25,
                delay: 0.05,
              }}
            >
              <AddIssue
                onClose={() => {
                  setIsCreatingIssue(false);
                }}
                onIssueLinked={linkedIssue => {
                  setIsCreatingIssue(false);
                  setRecentlyLinkedIssue(linkedIssue);
                }}
              />
            </MotionFlex>
          ) : (
            <MotionDivFlex key="container">
              <AddIssueHeader isCreatingIssue={false} onIconClick={() => setIsCreatingIssue(true)} />
              <MotionFlex
                key="issues-list"
                initial={animationVariant.initial}
                animate={animationVariant.animate}
                exit={animationVariant.exit}
                transition={animationVariant.transition}
                style={{
                  position: "relative",
                  zIndex: 2,
                  backgroundColor: "var(--figma-color-bg)",
                }}
              >
                <LinkedIssuesList
                  onAddIssue={() => {
                    setIsCreatingIssue(true);
                  }}
                  selectedIssue={recentlyLinkedIssue}
                />
              </MotionFlex>
            </MotionDivFlex>
          )}
        </AnimatePresence>
        <FooterBar />
      </Flex>
    </ErrorBoundary>
  );
});

/**
 * Wrapper around the error boundary that centers the error message.
 */
const ErrorBoundaryFlex = styled(Flex)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 25vh;
  margin-bottom: ${gridSpacePx(4)};
  padding-left: var(--panel-x-padding);
  padding-right: var(--panel-x-padding);
  width: ${gridSpacePx(62)};
`;

// Wrapper around the linked issue list that actually animates without the
// header.
const MotionDivFlex = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

const MotionFlex = styled(MotionFade)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;
