"use client";

import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export function ProjectIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <svg>
        <path
          fillRule="evenodd"
          d="m11.927 13.232-1.354.78c-.937.54-1.406.811-1.904.917a3.22 3.22 0 0 1-1.338 0c-.498-.106-.967-.376-1.904-.917l-1.354-.78c-.937-.541-1.406-.811-1.747-1.19a3.212 3.212 0 0 1-.669-1.157C1.5 10.401 1.5 9.861 1.5 8.78V7.22c0-1.082 0-1.622.157-2.106.14-.429.368-.823.67-1.157.34-.379.809-.649 1.746-1.19l1.354-.78c.937-.54 1.406-.811 1.904-.917a3.22 3.22 0 0 1 1.338 0c.498.106.967.376 1.904.917l1.354.78c.937.541 1.406.811 1.747 1.19.301.334.53.728.669 1.157.157.484.157 1.024.157 2.106v1.56c0 1.082 0 1.622-.157 2.106-.14.429-.368.823-.67 1.157-.34.379-.809.649-1.746 1.19Zm-5.751-.52-1.353-.78c-1.025-.591-1.239-.734-1.383-.894a1.712 1.712 0 0 1-.356-.617C3.017 10.217 3 9.962 3 8.78V7.22c0-.378.002-.661.006-.878l3.021 1.51a2.25 2.25 0 0 1 1.224 2.002v3.457a23.16 23.16 0 0 1-1.075-.597Zm2.575.597c.212-.105.532-.284 1.073-.596l1.353-.78c1.026-.592 1.239-.735 1.383-.895.16-.178.282-.389.356-.617.066-.204.084-.459.084-1.642V7.22c0-.378-.002-.661-.006-.878l-3 1.5-.007.003a2.25 2.25 0 0 0-1.236 2.009v3.456Zm3.757-8.402L9.324 6.499l-.01.004-.307.154a2.25 2.25 0 0 1-2.013 0l-.29-.145-.026-.013-3.186-1.593c.15-.143.42-.315 1.33-.84l1.354-.78c1.025-.592 1.256-.705 1.467-.75.235-.05.479-.05.714 0 .211.045.442.158 1.467.75l1.353.78c.912.525 1.181.697 1.331.84Z"
        />
      </svg>
    </Icon>
  );
}
