import { type RefObject, useLayoutEffect } from "react";

/**
 * Delays autofocus for an element based on a timeout. This can be necessary
 * when animating elements to autofocus that haven't yet been shown to the
 * user.
 */
export function useDelayedAutofocus(ref: RefObject<HTMLElement>, autofocus: boolean, delay: number = 100): void {
  useLayoutEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (autofocus) {
      timeoutId = setTimeout(() => {
        ref.current?.focus();
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [autofocus, delay]);
}
