import React from "react";
import styled from "styled-components";
import type { Issue } from "shared/types";
import { Flex } from "@linear/orbiter/components/Flex";
import { TeamsIcon } from "@linear/orbiter/icons/base/TeamsIcon";
import { Text } from "@linear/orbiter/components/Text";
import { ProjectIcon } from "@linear/orbiter/icons/decorative/ProjectIcon";
import { useUpdateIssue } from "~/queries/useUpdateIssue";
import { gridSpace } from "~/styles/gridSpace";
import { PriorityInputControl, priorityToPriorityOption } from "~/components/IssueInputControls/PriorityInputControl";
import { TeamInputControl } from "./IssueInputControls/TeamInputControl";
import { InlineInputControlButton, InlineInputControlButtonLabel } from "./IssueInputControls/InputControlButton";
import { UserInputControl } from "./IssueInputControls/UserInputControl";
import { UserAvatar } from "./UserAvatar";
import { ProjectInputControl } from "./IssueInputControls/ProjectInputControl";

interface LinkedIssueInputControlsProps {
  linkedIssue: Issue;
}

/**
 * A list of controls that update different properties on a linked issue.
 */
export function LinkedIssueInputControls(props: LinkedIssueInputControlsProps) {
  const { linkedIssue } = props;
  const updateIssue = useUpdateIssue();

  const issuePriorityOption = priorityToPriorityOption(linkedIssue.priority);

  return (
    <Flex gap={gridSpace(1)}>
      <TeamInputControl
        onSelectTeam={async newTeam => {
          if (!newTeam || newTeam.id === linkedIssue.team.id) {
            return;
          }

          await updateIssue.trigger({ issue: linkedIssue, teamId: newTeam.id });
        }}
        selectedTeam={linkedIssue.team}
      >
        <LinkedIssueInputControlButton flex={2}>
          <TeamsIcon />
          <Text truncate>{linkedIssue.team.key}</Text>
        </LinkedIssueInputControlButton>
      </TeamInputControl>
      <PriorityInputControl
        selectedPriority={issuePriorityOption}
        onSelectPriority={async newPriority => {
          await updateIssue.trigger({ issue: linkedIssue, priority: newPriority?.value });
        }}
      >
        <LinkedIssueInputControlButton flex={1}>
          {issuePriorityOption.icon}
          <InlineInputControlButtonLabel>{issuePriorityOption.name}</InlineInputControlButtonLabel>
        </LinkedIssueInputControlButton>
      </PriorityInputControl>
      <UserInputControl
        selectedTeam={linkedIssue.team}
        selectedUser={linkedIssue.assignee}
        onSelectUser={async newAssignee => {
          if (newAssignee?.id === linkedIssue.assignee?.id) {
            return;
          }

          await updateIssue.trigger({
            issue: linkedIssue,
            assigneeId: newAssignee?.id,
          });
        }}
      >
        <LinkedIssueInputControlButton flex={1}>
          <UserAvatar
            modelId={linkedIssue.assignee?.id}
            name={linkedIssue.assignee?.name}
            size="tiny"
            src={linkedIssue.assignee?.avatarUrl}
          />
          <InlineInputControlButtonLabel>{linkedIssue.assignee?.name ?? "Unassigned"}</InlineInputControlButtonLabel>
        </LinkedIssueInputControlButton>
      </UserInputControl>
      <ProjectInputControl
        teamId={linkedIssue.team.id}
        selectedProject={linkedIssue.project}
        onSelectProject={async newProject => {
          if (!newProject || newProject.id === linkedIssue.project?.id) {
            return;
          }

          await updateIssue.trigger({
            issue: linkedIssue,
            projectId: newProject.id,
          });
        }}
      >
        <LinkedIssueInputControlButton flex={1}>
          <ProjectIcon />
          <Text truncate>{linkedIssue.project?.name ?? "No project"}</Text>
        </LinkedIssueInputControlButton>
      </ProjectInputControl>
    </Flex>
  );
}

const LinkedIssueInputControlButton = styled(InlineInputControlButton)`
  background-color: var(--figma-color-bg-selected);
  border-color: var(--figma-color-border-selected);

  &[data-state="open"],
  &:enabled:hover {
    background-color: var(--figma-color-bg-selected-hover);
  }
`;
