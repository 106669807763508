import useSWR, { type SWRResponse } from "swr";
import type { Team } from "shared/types";
import { SortHelper } from "@linear/common/utils/SortHelper";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import type { Connection } from "~/types";
import { TeamFragment } from "~/queries/fragments/TeamFragment";

const TeamsQuery = `
  query TeamsQuery {
    teams {
      edges {
        node {
          ${TeamFragment}
        }
      }
    }
  }
`;

type TeamsQueryReturn = Team[];

/**
 * Finds all teams in a workspace for issue creation.
 */
export function useTeams(): SWRResponse<TeamsQueryReturn> {
  return useSWR(
    [TeamsQuery],
    async args => {
      const response = await fetchLinear<{
        teams: Connection<Team>;
      }>(args);

      const teams = flattenConnection(response.teams);
      return SortHelper.natural(teams, "name");
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );
}
