import * as React from "react";

export function PlusIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" aria-hidden {...props}>
      <g id="32 / plus">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 15.5V10.5H16.5V15.5H21.5V16.5H16.5V21.5H15.5V16.5H10.5V15.5H15.5Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
      </g>
    </svg>
  );
}
