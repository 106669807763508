import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

type Props = IconProps & {
  /** Whether the icon should appear muted */
  muted?: boolean;
};

/**
 * Icon for urgent priority
 */
export function Priority1Icon(props: Props) {
  const { muted, ...rest } = props;
  return (
    <Icon color={props.muted ? "labelMuted" : "orangeBase"} {...rest} aria-label="Urgent Priority">
      <svg>
        <path d="M3 1C1.91067 1 1 1.91067 1 3V13C1 14.0893 1.91067 15 3 15H13C14.0893 15 15 14.0893 15 13V3C15 1.91067 14.0893 1 13 1H3ZM7 4L9 4L8.75391 8.99836H7.25L7 4ZM9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z" />
      </svg>
    </Icon>
  );
}
