import { useCallback, useEffect, useState } from "react";

interface StoredStateOptions {
  /** Whether to listen for changes to the state in other tabs. */
  listener?: boolean;
}

/**
 * Stores state in localStorage and returns a hook to access it.
 *
 * @param key The key to store the state under in localStorage.
 * @param defaultState The default state to use if no state is stored.
 * @param options Options for the hook.
 */
export function useStoredState<T>(
  key: string,
  defaultState: T,
  options: StoredStateOptions = {}
): [T, (newState: T) => void] {
  const [state, setState] = useState<T>(() => {
    try {
      const storedState = localStorage.getItem(key);
      return storedState ? JSON.parse(storedState) : defaultState;
    } catch (e) {
      return defaultState;
    }
  });

  const onStateChange = useCallback((newState: T) => {
    setState(newState);

    try {
      localStorage.setItem(key, JSON.stringify(newState));
    } catch (e) {
      // No-op
    }
  }, []);

  useEffect(() => {
    if (options.listener === false) {
      return;
    }

    function onStateChangeEvent(event: StorageEvent) {
      if (event.key === key && event.newValue) {
        onStateChange(JSON.parse(event.newValue) as T);
      }
    }

    window.addEventListener("storage", onStateChangeEvent);
    return () => window.removeEventListener("storage", onStateChangeEvent);
  }, [onStateChange]);

  return [state, onStateChange];
}
