import React, { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { MotionConfig } from "framer-motion";
import { BaseStyles } from "@linear/orbiter/styles/base";
import {
  KeyboardEventListeners as KeyboardEventListenersClass,
  KeyboardEventListenersContext,
} from "@linear/orbiter/context/KeyboardEventListeners";
import { SWRProvider } from "~/providers/SWRProvider";
import { figmaTheme } from "~/styles/figmaTheme";
import { FigmaStyles } from "~/styles/FigmaStyles";

interface ProvidersProps extends React.PropsWithChildren {}

export function Providers(props: ProvidersProps) {
  const KeyboardEventListeners = useMemo(() => {
    return new KeyboardEventListenersClass();
  }, []);

  React.useEffect(() => {
    KeyboardEventListeners.attachGlobalEventListeners();
    return () => {
      KeyboardEventListeners.removeGlobalEventListeners();
    };
  }, [KeyboardEventListeners]);

  return (
    <SWRProvider>
      <ThemeProvider theme={figmaTheme}>
        <MotionConfig reducedMotion="user">
          <KeyboardEventListenersContext.Provider value={KeyboardEventListeners}>
            <BaseStyles />
            <FigmaStyles />
            {props.children}
          </KeyboardEventListenersContext.Provider>
        </MotionConfig>
      </ThemeProvider>
    </SWRProvider>
  );
}
