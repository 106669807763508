import React from "react";
import { motion, useReducedMotion } from "framer-motion";
import styled from "styled-components";
import { Flex, type FlexProps } from "@linear/orbiter/components/Flex";
import { gridSpacePx } from "~/styles/gridSpace";

export const StyledMotionPopoverMenu = styled(motion.div)`
  isolation: isolate;
  z-index: 2;
`;

export const MotionPopoverMenu = React.forwardRef(function (
  props: React.PropsWithChildren<{
    className?: string;
    side?: "top" | "bottom";
    isOpen: boolean;
  }>,
  ref: React.RefObject<HTMLDivElement>
) {
  const { className, isOpen, children, side = "bottom" } = props;
  const shouldReduceMotion = useReducedMotion();

  let initialY: number;
  if (shouldReduceMotion) {
    initialY = 0;
  } else {
    initialY = side === "top" ? 5 : -5;
  }

  return (
    <StyledMotionPopoverMenu
      initial={{ opacity: 0, y: initialY }}
      animate={{
        display: "block",
        opacity: isOpen ? 1 : 0,
        y: isOpen ? 0 : initialY,
        transitionEnd: {
          display: !isOpen ? "none" : undefined,
        },
      }}
      transition={{
        duration: 0.15,
      }}
      className={className}
      ref={ref}
    >
      {children}
    </StyledMotionPopoverMenu>
  );
});

export const StyledPopoverMenu = styled(Flex)`
  background: var(--figma-color-bg);
  border 1px solid var(--figma-color-border);
  border-radius: 4px;
  box-shadow: 0px 7px 24px 0px var(--color-bg-shadow);
  /** Motion parent needs to handle display case at transitionEnd */
  display: block;
  flex-direction: column;
  margin-top: 0;
  /** TODO: May want to make this dynamic */
  min-width: ${gridSpacePx(40)};
  overflow-y: auto;
  position: relative;
  top: auto;
`;

interface PopoverMenuProps extends FlexProps {
  isOpen: boolean;
  side?: "top" | "bottom";
}

/** A popover menu that animates. */
export const PopoverMenu = React.forwardRef(function PopoverMenu_(
  props: PopoverMenuProps,
  ref: React.RefObject<HTMLDivElement>
) {
  const { isOpen, side, ...rest } = props;

  return (
    <MotionPopoverMenu isOpen={isOpen} side={side}>
      <StyledPopoverMenu {...rest} ref={ref} />
    </MotionPopoverMenu>
  );
});
