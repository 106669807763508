import React, { useMemo } from "react";
import { SWRConfig } from "swr";
import { fetchLinear } from "~/utils/fetchLinear";

interface SWRProviderProps extends React.PropsWithChildren {}

export function SWRProvider(props: SWRProviderProps) {
  const { children } = props;

  const config = useMemo(() => {
    return {
      fetcher: fetchLinear,
    };
  }, []);

  return <SWRConfig value={config}>{children}</SWRConfig>;
}
