import type { Attachment, Issue } from "./types";

/** Types of issue links. */
export enum IssueLinkType {
  ATTACHMENT = "attachment",
  URL = "url",
}

/** A subset of properties in a Figma node used for issue links. */
export interface IssueLinkNode {
  id: BaseNode["id"];
  name: BaseNode["name"];
  type: BaseNode["type"];
}

/** Input used to create an issue link. */
interface IssueLinkBaseInput {
  issue: Issue;
  file: DocumentNode;
  node: BaseNode;
  page: PageNode;
}

/** A base class for issue links. */
abstract class IssueLinkBase {
  public node: IssueLinkNode;
  public page: {
    id: PageNode["id"];
    name: PageNode["name"];
  };
  public file: {
    name: DocumentNode["name"];
  };
  public readonly issueId: Issue["id"];

  public constructor(data: IssueLinkBaseInput) {
    const { issue, file, page, node } = data;

    this.issueId = issue.id;

    this.file = {
      name: file.name,
    };

    this.page = {
      id: page.id,
      name: page.name,
    };

    this.node = {
      id: node.id,
      name: node.name,
      type: node.type,
    };
  }
}

/**
 * Creates a link from an issue to a Figma node from an issue attachment. These
 * issue links are persisted to the API through attachments.
 *
 * @returns An issue link linking a Figma node to a Linear attachment.
 */
export class IssueLinkFromAttachment extends IssueLinkBase {
  public readonly linkType = IssueLinkType.ATTACHMENT;
  public readonly attachmentId: string;

  public constructor(data: IssueLinkBaseInput & { attachment: Attachment }) {
    super(data);

    const { attachment } = data;

    this.attachmentId = attachment.id;
  }
}

/**
 * Creates a link from an issue to a Figma node from a URL. These issue
 * links aren't persisted to the API and are maintained only in plugin
 * data.
 *
 * @returns An issue link linking a Figma node to a Figma URL for the current
 * file found in a Linear issue description.
 */
export class IssueLinkFromURL extends IssueLinkBase {
  public readonly linkType = IssueLinkType.URL;

  public readonly url: string;

  public constructor(data: IssueLinkBaseInput & { url: string }) {
    super(data);

    this.url = data.url;
  }
}

/** A union of all possible issue link types. */
export type IssueLink = IssueLinkFromAttachment | IssueLinkFromURL;

/** A map of issue IDs to issue links. */
export interface IssueLinksMap {
  [key: Issue["id"]]: IssueLink[];
}
