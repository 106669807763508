import useSWRMutation from "swr/mutation";
import { Config } from "~/config";
import { fetchLinear } from "~/utils/fetchLinear";

const OauthAuthAuthStringCheck = `
  mutation OauthAuthStringCheck($appId: String! $authString: String!) {
    oauthAuthStringCheck(appId: $appId, authString: $authString) {
      success
      token
    }
  }
`;

interface OAuthAuthStringCheckReturn {
  oauthAuthStringCheck: {
    success: boolean;
    token: string;
  };
}

interface OAuthStringCheckVars {
  authString: string;
}

export function useOAuthAuthStringCheck() {
  return useSWRMutation(OauthAuthAuthStringCheck, async (query, { arg }: { arg: OAuthStringCheckVars }) => {
    const response = await fetchLinear<OAuthAuthStringCheckReturn>([
      query,
      {
        appId: Config.OAUTH_CLIENT_ID,
        authString: arg.authString,
      },
    ]);

    return response.oauthAuthStringCheck;
  });
}
