import useSWR, { type SWRResponse } from "swr";
import type { Issue } from "shared/types";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import { IssueFragment } from "~/queries/fragments/IssueFragment";
import type { Connection, FetchedIssue } from "~/types";
import { flattenFetchedIssue } from "~/utils/flattenFetchedIssue";

const UserAssignedIssuesQuery = `
  query UserAssignedIssues($first: Int) {
    issues(filter: {
      assignee: {
        isMe: {
          eq: true
        }
      },
      completedAt: {
        null: true
      }
    }, first: $first) {
      edges {
        node {
          ${IssueFragment}
        }
      }
    }
  }
`;

interface UserAssignedIssuesQueryReturn {
  issues: Connection<FetchedIssue>;
}

/** Returns incomplete issues assigned to the current user. */
export function useUserAssignedIssues(first: number = 15): SWRResponse<Issue[]> {
  return useSWR([UserAssignedIssuesQuery, { first }], async args => {
    const response = await fetchLinear<UserAssignedIssuesQueryReturn>(args);
    const issues = flattenConnection(response.issues);

    return issues.map(flattenFetchedIssue);
  });
}
