import type { Issue } from "shared/types";
import type { FetchedIssue } from "~/types";
import { flattenConnection } from "~/utils/flattenConnection";

/**
 * Flattens any connections in a fetched issue.
 *
 * @param issue The fetched issue to flatten.
 * @returns The flattened issue object.
 */
export function flattenFetchedIssue(issue: FetchedIssue): Issue {
  const attachments = flattenConnection(issue.attachments);

  let assignee = null;
  if (issue.assignee) {
    assignee = {
      ...issue.assignee,
      teams: flattenConnection(issue.assignee.teams),
    };
  }

  return {
    ...issue,
    assignee,
    attachments,
  };
}
