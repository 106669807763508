import styled from "styled-components";
import { IconButton as OrbiterIconButton } from "@linear/orbiter/components/IconButton";
import { gridSpacePx } from "~/styles/gridSpace";

export const IconButton = styled(OrbiterIconButton)<{ fill?: string; inset?: "right" | "left" }>`
  --inset-x: -${gridSpacePx(1)};
  border-radius: 3px;
  height: ${gridSpacePx(6)};
  min-width: 0;
  width: ${gridSpacePx(6)};

  ${props => {
    if (props.inset === "right") {
      return `
        margin-right: var(--inset-x);
      `;
    } else if (props.inset === "left") {
      return `
        margin-left: var(--inset-x);
      `;
    }

    return undefined;
  }}

  &[data-state="open"],
  &:enabled:hover {
    background-color: var(--figma-color-bg-hover);

    // Allow for custom fill color on hover
    svg {
      fill: ${props => props.fill ?? undefined};
    }
  }
`;

export const LargeIconButton = styled(IconButton)`
  --inset-x: -${gridSpacePx(2)};
  height: ${gridSpacePx(8)};
  width: ${gridSpacePx(8)};
`;
