/**
 * Takes an object representing CSS style rules and constructs a CSS valid
 * string.
 */
export function objToCSS(obj: Record<string, string>) {
  return Object.entries(obj).reduce((cssString, [cssKey, cssValue]) => {
    cssString += `${cssKey}: ${cssValue}; \n`;

    return cssString;
  }, "");
}
