import * as React from "react";

export function FrameIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" aria-hidden {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0.5V1V3H8V1V0.5H9V1V3H11H11.5V4H11H9L9 8H11H11.5V9H11H9V11V11.5H8V11V9H4V11V11.5H3V11V9H1H0.5V8H1H3L3 4H1H0.5V3H1H3V1V0.5H4ZM8 8V4H4L4 8H8Z"
        fill="currentColor"
      />
    </svg>
  );
}
