import React, { useCallback } from "react";
import styled from "styled-components";
import type { Issue, User } from "shared/types";
import { observer } from "mobx-react-lite";
import { WorkflowStateHelper } from "@linear/common/models/helpers/StatusHelpers/WorkflowStateHelper";
import { FilterIcon } from "@linear/orbiter/icons/figma/FilterIcon";
import { useStoredState } from "~/hooks/useStoredState";
import type { PopoverMenuItemShape } from "./Popover/PopoverMenuItem";
import { FilterAndSort } from "./FilterAndSort";
import { LargeIconButton } from "./IconButton";

type LinkedIssueSorter = (a: Issue, b: Issue) => number;
const linkedIssueSorters: PopoverMenuItemShape<LinkedIssueSorter>[] = [
  {
    key: "status-sort",
    label: "Sort by status",
    value(a: Issue, b: Issue) {
      return WorkflowStateHelper.getPositionOrder(b.state) - WorkflowStateHelper.getPositionOrder(a.state);
    },
  },
  {
    key: "created-date-sort",
    label: "Sort by created date",
    value(a: Issue, b: Issue) {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    },
  },
  {
    key: "priority-sort",
    label: "Sort by priority",
    value(a: Issue, b: Issue) {
      return b.priority - a.priority;
    },
  },
  {
    key: "name-sort",
    label: "Sort by name",
    value(a: Issue, b: Issue) {
      return a.title.localeCompare(b.title, undefined, { ignorePunctuation: true });
    },
  },
];
const defaultSorter = linkedIssueSorters[0];

type LinkedIssueFilter = (issue: Issue, currentUser?: User) => boolean;
const linkedIssueFilters: PopoverMenuItemShape<LinkedIssueFilter>[] = [
  {
    key: "completed-filter",
    label: "Hide completed",
    value(issue: Issue) {
      return WorkflowStateHelper.getStateTypeOrder(issue.state.type) !== 4;
    },
  },
  {
    key: "canceled-filter",
    label: "Hide canceled",
    value(issue: Issue) {
      return WorkflowStateHelper.getStateTypeOrder(issue.state.type) !== 5;
    },
  },
  {
    key: "assigned-to-me-filter",
    label: "Only assigned to me",
    value(issue: Issue, currentUser?: User) {
      return issue.assignee?.id === currentUser?.id;
    },
  },
  {
    key: "created-by-me-filter",
    label: "Only created by me",
    value(issue: Issue, currentUser?: User) {
      return issue.creator?.id === currentUser?.id;
    },
  },
];
const defaultLinkedIssueFilters: PopoverMenuItemShape<LinkedIssueFilter>[] = [];

/**
 * Callback for handling changes to the sorting and filtering of linked issues.
 *
 * @param sorter The sort method to use for linked issues.
 * @param filters The filters to apply to linked issues.
 */
type OnSortFilterChange = (
  sorter?: PopoverMenuItemShape<LinkedIssueSorter>,
  filters?: PopoverMenuItemShape<LinkedIssueFilter>[]
) => void;

interface LinkedIssuesFilterAndSortProps {
  /** Callback for handling changes to the sorting and filtering of linked issues. */
  onSortFilterChange: OnSortFilterChange;
  /** The currently selected linked issues filters. */
  selectedFilters: PopoverMenuItemShape<LinkedIssueFilter>[];
  /** The currently selected linked issues sorting method. */
  selectedSorter: PopoverMenuItemShape<LinkedIssueSorter>;
}

export const LinkedIssuesFilterAndSort = observer(function LinkedIssuesFilterAndSort_(
  props: LinkedIssuesFilterAndSortProps
) {
  const { onSortFilterChange, selectedFilters, selectedSorter } = props;

  return (
    <FilterAndSort
      contentProps={{
        align: "end",
        collisionPadding: undefined,
      }}
      filters={linkedIssueFilters}
      onFiltersChange={filters => {
        onSortFilterChange(undefined, filters);
      }}
      onSortChange={sorter => {
        onSortFilterChange(sorter, undefined);
      }}
      selectedFilters={selectedFilters}
      selectedSorter={selectedSorter}
      sorters={linkedIssueSorters}
    >
      <FilterAndSortButton
        inset="right"
        aria-label="Filter and sort linked issues"
        onClick={e => {
          // Prevent clicking the button from propagating through the UI.
          // Prevents things like the active selection being cleared when
          // clicking the filter+sort menu.
          e.stopPropagation();
        }}
      >
        <FilterIcon />
      </FilterAndSortButton>
    </FilterAndSort>
  );
});

const FilterAndSortButton = styled(LargeIconButton)`
  &[data-state="open"],
  &:enabled:hover {
    background-color: var(--figma-color-bg-hover);

    // Override Orbiter default hover styles on icon buttons
    svg {
      fill: none;
      stroke: var(--figma-color-text);
    }
  }
`;

/**
 * Hook for managing sorting and filtering of linked issues.
 *
 * @param issues The issues to sort and filter.
 * @returns A tuple with the sorted and filtered issues, a function to update
 * the sorting and filtering, and the controlled selected filters and sorters.
 */
export function useSortedAndFilteredIssues(
  issues: Issue[],
  currentUser?: User
): [
  Issue[],
  OnSortFilterChange,
  {
    selectedFilters: PopoverMenuItemShape<LinkedIssueFilter>[];
    selectedSorter: PopoverMenuItemShape<LinkedIssueSorter>;
  },
] {
  const [selectedFilterKeys, setSelectedFilterKeys] = useStoredState(
    "linked-issue-filters",
    defaultLinkedIssueFilters.map(filter => filter.key)
  );
  const [selectedSorterKey, setSelectedSorterKey] = useStoredState("linked-issue-sorter", defaultSorter.key);

  const selectedFilters = linkedIssueFilters.filter(filter => selectedFilterKeys.includes(filter.key));
  const selectedSorter = linkedIssueSorters.find(sorter => sorter.key === selectedSorterKey) ?? defaultSorter;

  // If a new sorter or filter isn't provided, use the existing one. For
  // example, clearing the current set of filters while preserving the existing
  // sort method.
  const onSortFilterChange: OnSortFilterChange = useCallback(
    (newSorter, newFilters) => {
      const newSelectedFilterKeys = newFilters?.map(filter => filter.key) ?? selectedFilterKeys;
      const newSelectedSorterKey = newSorter?.key ?? selectedSorterKey;

      setSelectedSorterKey(newSelectedSorterKey);
      setSelectedFilterKeys(newSelectedFilterKeys);
    },
    [selectedFilterKeys, selectedSorterKey]
  );

  const issuesToDisplay = issues
    .filter(issue => {
      if (!issue) {
        return false;
      }

      return selectedFilters.every(filter => filter.value(issue, currentUser));
    })
    .sort(selectedSorter.value);

  return [
    issuesToDisplay,
    onSortFilterChange,
    {
      selectedFilters,
      selectedSorter,
    },
  ];
}
