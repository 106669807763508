import { useCallback, useEffect, useState } from "react";
import { differenceInMinutes } from "date-fns";
import { Config } from "~/config";
import { useOAuthAuthStringCheck } from "~/queries/useOAuthAuthStringCheck";
import { openExternalLink } from "~/utils/links";

const authParams = new URLSearchParams();
authParams.append("client_id", Config.OAUTH_CLIENT_ID);
authParams.append("response_type", "stringauth");
authParams.append("redirect_uri", Config.OAUTH_REDIRECT_URI);
authParams.append("scope", Config.OAUTH_SCOPE.join(","));

const authUrl = `${Config.OAUTH_URL}?${authParams.toString()}`;

interface AuthCheckProps {
  authString: string | undefined;
  onReset(): void;
  onSuccess(accessToken: string): void;
}

/**
 * Opens a browser window with a given authString to authentication through
 * OAuth, then polls the OAuth authString endpoint to see if the given
 * authString has successfully been authenticated.
 */
export function useOAuthAuthStringAuthentication(props: AuthCheckProps) {
  const { authString, onReset, onSuccess } = props;

  const [startedPollingAt] = useState<Date>(new Date());

  const oAuthAuthStringCheck = useOAuthAuthStringCheck();

  const poll = useCallback(async () => {
    // Stop polling and reset state after the token expires anyway.
    if (differenceInMinutes(new Date(), startedPollingAt) >= 5) {
      onReset();
      return;
    }

    if (!authString) {
      return;
    }

    const result = await oAuthAuthStringCheck.trigger({
      authString,
    });

    if (result?.success && result?.token) {
      onSuccess(result.token);
      return;
    }
  }, [onSuccess, onReset]);

  useEffect(() => {
    if (!authString) {
      return;
    }

    openExternalLink(authUrl + `&authstring=${authString}`);
    const interval = setInterval(poll, 3000);
    return () => clearInterval(interval);
  }, [authString]);
}
