import { useState } from "react";
import useSWR, { type SWRResponse } from "swr";
import { runInAction } from "mobx";
import { UpdateIssueLinksMessage } from "shared/messages";
import type { IssueLink, IssueLinksMap } from "shared/IssueLink";
import type { Issue } from "shared/types";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import { IssueFragment } from "~/queries/fragments/IssueFragment";
import { store } from "~/store";
import type { Connection, FetchedIssue } from "~/types";
import { postPluginMessage } from "~/utils/postPluginMessage";
import { flattenFetchedIssue } from "~/utils/flattenFetchedIssue";

// Maximum issues that the query complexity allows
const LINKED_ISSUE_LIMIT = 25;

export const LinkedIssuesQuery = `
  query IssuesById(
    $cursor: String,
    $issueIds: [ID!],
  ) {
    issues(
      after: $cursor,
      filter: {
        id: {
          in: $issueIds
        }
      },
      first: ${LINKED_ISSUE_LIMIT},
  ) {
      edges {
        node {
          ${IssueFragment}
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
    }
  }
`;

interface LinkedIssuesQueryPageInfo {
  endCursor: string;
  hasNextPage: boolean;
  startCursor: string;
}

interface LinkedIssuesQueryReturn {
  issues: Connection<FetchedIssue> & {
    pageInfo: LinkedIssuesQueryPageInfo;
  };
}

/** Queries the API for issues that have linked nodes on the Figma canvas. */
export function useLinkedIssues(issueLinks: IssueLinksMap): SWRResponse<boolean> {
  // Filter out empty issue links from the linked issues request
  const issueIds = Object.keys(issueLinks).filter(issueId => {
    return issueLinks[issueId].length > 0;
  });

  const [previousPageInfo, setPreviousPageInfo] = useState<LinkedIssuesQueryPageInfo | null>(null);

  const preventQuery = issueIds.length === 0;

  const linkedIssuesSWR = useSWR(
    preventQuery ? null : [LinkedIssuesQuery, { issueIds, cursor: previousPageInfo?.endCursor }],
    async args => {
      const response = await fetchLinear<LinkedIssuesQueryReturn>(args);

      // Create a map of issues by id
      const issues = flattenConnection(response.issues).reduce(
        (acc, issue) => {
          acc[issue.id] = flattenFetchedIssue(issue);
          return acc;
        },
        {} as Record<string, Issue>
      );

      // Get the issue ids for the current page
      const startIndex = issueIds.indexOf(response.issues.pageInfo.startCursor);
      const endIndex = issueIds.indexOf(response.issues.pageInfo.endCursor);
      const fetchedIds = issueIds.slice(startIndex, endIndex + 1);

      // Remove all issue links for issues that no longer exist in the fetched
      // page
      const removedIssueLinks = fetchedIds.reduce((acc, issueId) => {
        if (!issues[issueId]) {
          acc.push(...issueLinks[issueId]);
        }

        return acc;
      }, [] as IssueLink[]);

      store.removeIssueLinks(removedIssueLinks);

      // Plugin won't know about added Figma links in Linear for existing
      // issues so we need to sync with the plugin to get them added locally.
      postPluginMessage(new UpdateIssueLinksMessage(issues));

      // Append the current page's issues to the store
      runInAction(() => {
        store.linkedIssues = {
          ...store.linkedIssues,
          ...issues,
        };
      });

      // Update the page info for continually fetching pages. When there are no
      // more pages to fetch, reset the stored previous page information so the
      // cursor is reset.
      setPreviousPageInfo(() => {
        if (response.issues.pageInfo.hasNextPage) {
          return response.issues.pageInfo;
        } else {
          return null;
        }
      });

      return true;
    },
    {
      refreshInterval: 30000,
    }
  );

  return linkedIssuesSWR;
}
