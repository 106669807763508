import * as React from "react";
import type { Issue } from "shared/types";
import { Priority0Icon } from "@linear/orbiter/icons/base/Priority0Icon";
import { Priority1Icon } from "@linear/orbiter/icons/base/Priority1Icon";
import { Priority2Icon } from "@linear/orbiter/icons/base/Priority2Icon";
import { Priority3Icon } from "@linear/orbiter/icons/base/Priority3Icon";
import { Priority4Icon } from "@linear/orbiter/icons/base/Priority4Icon";

type Props = {
  priority: Issue["priority"];
  muted?: boolean;
};

export const IssuePriorityIcon = (props: Props) => {
  const { priority, muted, ...rest } = props;

  switch (priority) {
    case 1:
      return <Priority1Icon muted={muted} {...rest} />;
    case 2:
      return <Priority2Icon {...rest} />;
    case 3:
      return <Priority3Icon {...rest} />;
    case 4:
      return <Priority4Icon {...rest} />;
    default:
      return <Priority0Icon {...rest} />;
  }
};
