/**
 * Shared types used in messages between Figma and the Plugin UI.
 */

import type { StateType } from "@linear/common/models/WorkflowStateType";

export enum FigmaEditorTheme {
  Light = "light",
  Dark = "dark",
}

export enum FigmaEditorType {
  Figma = "figma",
  Figjam = "figjam",
  Dev = "dev",
}

/**
 * A small representation of the data used for Figma files in messages.
 */
export interface MessageFile {
  id: DocumentNode["id"];
  name: DocumentNode["name"];
}

/**
 * A small representation of the data used for Figma nodes in messages.
 */
export interface SelectedNode {
  id: BaseNode["id"];
  name: BaseNode["name"];
  type: BaseNode["type"];
}

/**
 * A small representation of the data used for Figma pages in messages.
 */
export interface MessagePage {
  id: PageNode["id"];
  name: PageNode["name"];
  type: PageNode["type"];
}

export interface Attachment {
  id: string;
  sourceType: string;
  title: string;
  url: string;
}

export interface Issue {
  assignee: User | null;
  attachments: Attachment[];
  createdAt: string;
  creator: User | null;
  cycle: Cycle;
  id: string;
  identifier: string;
  description: string | null;
  state: State;
  priority: Priority;
  priorityLabel: string;
  project: Project | null;
  team: Team;
  title: string;
  url: string;
}

export interface IssueSearch {
  metadata: {
    score: number;
  };
}

export enum Priority {
  None = 0,
  Urgent = 1,
  High = 2,
  Medium = 3,
  Low = 4,
}

export interface Project {
  color: string;
  id: string;
  name: string;
}

export interface State {
  color: string;
  id: string;
  name: string;
  position: number;
  type: StateType;
  team: {
    defaultIssueState?: {
      id: string;
    };
    states: {
      edges: {
        node: State;
      }[];
    };
  };
}

export interface Team {
  color: string;
  icon: string;
  id: string;
  key: string;
  name: string;
  private: boolean;
}

export interface Organization {
  id: string;
  logoUrl: string;
  name: string;
}

export interface Cycle {
  completedAt: Date;
  name: string;
  number: number;
  startsAt: Date;
}

export interface User {
  admin: boolean;
  avatarUrl?: string;
  displayName: string;
  email: string;
  id: string;
  name: string;
  organization: Organization;
  teams: Team[];
}
