import { type BaseMessage, Message } from "./Message";

/**
 * Updates the selection on the Figma canvas to a set of nodes found by the
 * given ids.
 */
export class SelectNodesMessage implements BaseMessage {
  public readonly message = Message.SELECT_NODES;
  public constructor(public readonly data: SceneNode["id"][]) {}
}
