import React from "react";
import styled from "styled-components";
import type { SelectedNode } from "shared/types";
import type { IssueLink } from "shared/IssueLink";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { CloseIcon } from "@linear/orbiter/icons/figma/CloseIcon";
import { NodeIcon } from "~/components/NodeIcon";
import { useSelectNodes } from "~/hooks/useSelectNodes";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { useRemoveIssueLinks } from "~/queries/useRemoveIssueAttachments";
import { IconButton } from "./IconButton";

interface NodeListProps {
  /**
   * Allows the node items to select the corresponding node in the Figma
   * canvas.
   */
  selectableNodes?: boolean;
  nodes: SelectedNode[];
  /**
   * Allow for node items to be removed from a linked issue.
   */
  removableIssueLinks?: IssueLink[];
}

/**
 * Shows a list of Figma nodes with an associated icon and the node name.
 */
export function NodeList(props: NodeListProps) {
  const { selectableNodes, nodes, removableIssueLinks = [] } = props;

  const selectNodes = useSelectNodes();
  const removeIssueLink = useRemoveIssueLinks();

  const Nodes = nodes.map((node, i) => {
    const handleClick = selectableNodes
      ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.stopPropagation();
          selectNodes([node.id]);
        }
      : undefined;

    return (
      <NodeListItemFlex
        align="center"
        gap={gridSpace(2)}
        isSelectable={selectableNodes}
        key={node.id}
        onClick={handleClick}
      >
        <NodeIcon type={node.type} />
        <Flex align="center" gap={gridSpace(1)}>
          <Text>{node.name}</Text>
          {removableIssueLinks[i] && (
            <RemoveIconButton
              aria-label="Remove node"
              onClick={async e => {
                // Prevent the node selection click.
                e.stopPropagation();
                await removeIssueLink.trigger([removableIssueLinks[i]]);
              }}
            >
              <RemoveIcon />
            </RemoveIconButton>
          )}
        </Flex>
      </NodeListItemFlex>
    );
  });

  return <>{Nodes}</>;
}

const NodeListItemFlex = styled(Flex)<{ isSelectable?: boolean }>`
  &:hover {
    text-decoration: ${props => (props.isSelectable ? "underline" : undefined)};
  }
`;

export const NodeListFlex = styled(Flex)`
  width: max-content;
`;

const RemoveIconButton = styled(IconButton)`
  border-radius: 2px;
  color: var(--figma-color-text);
  height: ${gridSpacePx(3)};
  opacity: 0;
  padding: ${gridSpacePx(0.5)};
  width: ${gridSpacePx(3)};

  ${NodeListItemFlex}:hover & {
    opacity: 1;
  }

  &:enabled:hover {
    // Currently, the close button is only shown on actively selected list items
    background-color: var(--figma-color-bg-selected-hover);
  }
`;

const RemoveIcon = styled(CloseIcon)`
  height: 100%;
  width: 100%;
`;

NodeListFlex.defaultProps = {
  column: true,
  gap: gridSpace(2),
};
