"use client";

import React from "react";
import { useTheme } from "styled-components";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export const StatusTriageIcon = (props: IconProps) => {
  const { color, ...etc } = props;
  const theme = useTheme();

  return (
    <Icon width="14" height="14" viewBox="0 0 14 14" noFill {...etc}>
      <svg>
        <path
          fill={color || theme.color.labelMuted}
          d="M7 14C10.866 14 14 10.866 14 7C14 3.13403 10.866 0 7 0C3.134 0 0 3.13403 0 7C0 10.866 3.134 14 7 14ZM8.0126 9.50781V7.98224H5.9874V9.50787C5.9874 9.92908 5.4767 10.1549 5.14897 9.8786L2.17419 7.37073C1.94194 7.17493 1.94194 6.82513 2.17419 6.62933L5.14897 4.12146C5.4767 3.84515 5.9874 4.07098 5.9874 4.49219V6.01764H8.0126V4.49213C8.0126 4.07092 8.5233 3.84509 8.85103 4.1214L11.8258 6.62927C12.0581 6.82507 12.0581 7.17487 11.8258 7.37067L8.85103 9.87854C8.5233 10.1548 8.0126 9.92902 8.0126 9.50781Z"
        />
      </svg>
    </Icon>
  );
};
