import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Flex } from "@linear/orbiter/components/Flex";
import { MenuIcon } from "@linear/orbiter/icons/base/MenuIcon";
import { Config } from "~/config";
import { store } from "~/store";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { UserName } from "~/components/UserName";
import { IconButton } from "./IconButton";
import { Select } from "./Select";
import { TextTitle } from "./TextTitle";
import { UserAvatar } from "./UserAvatar";
import type { PopoverMenuItemShape } from "./Popover/PopoverMenuItem";

/**
 * A bottom-anchored menu for account actions.
 */
export const FooterBar = observer(function FooterBar_() {
  const menuOptions: PopoverMenuItemShape<() => void>[] = [
    {
      key: "log-out",
      label: "Log out",
      value() {
        store.logout();
      },
    },
  ];

  if (!Config.PROD) {
    menuOptions.unshift({
      key: "clear-plugin-data",
      label: "[DEV] Clear plugin data",
      destructive: true,
      value() {
        store.clearStorage();
      },
    });
  }

  return (
    <FooterMenuWrapper>
      <FooterMenuFlex align="center" gap={gridSpace(2)}>
        <UserAvatar src={store.user?.avatarUrl} modelId={store.user?.id} size="tiny" />
        <TextTitle truncate>
          <UserName user={store.user} />
        </TextTitle>
        <Select
          contentProps={{
            side: "top",
          }}
          items={menuOptions}
          onSelect={item => {
            item.value();
          }}
        >
          <IconButton aria-label="Open menu">
            <MenuIcon />
          </IconButton>
        </Select>
      </FooterMenuFlex>
    </FooterMenuWrapper>
  );
});

const FooterMenuWrapper = styled.div`
  background-color: var(--figma-color-bg);
  border-top: 1px solid var(--figma-color-border);
  padding: ${gridSpacePx(2)} var(--panel-x-padding);
  z-index: 2; // Appears above any lists
`;

const FooterMenuFlex = styled(Flex)`
  position: relative;

  // Padding on the right-side to prevent overflow with Figma (?) help button
  max-width: calc(100% - ${gridSpacePx(17)});
`;
