import React from "react";
import type { Project, State, Team, User } from "shared/types";
import { Flex } from "@linear/orbiter/components/Flex";
import { TeamsIcon } from "@linear/orbiter/icons/base/TeamsIcon";
import { ProjectIcon } from "@linear/orbiter/icons/decorative/ProjectIcon";
import { Text } from "@linear/orbiter/components/Text";
import { Priority0Icon } from "@linear/orbiter/icons/base/Priority0Icon";
import { gridSpace } from "~/styles/gridSpace";
import { PriorityInputControl, type PriorityOption } from "~/components/IssueInputControls/PriorityInputControl";
import { ProjectInputControl } from "./IssueInputControls/ProjectInputControl";
import { TeamInputControl } from "./IssueInputControls/TeamInputControl";
import { UserInputControl } from "./IssueInputControls/UserInputControl";
import { UserAvatar } from "./UserAvatar";
import { InlineInputControlButton, InlineInputControlButtonLabel } from "./IssueInputControls/InputControlButton";
import { StatusInputControl } from "./IssueInputControls/StatusInputControl";
import { WorkflowStateIcon } from "./WorkflowStateIcon";

export interface CreateIssueInputState {
  priority: PriorityOption | null;
  project: Project | null;
  status: State | null;
  team: Team | null;
  user: User | null;
}

interface CreateIssueFormInputControlsProps {
  createIssueInputState: CreateIssueInputState;
  onCreateIssueInputStateChange: (createIssueInputState: Partial<CreateIssueInputState>) => void;
}

export function CreateIssueFormInputControls(props: CreateIssueFormInputControlsProps) {
  const { createIssueInputState: inputControlState, onCreateIssueInputStateChange: onInputControlStateChange } = props;

  const {
    project: selectedProject,
    priority: selectedPriority,
    status: selectedStatus,
    team: selectedTeam,
    user: selectedUser,
  } = inputControlState;

  return (
    <Flex className="mt-1" gap={gridSpace(1)}>
      <TeamInputControl
        onSelectTeam={team => {
          onInputControlStateChange({
            team,
          });
        }}
        selectedTeam={selectedTeam}
      >
        <InlineInputControlButton flex={2}>
          <TeamsIcon />
          <Text truncate>{selectedTeam?.key}</Text>
        </InlineInputControlButton>
      </TeamInputControl>
      <StatusInputControl
        onSelectStatus={status => {
          onInputControlStateChange({
            status,
          });
        }}
        selectedStatus={selectedStatus}
        teamId={selectedTeam?.id}
      >
        <InlineInputControlButton>
          <WorkflowStateIcon state={selectedStatus} />
          <InlineInputControlButtonLabel>{selectedStatus?.name ?? "Backlog"}</InlineInputControlButtonLabel>
        </InlineInputControlButton>
      </StatusInputControl>
      <PriorityInputControl
        onSelectPriority={priority => {
          onInputControlStateChange({
            priority,
          });
        }}
        selectedPriority={selectedPriority}
      >
        <InlineInputControlButton title={selectedPriority?.name ?? "Priority"}>
          {selectedPriority?.icon ?? <Priority0Icon />}
          <InlineInputControlButtonLabel>{selectedPriority?.name ?? "Priority"}</InlineInputControlButtonLabel>
        </InlineInputControlButton>
      </PriorityInputControl>
      <UserInputControl
        selectedUser={selectedUser}
        onSelectUser={user => {
          onInputControlStateChange({
            user,
          });
        }}
        selectedTeam={selectedTeam}
      >
        <InlineInputControlButton flex={1}>
          <UserAvatar modelId={selectedUser?.id} name={selectedUser?.name} size="tiny" src={selectedUser?.avatarUrl} />
          <InlineInputControlButtonLabel>{selectedUser?.name ?? "Assignee"}</InlineInputControlButtonLabel>
        </InlineInputControlButton>
      </UserInputControl>
      <ProjectInputControl
        onSelectProject={project => {
          onInputControlStateChange({
            project,
          });
        }}
        selectedProject={selectedProject}
        teamId={selectedTeam?.id}
      >
        <InlineInputControlButton flex={1}>
          <ProjectIcon />
          <Text truncate>{selectedProject?.name ?? "No project"}</Text>
        </InlineInputControlButton>
      </ProjectInputControl>
    </Flex>
  );
}
