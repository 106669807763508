import React, { useState } from "react";
import styled from "styled-components";
import type { Issue } from "shared/types";
import { Flex } from "@linear/orbiter/components/Flex";
import { fontSize } from "@linear/orbiter/styles/mixins";
import { IssueHelper } from "@linear/common/models/IssueHelper";
import { Text } from "@linear/orbiter/components/Text";
import { useKeyPressEvent } from "@linear/orbiter/hooks/useKeyPressEvent";
import { KeyboardHelper } from "@linear/orbiter/utils/KeyboardHelper";
import { Textarea } from "~/components/Textarea";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { AddIssueAttachments } from "~/components/AddIssueAttachments";
import { FormButton } from "~/components/FormButton";
import { useCreateIssue } from "~/queries/useCreateIssue";
import { useLinkIssue } from "~/queries/useLinkIssue";
import { uiFigma } from "~/store/UIFigma";
import { AddIssueNestedHeader } from "./AddIssueNestedHeader";
import { TextTitle } from "./TextTitle";
import { CreateIssueFormInputControls, type CreateIssueInputState } from "./CreateIssueFormInputControls";

interface CreateIssueFormProps {
  defaultTitle?: string;
  onCancelLinkingIssue: () => void;
  onIssueLinked: (linkedIssue: Issue) => void;
}

export function CreateIssueForm(props: CreateIssueFormProps) {
  const { defaultTitle, onCancelLinkingIssue, onIssueLinked } = props;

  const [issueTitle, setIssueTitle] = useState(defaultTitle ?? "");
  const [issueDescription, setIssueDescription] = useState("");

  const [createIssueInputState, setCreateIssueInputState] = useState<CreateIssueInputState>({
    priority: null,
    project: null,
    status: null,
    team: null,
    user: null,
  });

  const createIssue = useCreateIssue();

  const linkIssueRequest = useLinkIssue({
    onIssueLinked(linkedIssue) {
      onIssueLinked(linkedIssue);
    },
  });

  const isLoading = createIssue.isMutating || linkIssueRequest.isMutating || linkIssueRequest.isLinkingIssue;
  const isSubmitDisabled = !issueTitle || isLoading;

  // Create the issue in Linear and link it to the current selection
  async function createAndLinkIssue() {
    if (isSubmitDisabled) {
      return;
    }

    const createdIssue = await createIssue.trigger({
      assigneeId: createIssueInputState.user?.id,
      description: issueDescription,
      priority: createIssueInputState.priority?.value,
      projectId: createIssueInputState.project?.id,
      teamId: createIssueInputState.team?.id ?? "",
      stateId: createIssueInputState.status?.id,
      title: issueTitle,
    });

    if (!createdIssue) {
      // Error is shown in the form below
      return;
    }

    return await linkIssueRequest.trigger({
      file: uiFigma.file,
      fileKey: uiFigma.fileKey,
      issue: createdIssue,
      page: uiFigma.currentPage,
      selection: uiFigma.selection,
    });
  }

  // Add Mod+Enter keyboard event to submit the form
  useKeyPressEvent(
    event => {
      return KeyboardHelper.isModKey(event) && event.key === "Enter";
    },
    createAndLinkIssue,
    undefined,
    {
      runWithInputElementFocus: true,
    }
  );

  return (
    <CreateIssueFlex column grow={1}>
      <AddIssueNestedHeader onBack={onCancelLinkingIssue}>
        <TextTitle>Create new issue</TextTitle>
      </AddIssueNestedHeader>
      <CreateIssueEdit column gap={gridSpace(2)}>
        <CreateIssueTitle
          delayAutoFocus={100}
          defaultValue={issueTitle}
          maxLength={IssueHelper.maxTitleLength}
          onChange={e => {
            setIssueTitle(e.target.value);
          }}
          placeholder="Issue title"
        />
        <CreateIssueDescription
          onChange={e => {
            setIssueDescription(e.target.value);
          }}
          placeholder="Add a description…"
        />
        <CreateIssueFormInputControls
          createIssueInputState={createIssueInputState}
          onCreateIssueInputStateChange={(newInputControlState: Partial<CreateIssueInputState>) => {
            setCreateIssueInputState(prevInputControlState => ({
              ...prevInputControlState,
              ...newInputControlState,
            }));
          }}
        />
      </CreateIssueEdit>
      <AddIssueAttachments />
      <FormButtonFlex>
        {createIssue.error && (
          <Flex column>
            <Text color="labelMuted">There was an error creating the issue:</Text>
            <Text color="labelMuted">{createIssue.error?.toString()}</Text>
          </Flex>
        )}
        {linkIssueRequest.error && (
          <div>
            <Text>The issue was created but there was an error creating the Figma attachment:</Text>
            <Text>{linkIssueRequest.error.toString()}</Text>
          </div>
        )}
        <FormButton className="w-full" disabled={isSubmitDisabled} onClick={createAndLinkIssue}>
          {isLoading ? "Creating issue…" : "Create issue"}
        </FormButton>
      </FormButtonFlex>
    </CreateIssueFlex>
  );
}

const CreateIssueFlex = styled(Flex)`
  background-color: var(--figma-color-bg);
  width: 100%;
`;

const CreateIssueEdit = styled(Flex)`
  padding: ${gridSpacePx(2)} var(--panel-x-padding) ${gridSpacePx(5)};
`;

const CreateIssueTitle = styled(Textarea)`
  font-size: ${fontSize("large")};
  margin-left: -${gridSpacePx(2)};
  width: calc(100% + ${gridSpacePx(4)});
`;

const CreateIssueDescription = styled(Textarea)`
  margin-left: -${gridSpacePx(2)};
  min-height: 100px;
  overflow-y: auto;
  width: calc(100% + ${gridSpacePx(4)});

  // Specific height to show a cut-off portion of the attachments list so it appears as scrollable
  max-height: ${gridSpacePx(97)};
`;

const FormButtonFlex = styled(Flex)`
  margin: ${gridSpacePx(2)} var(--panel-x-padding) ${gridSpacePx(6)};
`;
