import * as React from "react";
import styled from "styled-components";
import { PortalContext } from "@linear/orbiter/components/Portal/ContextualPortal";
import { Flex } from "../components/Flex";

type ScrollableProps = {
  /**
   * Whether scrollbar gutter should be stable
   */
  gutter?: "auto" | "stable" | string;
  /**
   * Whether to apply background color
   */
  $applyBackground?: boolean;
};

/**
 * Flex container with the correct styles and properties for a scrollable container
 */
export const Scrollable = styled(Flex)<ScrollableProps>`
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
  background-color: ${props => (props.$applyBackground ? props.theme.color.scrollBackground : "inherit")};
  scrollbar-gutter: ${props => props.gutter || "auto"};
  &:focus-visible {
    outline: none;
  }
`;

// Scrollable regions should always be keyboard focusable
Scrollable.defaultProps = {
  tabIndex: 0,
};

/**
 * Div with the correct styles and properties for a scrollable container
 */
export const ScrollableDiv = styled("div")<ScrollableProps>`
  overflow-y: auto;
  position: relative;
  background-color: ${props => (props.$applyBackground ? props.theme.color.scrollBackground : "inherit")};
  scrollbar-gutter: ${props => props.gutter || "auto"};
  &:focus-visible {
    outline: none;
  }
`;

/**
 * Portal context around a div with the correct styles and properties for a scrollable container
 */
export function ScrollableDivWithPortalContext(props: React.ComponentProps<typeof ScrollableDiv>) {
  const [node, setNode] = React.useState<HTMLDivElement | null>(null);
  return (
    <PortalContext.Provider value={node}>
      <ScrollableDiv {...props} ref={setNode} />
    </PortalContext.Provider>
  );
}

// Scrollable regions should always be keyboard focusable
ScrollableDiv.defaultProps = {
  tabIndex: 0,
};
