import React from "react";
import styled from "styled-components";
import type { SelectedNode } from "shared/types";
import { Text } from "@linear/orbiter/components/Text";
import { Flex } from "@linear/orbiter/components/Flex";
import { NodeIcon } from "~/components/NodeIcon";
import { gridSpace } from "~/styles/gridSpace";
import type { FigmaSelection } from "~/store/UIFigma";

interface NodeSummaryProps {
  className?: string;
  nodes: FigmaSelection["nodes"];
}

/**
 * Shows a summary of figma nodes with a number and the node type's icon.
 */
export function NodeSummary(props: NodeSummaryProps) {
  const { className, nodes } = props;

  if (nodes.length === 0) {
    return null;
  }

  const nodeTotals = {
    FRAME: 0,
    PAGE: 0,
    SECTION: 0,
  };

  type SupportedNodeType = keyof typeof nodeTotals;

  nodes.forEach(node => {
    // Treat Component and Instance nodes as Frames
    const nodeType = ["COMPONENT", "INSTANCE"].includes(node.type) ? "FRAME" : node.type;

    if (nodeType in nodeTotals) {
      nodeTotals[nodeType as SupportedNodeType] += 1;
    }
  });

  return (
    <Flex align="center" className={className} gap={gridSpace(1)}>
      {Object.entries(nodeTotals).map(([type, total]) => {
        if (total === 0) {
          return null;
        }
        return (
          <Flex key={`node-${type}`} align="center" gap={gridSpace(1)}>
            <NodeIcon type={type as SelectedNode["type"]} />
            <StyledText>{total}</StyledText>
          </Flex>
        );
      })}
    </Flex>
  );
}

const StyledText = styled(Text)`
  color: inherit;
`;
