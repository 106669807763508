import * as React from "react";

export function SectionIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" aria-hidden {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4.5V4V1.5H1.5L1.5 4.5H5.5ZM5.5 0.5H1.5H1H0.5V1V1.5V4.5V5.5V10.5V11V11.5V11.5H1.5V11.5H10.5H11H11.5L11.5 11L11.5 10.5V1.5V1V0.5H11H10.5H6.5H5.5ZM10.5 1.5H6.5V4V4.5V5.5H6H1.5L1.5 10.5H10.5L10.5 1.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
