import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export function TeamsIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <svg>
        <path d="M10.5 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM8 10c2.338 0 3.6.475 3.972 1.424a.43.43 0 0 1 .028.157.419.419 0 0 1-.419.419H4.419A.419.419 0 0 1 4 11.581a.43.43 0 0 1 .028-.157C4.399 10.474 5.662 10 8 10Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 5.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C3.28 1 4.12 1 5.8 1h4.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C15 3.28 15 4.12 15 5.8v4.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C12.72 15 11.88 15 10.2 15H5.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C1 12.72 1 11.88 1 10.2V5.8Zm4.8-3.3h4.4c.865 0 1.423.001 1.848.036.408.033.559.09.633.127a1.5 1.5 0 0 1 .655.656c.038.074.095.225.128.633.035.425.036.983.036 1.848v4.4c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036H5.8c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848V5.8c0-.865.001-1.423.036-1.848.033-.408.09-.559.127-.633a1.5 1.5 0 0 1 .656-.656c.074-.037.225-.094.633-.127C4.377 2.5 4.935 2.5 5.8 2.5Z"
        />
      </svg>
    </Icon>
  );
}
