import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { SearchIcon } from "@linear/orbiter/icons/base/SearchIcon";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export function SearchInput(props: SearchInputProps) {
  const { className, ...rest } = props;

  return (
    <SearchFlex align="center" className={className} gap={gridSpace(2)}>
      <label htmlFor="search-input">
        <StyledSearchIcon />
      </label>
      <StyledSearchInput id="search-input" {...rest} />
    </SearchFlex>
  );
}

const SearchFlex = styled(Flex)`
  border-radius: 4px;
  border: 1px solid var(--figma-color-border);
  padding: ${gridSpacePx(3)};
  transition: border 0.1s linear;
`;

const StyledSearchIcon = styled(SearchIcon)`
  fill: ${props => props.theme.color.labelFaint};
  height: ${gridSpacePx(3)};
  position: relative;
  top: 2px;
  width: ${gridSpacePx(3)};
`;

const StyledSearchInput = styled.input`
  background: var(--figma-color-bg);
  border: none;
  color: var(--figma-color-text);
  font-size: 1rem;
  outline: 1px solid transparent;
  box-shadow: none;
  width: 100%;
`;
