import useSWR, { type SWRResponse } from "swr";
import type { State } from "shared/types";
import { WorkflowStateHelper } from "@linear/common/models/helpers/StatusHelpers/WorkflowStateHelper";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import type { Connection } from "~/types";

const TeamWorkflowQuery = `
  query TeamWorkflow($teamId: String!) {
    team(id: $teamId) {
      states {
        edges {
          node {
            color
            id
            name
            position
            type
            team {
              defaultIssueState {
                id
              }
              states {
                edges {
                  node {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Find all the workflow states in a team.
 */
export function useTeamWorkflow(teamId: string | null): SWRResponse<State[]> {
  return useSWR(
    teamId ? [TeamWorkflowQuery, { teamId }] : null,
    async args => {
      const response = await fetchLinear<{
        team: {
          states: Connection<State>;
        };
      }>(args);

      const states = flattenConnection(response.team.states);

      return states.sort((a, b) => {
        return WorkflowStateHelper.isStateBefore(b, a) ? 1 : -1;
      });
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );
}
