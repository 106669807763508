import type { Attachment, Issue } from "../types";
import { type BaseMessage, Message } from "./Message";

/** Message the plugin to create an issue link from attachments created. */
export class LinkIssueMessage implements BaseMessage {
  public readonly message = Message.LINK_ISSUE;
  public readonly data: {
    attachmentsCreated: Attachment[];
    issue: Issue;
  };

  public constructor(issue: Issue, attachmentsCreated: Attachment[]) {
    this.data = {
      attachmentsCreated,
      issue,
    };
  }
}
