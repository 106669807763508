import * as React from "react";

export function InstanceIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" aria-hidden {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.664062 6.06908L1.10775 5.62263L5.58263 1.11998L6.03185 0.667969L6.48107 1.11998L10.9559 5.62263L11.3996 6.06908L10.9559 6.51553L6.48107 11.0182L6.03185 11.4702L5.58263 11.0182L1.10775 6.51553L0.664062 6.06908ZM6.03185 9.67328L9.61382 6.06908L6.03185 2.46487L2.44988 6.06908L6.03185 9.67328Z"
        fill="currentColor"
      />
    </svg>
  );
}
