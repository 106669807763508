import { runInAction } from "mobx";
import useSWRMutation from "swr/mutation";
import type { Issue, Priority } from "shared/types";
import { store } from "~/store";
import { fetchLinear } from "~/utils/fetchLinear";
import type { FetchedIssue } from "~/types";
import { IssueFragment } from "~/queries/fragments/IssueFragment";
import { flattenFetchedIssue } from "~/utils/flattenFetchedIssue";

const UpdateIssueQuery = `
  mutation UpdateIssue(
    $assigneeId: String,
    $issueId: String!,
    $priority: Int,
    $projectId: String,
    $projectMilestoneId: String,
    $stateId: String,
    $teamId: String,
  ) {
    issueUpdate(
      id: $issueId,
      input: {
        assigneeId: $assigneeId,
        priority: $priority,
        projectId: $projectId,
        projectMilestoneId: $projectMilestoneId,
        stateId: $stateId,
        teamId: $teamId,
      }
    ) {
      issue {
        ${IssueFragment}
      }
    }
  }
`;

interface UpdateIssueQueryReturn {
  issueUpdate: {
    issue: FetchedIssue;
  };
}

/** Arguments for the useUpdateIssue hook. */
interface UpdateIssueArgs {
  assigneeId?: string;
  issue: Issue;
  priority?: Priority;
  projectId?: string;
  stateId?: string;
  teamId?: string;
}

/** Input for the UpdateIssue mutation. */
interface UpdateIssueInput extends Omit<UpdateIssueArgs, "issue"> {
  issueId: string;
  projectMilestoneId?: string | null;
}

/**
 * Mutation that removes attachments by ID.
 */
export function useUpdateIssue() {
  return useSWRMutation(
    UpdateIssueQuery,
    async (
      query,
      {
        arg,
      }: {
        arg: UpdateIssueArgs;
      }
    ) => {
      const { issue, ...restArgs } = arg;
      const updateIssueInput = restArgs as UpdateIssueInput;

      updateIssueInput.issueId = issue.id;

      // TEMP: When moving an issue between teams or projects, remove the milestone
      if (arg.teamId || arg.projectId) {
        updateIssueInput.projectMilestoneId = null;
      }

      const response = await fetchLinear<UpdateIssueQueryReturn>([query, updateIssueInput]);
      const responseIssue = response.issueUpdate.issue;

      const updatedIssue = flattenFetchedIssue(responseIssue);

      runInAction(() => {
        store.linkedIssues[arg.issue.id] = updatedIssue;
      });
    }
  );
}
