import * as React from "react";
import { observer } from "mobx-react-lite";
import type { State } from "shared/types";
import { useTheme } from "styled-components";
import { StateType } from "@linear/common/models/WorkflowStateType";
import { StatusBacklogIcon } from "@linear/orbiter/icons/base/StatusBacklogIcon";
import { StatusCanceledIcon } from "@linear/orbiter/icons/base/StatusCanceledIcon";
import { StatusDoneIcon } from "@linear/orbiter/icons/base/StatusDoneIcon";
import { StatusIconDynamic } from "@linear/orbiter/icons/base/StatusIconDynamic";
import { StatusTriageIcon } from "@linear/orbiter/icons/base/StatusTriageIcon";

interface WorkflowStateIconProps extends React.SVGProps<SVGSVGElement> {
  /** The workflow state to render. */
  state: State | null;
  /** If given, overrides the color of the workflow state and renders it as a normal icon. */
  monochrome?: boolean;
}

/**
 * Copied version of WorkflowStateIcon from client.
 */
export const WorkflowStateIcon = observer(function WorkflowStateIcon_(props: WorkflowStateIconProps) {
  const { state, monochrome, className, ...etc } = props;

  const iconClassName = className ? `color-override ${className}` : "color-override";
  const theme = useTheme();

  // If no state was passed, render the backlog icon as a placeholder.
  if (!state) {
    return <StatusBacklogIcon color={theme.color.labelMuted} {...etc} className={iconClassName} />;
  }

  let percentage = 0;
  if (state.type === StateType.started) {
    const teamStates = state.team.states.edges.map(({ node }) => node);

    const started = teamStates
      .filter(workflowState => workflowState.type === state.type)
      .sort((a, b) => a.position - b.position);

    if (started.length === 1) {
      percentage = 0.5;
    } else if (started.length === 2) {
      percentage = started.indexOf(state) === 0 ? 0.5 : 0.75;
    } else {
      percentage = (1.0 / (started.length + 1)) * (started.indexOf(state) + 1);
    }
  }

  const iconColor = monochrome ? theme.color.labelMuted : state.color;

  switch (state.type) {
    case "triage":
      return <StatusTriageIcon color={iconColor} {...etc} className={iconClassName} />;
    case "backlog":
      return <StatusBacklogIcon color={iconColor} {...etc} className={iconClassName} />;
    case "unstarted":
      return (
        <StatusIconDynamic
          percentage={percentage}
          color={iconColor}
          aria-label="Todo"
          {...etc}
          className={iconClassName}
        />
      );
    case "started":
      return (
        <StatusIconDynamic
          percentage={percentage}
          color={iconColor}
          aria-label="In Progress"
          {...etc}
          className={iconClassName}
        />
      );
    case "completed":
      return <StatusDoneIcon color={iconColor} {...etc} className={iconClassName} />;
    case "canceled":
      return <StatusCanceledIcon color={iconColor} {...etc} className={iconClassName} />;
    default:
      return null;
  }
});
