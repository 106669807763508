/**
 * Find all elements in arr1 that are not in arr2 by some comparator.
 *
 * @param arr1 The array to find excluded elements in.
 * @param arr2 The array to compare against.
 * @param comparator Empty for comparing direct values, string to compare
 * objects by a property, or a function to dynamically compare values in each
 * array.
 *
 * @returns Elements in arr1 that are not in arr2.
 */
export function difference<T, U>(arr1: T[], arr2: U[], comparator?: string | ((x: T, y: U) => boolean)): T[] {
  let compare: (x: T, y: U) => boolean;

  if (comparator === undefined) {
    compare = (x, y) => (x as unknown) === y;
  } else if (typeof comparator === "string") {
    compare = (x: T, y: U) => (x[comparator as keyof T] as unknown) === y[comparator as keyof U];
  } else {
    compare = comparator;
  }

  return arr1.filter(x => !arr2.some(y => compare(x, y)));
}

/**
 * Find all elements in arr1 that are also in arr2 by some comparator.
 *
 * @param arr1 The array to find excluded elements in.
 * @param arr2 The array to compare against.
 * @param comparator Empty for comparing direct values, string to compare
 * objects by a property, or a function to dynamically compare values in each
 * array.
 *
 * @returns Elements in arr1 that are not in arr2.
 */
export function intersection<T, U>(arr1: T[], arr2: U[], comparator?: string | ((x: T, y: U) => boolean)): T[] {
  let compare: (x: T, y: U) => boolean;

  if (comparator === undefined) {
    compare = (x, y) => (x as unknown) === y;
  } else if (typeof comparator === "string") {
    compare = (x: T, y: U) => (x[comparator as keyof T] as unknown) === y[comparator as keyof U];
  } else {
    compare = comparator;
  }

  return arr1.filter(x => arr2.some(y => compare(x, y)));
}
