import { StateType, StateTypeHelper } from "../../WorkflowStateType";

/**
 * Shape for a WorkflowState that can be used for sorting by type & position.
 */
interface StateSort {
  position: number;
  type: StateType;
}

/** Information about workflow states. */
export class WorkflowStateHelper {
  /** The maximum allowed length for an workflow state name. */
  public static maxNameLength = 30;

  /** The maximum allowed length for an workflow state description. */
  public static maxDescriptionLength = 255;

  /**
   * Returns a number that can be used to sort / order state types
   *
   * @param stateType The StateType you want the order of
   */
  public static getStateTypeOrder(type: StateType): number {
    return StateTypeHelper.statusOrdering[type];
  }

  /**
   * Determines whether one workflow state should be ordered before the other.
   *
   * @param stateA The first state to compare.
   * @param stateB The second state to compare.
   * @returns True if stateA should be ordered before stateB, false otherwise.
   */
  public static isStateBefore(stateA: StateSort, stateB: StateSort): boolean {
    const stateAOrder = StateTypeHelper.statusOrdering[stateA.type];
    const stateBOrder = StateTypeHelper.statusOrdering[stateB.type];

    return stateAOrder < stateBOrder || (stateAOrder === stateBOrder && stateA.position < stateB.position);
  }

  /**
   * Finds the position order of a workflow state for sorting. Closed states
   * are sorted last.
   *
   * @param state The state to find the position order of.
   * @returns The position order of the state.
   */
  public static getPositionOrder(state: StateSort): number {
    return StateTypeHelper.closedStateTypes.includes(state.type) ? -state.position : state.position;
  }

  /** The default state for a duplicate issue. */
  public static readonly defaultDuplicateState: { type: StateType; name: string; color: string } = {
    type: StateType.canceled,
    name: "Duplicate",
    color: "#95a2b3",
  };

  /** The default state for an in review issue. */
  public static readonly reviewState: { type: StateType; name: string; color: string; position: number } = {
    type: StateType.started,
    name: "In Review",
    color: "#0f783c",
    // After In Progress
    position: 2.5,
  };

  /** The default states, in order as they should be presented for the team. */
  public static readonly defaultStates: { type: StateType; name: string; color: string }[] = [
    {
      type: StateType.backlog,
      name: "Backlog",
      color: "#bec2c8",
    },
    {
      type: StateType.unstarted,
      name: "Todo",
      color: "#e2e2e2",
    },
    {
      type: StateType.started,
      name: "In Progress",
      color: "#f2c94c",
    },
    {
      type: StateType.completed,
      name: "Done",
      color: "#5e6ad2",
    },
    {
      type: StateType.canceled,
      name: "Canceled",
      color: "#95a2b3",
    },
    this.defaultDuplicateState,
  ];

  /** The default triage state. */
  public static readonly defaultTriageState = {
    type: StateType.triage,
    name: "Triage",
    color: "#FC7840",
    position: 0,
  };
}
