import { createGlobalStyle } from "styled-components";
import { gridSpacePx } from "~/styles/gridSpace";

const generatedGridMultiples = [0, 0.5, 1, 2, 3, 4, 5, 6];

/**
 * Styles injected into the UI to override Figma defaults and provide defaults to the application.
 */
export const FigmaStyles = createGlobalStyle`
  :root {
    --font-size-micro: 0.75rem;
    --font-size-microPlus: 0.75rem;
    --font-size-mini: 0.8125rem;
    --font-size-miniPlus: 0.8125rem;
    --font-size-small: 0.875rem;
    --font-size-smallPlus: 0.875rem;
    --font-size-regular: 1rem;
    --font-size-regularPlus: 1rem;
    --font-size-large: 1.18rem;

    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 600;

    /* Custom CSS variables */
    --color-bg-shadow: rgba(0, 0, 0, 0.06);
    --color-accent: var(--figma-color-bg-brand);

    /** Dark theme classic, control base */
    --color-accent: #717CE1;
    --color-accent-hover: #5E6AD2;

    /** Editor specific styles **/
    --panel-x-padding: ${gridSpacePx(4)};

    &[data-editor-type="figma"] {
      --figma-color-border-selected: #D1E0EB;
      --panel-x-padding: ${gridSpacePx(3)};
    }

    &[data-editor-type="dev"] {
      --figma-color-bg-selected-hover: #DAECDF;
      --figma-color-bg-selected: var(--figma-color-bg-selected-tertiary);
      --figma-color-border-selected: #DDE4DE;
      --panel-x-padding: ${gridSpacePx(4)};
    }

    &[data-theme="dark"] {
      &[data-editor-type="figma"] {
        --figma-color-bg-selected-hover: #667799;
        --figma-color-border-selected: #5E6C8C;
      }

      &[data-editor-type="dev"] {
        --figma-color-bg-selected-hover: #517361;
        --figma-color-border-selected: #5B7A6A;
      }
    }
  }

  html {
    color-scheme: light dark;
  }

  html, body {
    color: var(--figma-color-text);
    background: var(--figma-color-bg);
    font-size: 11px;
    height: 100%;
    user-select: none;
  }

  #root {
    display: flex;
    height: 100%;
  }

  summary {
    border: 1px solid transparent;
    outline: 1px solid transparent;
  }

  button:not(:disabled):focus-visible {
    border-radius: 3px;
    border: none;
    outline: 2px solid var(--color-accent);
  }

  a:not(:disabled):focus-visible {
    text-decoration: underline;
    outline: none;
  }

  /*
   * Override for Radix popper content widths to allow for padding on both
   * horizontal edges.
   */
  [data-radix-popper-content-wrapper] {
    max-width: calc(100% - var(--panel-x-padding) * 2);
    min-width: auto !important;
  }

  /*
   * Prevent the popper content from flashing focus from force rendering for
   * Downshift.
   */
  [data-radix-popper-content-wrapper] > *:focus-visible {
    outline: none;
  }

  /**
   * Utility styles
   */
  .flex-shrink-0 {
    flex-shrink: 0;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .h-full {
    height: 100%;
  }

  .relative {
    position: relative;
  }

  .text-center {
    text-align: center;
  }

  .w-full {
    width: 100%;
  }

  .w-1\/2 {
    width: 50%;
  }

  ${generatedGridMultiples.map(spacing => {
    return `
      .p-${spacing} {
        padding: ${gridSpacePx(spacing)};
      }
      .px-${spacing} {
        padding-left: ${gridSpacePx(spacing)};
        padding-right: ${gridSpacePx(spacing)};
      }
      .py-${spacing} {
        padding-top: ${gridSpacePx(spacing)};
        padding-bottom: ${gridSpacePx(spacing)};
      }
      .pt-${spacing} {
        padding-top: ${gridSpacePx(spacing)};
      }
      .pb-${spacing} {
        padding-bottom: ${gridSpacePx(spacing)};
      }
      .pl-${spacing} {
        padding-left: ${gridSpacePx(spacing)};
      }
      .pr-${spacing} {
        padding-right: ${gridSpacePx(spacing)};
      }

      .m-${spacing} {
        margin: ${gridSpacePx(spacing)};
      }
      .mx-${spacing} {
        margin-left: ${gridSpacePx(spacing)};
        margin-right: ${gridSpacePx(spacing)};
      }
      .my-${spacing} {
        margin-top: ${gridSpacePx(spacing)};
        margin-bottom: ${gridSpacePx(spacing)};
      }
      .mt-${spacing} {
        margin-top: ${gridSpacePx(spacing)};
      }
      .mb-${spacing} {
        margin-bottom: ${gridSpacePx(spacing)};
      }
      .ml-${spacing} {
        margin-left: ${gridSpacePx(spacing)};
      }
      .mr-${spacing} {
        margin-right: ${gridSpacePx(spacing)};
      }
    `;
  })}
`;
