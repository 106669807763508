import type { SelectedNode } from "../types";
import { type BaseMessage, Message } from "./Message";

export enum FigmaSelectionType {
  PAGE = "PAGE",
  NODES = "NODES",
}

interface SelectedMessage extends BaseMessage {
  data: {
    nodes: SelectedNode[];
    selectionType: FigmaSelectionType;
  };
}

/** Used for a selection message when  no selection on the canvas has been made. */
export class PageSelectedMessage implements SelectedMessage {
  public readonly message = Message.SELECTED_PAGE;
  public readonly data: SelectedMessage["data"];

  public constructor() {
    this.data = {
      nodes: [],
      selectionType: FigmaSelectionType.PAGE,
    };
  }
}

/** Used for a selection message when nodes have been directly selected on the canvas. */
export class NodeSelectedMessage implements SelectedMessage {
  public readonly message = Message.SELECTED_NODES;
  public readonly data: SelectedMessage["data"];

  public constructor(data: { nodes: SceneNode[] }) {
    const nodes = data.nodes.map(node => {
      return {
        id: node.id,
        name: node.name,
        type: node.type,
      };
    });

    this.data = {
      nodes,
      selectionType: FigmaSelectionType.NODES,
    };
  }
}
