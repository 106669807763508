import * as React from "react";
import styled from "styled-components";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

function LoadingIconUnstyled(props: IconProps) {
  return (
    <Icon {...props}>
      <svg>
        <path d="M11.6678 12.7487C10.6537 13.5332 9.38135 14 8 14C4.68629 14 2 11.3137 2 8C2 6.61865 2.4668 5.34632 3.25127 4.33216L1.82842 2.90931C0.686208 4.29247 0 6.0661 0 8C0 12.4183 3.58172 16 8 16C9.9339 16 11.7075 15.3138 13.0907 14.1716L11.6678 12.7487Z" />
        <path d="M5.32045 2.6301C6.12695 2.22688 7.03698 2 8 2C11.3137 2 14 4.68629 14 8C14 8.96302 13.7731 9.87305 13.3699 10.6796L14.8406 12.1503C15.5763 10.9403 16 9.51961 16 8C16 3.58172 12.4183 0 8 0C6.48039 0 5.05974 0.423692 3.84973 1.15939L5.32045 2.6301Z" />
      </svg>
    </Icon>
  );
}

export const LoadingIcon = styled(LoadingIconUnstyled)`
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
