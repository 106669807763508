import React, { useEffect, useMemo, useRef, useState } from "react";
import type { Project } from "shared/types";
import { Combobox } from "~/components/Combobox";
import { debounce } from "~/utils/debounce";
import { useProjectsInTeam } from "~/queries/useProjectsInTeam";

const noProject = {
  color: null,
  id: null,
  name: "No project",
} as unknown as Project;

interface ProjectInputControlProps extends React.PropsWithChildren {
  /** Callback when a project is selected. Null when no project is selected. */
  onSelectProject: (selectedProject: Project | null) => void;
  /** The currently selected project. */
  selectedProject: Project | null;
  /** ID of the team to search for projects in. */
  teamId?: string;
}

/**
 * Button and context menu for selecting a project in a team.
 */
export function ProjectInputControl(props: ProjectInputControlProps) {
  const { children, onSelectProject, selectedProject, teamId } = props;

  const [projectQuery, setProjectQuery] = useState<string>("");
  const [projects, setProjects] = useState<Project[]>([noProject]);

  const projectSearch = useProjectsInTeam(teamId || null, projectQuery);
  const previousTeamId = useRef<string | undefined>(teamId);

  const debouncedProjectSearch = useMemo(() => {
    return debounce((query: string) => {
      setProjectQuery(query);
    }, 250);
  }, []);

  // Maintains a list of currently displayed projects while loading projects
  // direct from search. This prevents a flash of unmounting and rendering a
  // new list of projects between searches.
  useEffect(() => {
    if (projectQuery && projectSearch.data) {
      const newProjects: Project[] = [];

      if ("no project".startsWith(projectQuery.toLowerCase())) {
        newProjects.push(noProject);
      }

      setProjects([...newProjects, ...projectSearch.data]);
    } else if (!projectQuery) {
      const newProjects: Project[] = [noProject];

      if (projectSearch.data) {
        newProjects.push(...projectSearch.data);
      }

      setProjects(newProjects);
    }
  }, [projectSearch.data]);

  // When the team changes and the selected project isn't in the team, select
  // the empty project and reset state
  useEffect(() => {
    if (previousTeamId.current !== teamId && projectSearch.data) {
      previousTeamId.current = teamId;

      if (!projectSearch.data.some(project => project.id === selectedProject?.id)) {
        onSelectProject(null);
      }
    }
  }, [projectSearch.data, previousTeamId, selectedProject]);

  return (
    <Combobox
      isLoading={projectSearch.isLoading}
      items={projects.map(project => ({
        key: project.id,
        label: project.name,
        value: project,
      }))}
      emptyMessage="No projects found"
      placeholder="Add to project…"
      onSelect={item => {
        onSelectProject(item.value);
      }}
      onSearch={debouncedProjectSearch}
      selectedItem={
        selectedProject
          ? {
              key: selectedProject.id,
              label: selectedProject.name,
              value: selectedProject,
            }
          : {
              key: noProject.id,
              label: noProject.name,
              value: noProject,
            }
      }
    >
      {children}
    </Combobox>
  );
}
