import React, { useRef, useState } from "react";
import { useSelect } from "downshift";
import * as Popover from "@radix-ui/react-popover";
import { gridSpace } from "~/styles/gridSpace";
import { panelXPadding } from "~/styles/panelXPadding";
import { PopoverMenu } from "./Popover/PopoverMenu";
import { PopoverMenuItem, type PopoverMenuItemShape } from "./Popover/PopoverMenuItem";
import { PopoverMenuList } from "./Popover/PopoverMenuList";

interface SelectProps<T> {
  /** Any styling for the wrapper element. */
  className?: string;
  /** The trigger element for the select popover. */
  children?: React.ReactNode;
  /** Styling and anchoring props for the popover content. */
  contentProps?: Popover.PopperContentProps;
  /** Options for a select menu. */
  items: PopoverMenuItemShape<T>[];
  /** Callback when an option has been selected. */
  onSelect: (item: PopoverMenuItemShape<T>) => void;
}

/** A select menu that renders in a popover. */
export function Select<T>(props: SelectProps<T>) {
  const { children, contentProps, items, onSelect } = props;

  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const { selectItem, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    isOpen,
    items,
    itemToString(item) {
      return item?.label ?? "";
    },
    onSelectedItemChange(change) {
      if (!change.selectedItem) {
        return;
      }

      setIsOpen(false);

      selectItem(null);
      onSelect(change.selectedItem);
    },
  });

  const SelectListItems = items.map((item, index) => {
    return (
      <PopoverMenuItem
        {...item}
        key={item.label}
        isHighlighted={index === highlightedIndex}
        itemProps={getItemProps({
          item,
          index,
        })}
      />
    );
  });

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger
        asChild
        {...getToggleButtonProps({
          tabIndex: undefined,
          onBlur(e) {
            // Prevent Downshift from closing the popover when Radix focuses it
            // after the popover is opened.
            if (contentRef.current?.contains(e.relatedTarget)) {
              e.target.focus();
            } else {
              setIsOpen(false);
            }
          },
        })}
      >
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          asChild
          align="start"
          hideWhenDetached
          side="bottom"
          sideOffset={gridSpace(1)}
          collisionPadding={panelXPadding()}
          ref={contentRef}
          {...contentProps}
        >
          {/*
           * Wrapper around motion prevents Radix from assuming the popover
           * is off screen and closed.
           */}
          <div style={{ zIndex: 2 }}>
            <PopoverMenu
              isOpen={isOpen}
              side={contentProps?.side}
              // Ref errors are suppressed to avoid mounting the select when it's closed
              {...getMenuProps(undefined, { suppressRefError: true })}
            >
              <PopoverMenuList isOpen={isOpen}>{SelectListItems}</PopoverMenuList>
            </PopoverMenu>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
