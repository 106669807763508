import useSWR, { type SWRResponse } from "swr";
import type { Issue, IssueSearch } from "shared/types";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import type { Connection, FetchedIssue } from "~/types";
import { flattenFetchedIssue } from "~/utils/flattenFetchedIssue";
import { IssueFragment } from "./fragments/IssueFragment";

const SearchIssuesQuery = `
  query SearchIssuesQuery($query: String!, $includeComments: Boolean!) {
    searchIssues(term: $query, includeComments: $includeComments, first: 25) {
      edges {
        node {
          ${IssueFragment}
          metadata
        }
      }
    }
  }
`;

interface SearchIssuesQueryReturn {
  searchIssues: Connection<IssueSearch & FetchedIssue>;
}

/** Returns incomplete issues assigned to the current user. */
export function useSearchIssues(query: string, includeComments = true): SWRResponse<Issue[]> {
  return useSWR(query ? [SearchIssuesQuery, { query, includeComments }] : null, async args => {
    const response = await fetchLinear<SearchIssuesQueryReturn>(args);
    const issues = flattenConnection(response.searchIssues).map(flattenFetchedIssue) as (IssueSearch & Issue)[];

    return issues.sort((a, b) => {
      return b.metadata.score - a.metadata.score;
    }) as Issue[];
  });
}
