/* eslint-disable node/no-process-env */

declare const window: { CLIENT_ENV?: { CLIENT_URL?: string } } | undefined;

export const Config = {
  /** Same config is hardcoded in common/src/security/Headers! */
  DESKTOP_PORTS: [44450, 18450, 33234],

  /** The id of the Linear Onboarding team that contains the default issues to copy over to a new team. */
  DEFAULT_ISSUES_TEAM_ID: "fb64079d-35f3-4506-a1de-55d4b86b3e55",

  /** Whether the client is compiled against production. */
  PRODUCTION_ENV: process.env.NODE_ENV === "production",

  /** The current URL that for the application */
  CLIENT_URL:
    typeof window !== "undefined" ? window.CLIENT_ENV && window.CLIENT_ENV.CLIENT_URL : process.env.VITE_CLIENT_URL,
} as const;
