import * as React from "react";

export function BackArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" aria-hidden {...props}>
      <path
        d="M5.75736 1.00736L1.5 5.25M1.5 5.25L5.75736 9.49264M1.5 5.25H14"
        stroke="currentColor"
        strokeOpacity="0.8"
      />
    </svg>
  );
}
