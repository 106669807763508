import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import type { Issue } from "shared/types";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { KeyboardHelper } from "@linear/orbiter/utils/KeyboardHelper";
import { CreateIssueForm } from "~/components/CreateIssueForm";
import { LinkIssueForm } from "~/components/LinkIssueForm";
import { AssignedIssuesList } from "~/components/AssignedIssuesList";
import { IssueSearchResultsList } from "~/components/IssueSearchResultsList";
import { debounce } from "~/utils/debounce";
import { gridSpacePx } from "~/styles/gridSpace";
import { Scrollable } from "~/components/Scrollable";
import { AddIssueHeader } from "./AddIssueHeader";
import { SearchInput } from "./SearchInput";
import { FormButton } from "./FormButton";

const listAnimationVariants = {
  initial: {
    x: "-35%",
  },
  animate: {
    x: 0,
  },
  exit: {
    x: "-35%",
  },
};

interface AddIssueProps {
  onClose: () => void;
  onIssueLinked: (linkedIssue: Issue) => void;
}

/**
 * The screen for linking Figma nodes to new or existing issues.
 */
export const AddIssue = observer(function AddIssue_(props: AddIssueProps) {
  const { onClose, onIssueLinked } = props;
  const [issueToLink, setIssueToLink] = useState<Issue | null>(null);
  const [isCreatingIssue, setIsCreatingIssue] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSearch = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    }, 250),
    []
  );

  // When the user presses Escape, go back one step in the add issue flow. If
  // the user is at the root of the flow, exit it.
  //
  // Note: Uses a custom event handler instead of the useKeyPressEvent hook due
  // to the multiple listeners it attaches from AnimatePresence
  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (KeyboardHelper.isEscape(e)) {
        if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
          e.target.blur();
          return;
        }

        if (isCreatingIssue) {
          setIsCreatingIssue(false);
        } else if (issueToLink) {
          setIssueToLink(null);
        } else {
          onClose();
        }
      }
    };

    self.document.body.addEventListener("keydown", onEscape);

    return () => {
      self.document.body.removeEventListener("keydown", onEscape);
    };
  }, [isCreatingIssue, issueToLink, onClose]);

  /**
   * If the user is linking an issue, show the LinkIssueForm.
   * If the user is creating an issue, show the CreateIssueForm.
   * If the user is searching for an issue, show the IssueSearchResultsList.
   * Otherwise, show the AssignedIssuesList.
   */
  return (
    <Scrollable
      grow={1}
      style={{
        // Prevent horizontal scrolling
        overflowX: "hidden",
      }}
    >
      <MotionConfig
        transition={{
          duration: 0.175,
        }}
      >
        <AnimatePresence initial={false} mode="popLayout">
          {issueToLink ? (
            <NestedMotionPane key="linkIssueForm">
              <LinkIssueForm
                issue={issueToLink}
                onCancelLinkingIssue={() => {
                  setIssueToLink(null);
                }}
                onIssueLinked={onIssueLinked}
              />
            </NestedMotionPane>
          ) : isCreatingIssue ? (
            <NestedMotionPane key="createIssueForm">
              <CreateIssueForm
                onCancelLinkingIssue={() => {
                  setIsCreatingIssue(false);
                }}
                onIssueLinked={onIssueLinked}
              />
            </NestedMotionPane>
          ) : (
            <motion.div
              animate={"animate"}
              className="w-full"
              exit={"exit"}
              initial={"initial"}
              key="issuesToLinkList"
              variants={listAnimationVariants}
              transition={{
                duration: 0.15,
              }}
            >
              <Scrollable className="h-full w-full" column>
                <AddIssueHeader isCreatingIssue onIconClick={onClose} />
                <CreateIssueButton
                  onClick={() => {
                    setIsCreatingIssue(true);
                  }}
                >
                  Create new issue
                </CreateIssueButton>
                <AddIssueSearchInput
                  autoFocus
                  defaultValue={searchQuery}
                  onChange={debouncedSearch}
                  placeholder="Search…"
                />
                {searchQuery ? (
                  <IssueSearchResultsList
                    query={searchQuery}
                    onIssueLinked={onIssueLinked}
                    onIssueSelect={setIssueToLink}
                  />
                ) : (
                  <AssignedIssuesList
                    onIssueLinked={onIssueLinked}
                    onIssueSelect={issue => {
                      setIssueToLink(issue);
                    }}
                  />
                )}
              </Scrollable>
            </motion.div>
          )}
        </AnimatePresence>
      </MotionConfig>
    </Scrollable>
  );
});

const NestedMotionPane = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
  z-index: 2;
`;

NestedMotionPane.defaultProps = {
  animate: { x: 0 },
  exit: { x: "100%" },
  initial: { x: "100%" },
};

const AddIssueSearchInput = styled(SearchInput)`
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--figma-color-border);
  padding-left: var(--panel-x-padding);
  padding-right: var(--panel-x-padding);
`;

const CreateIssueButton = styled(FormButton)`
  margin: 0 var(--panel-x-padding) ${gridSpacePx(2)};
`;
