import type { PluginMessage } from "shared/messages";
import { manifest } from "shared/manifest";

/**
 * Posts a message to the Figma plugin.
 */
export function postPluginMessage<T extends PluginMessage>(message: T) {
  window.parent.postMessage(
    {
      pluginMessage: message,
      pluginId: manifest.id,
    },
    "https://www.figma.com"
  );
}
