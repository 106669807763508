import React, { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import type { IssueLink } from "shared/IssueLink";
import type { Issue } from "shared/types";
import { difference, intersection } from "shared/utils/array";
import { FigmaSelectionType } from "shared/messages";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { ImageIcon } from "@linear/orbiter/icons/figma/ImageIcon";
import { MenuIcon } from "@linear/orbiter/icons/base/MenuIcon";
import { useMeasure } from "@linear/orbiter/hooks/react-use";
import { IconButton } from "~/components/IconButton";
import { IssueIdentifier } from "~/components/IssueIdentifier";
import { IssueTitle } from "~/components/IssueTitle";
import { NodeList, NodeListFlex } from "~/components/NodeList";
import { WorkflowStateIcon } from "~/components/WorkflowStateIcon";
import {
  LinkedIssueListItemDescription,
  LinkedIssueListItemCondensedDescription,
} from "~/components/LinkedIssueListItemDescription";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { NodeSummary } from "~/components/NodeSummary";
import { UserAvatar } from "~/components/UserAvatar";
import { useSelectNodes } from "~/hooks/useSelectNodes";
import { useLinkIssue } from "~/queries/useLinkIssue";
import { FigmaSelection, uiFigma } from "~/store/UIFigma";
import { panelXPadding } from "~/styles/panelXPadding";
import { useRemoveIssueLinks } from "~/queries/useRemoveIssueAttachments";
import { useUpdateIssue } from "~/queries/useUpdateIssue";
import { openExternalLink } from "~/utils/links";
import { Select } from "./Select";
import type { PopoverMenuItemShape } from "./Popover/PopoverMenuItem";
import { UserInputControl } from "./IssueInputControls/UserInputControl";
import { StatusInputControl } from "./IssueInputControls/StatusInputControl";
import { LinkedIssueInputControls } from "./LinkedIssueInputControls";

interface LinkedIssueListItemProps {
  /** Whether the linked issue was selected in the plugin UI. */
  isActivelySelected: boolean;
  /** Flashes the linked issue if it was selected from being recently linked. */
  isHighlighted?: boolean;
  /** Whether the linked issue is selected in the Figma canvas. */
  isPassivelySelected: boolean;
  linkedIssue: Issue;
  issueLinks: IssueLink[];
  onClick: (linkedIssue: Issue) => void;
}

/**
 * Represents an issue linked to Figma nodes on the canvas or the current Figma page.
 */
export const LinkedIssueListItem = observer(function LinkedIssueListItem_(props: LinkedIssueListItemProps) {
  const { linkedIssue, issueLinks, isActivelySelected, isHighlighted, isPassivelySelected, onClick } = props;

  const linkedNodes = issueLinks.map(issueLink => issueLink.node);
  const linkIssue = useLinkIssue();

  const relativeRef = useRef<HTMLDivElement>(null);
  const [elementRef, { height }] = useMeasure<HTMLDivElement>();
  const shouldReduceMotion = useReducedMotion();

  const updateIssue = useUpdateIssue();

  const selectNodes = useSelectNodes({
    // When nodes have been selected, call the clicked callback
    onSelect() {
      onClick(linkedIssue);
    },
  });

  // Remove markdown image links from the description for the condensed display
  let numberOfImagesReplaced = 0;
  const descriptionWithoutImages =
    linkedIssue.description
      ?.replace(/!\[\S*\]\(\S*\)/g, () => {
        numberOfImagesReplaced++;
        return "";
      })
      .trim() ?? "";

  const linkedIssueMenuOptions: PopoverMenuItemShape<() => Promise<void>>[] = [
    {
      key: "view-issue",
      label: "Open issue in Linear",
      async value() {
        openExternalLink(linkedIssue.url);
      },
    },
  ];

  const isLinkedToSelection = uiFigma.selection.includes(linkedNodes);
  const removeIssueLink = useRemoveIssueLinks();

  // A destructive option to unlink the current Figma selection from an issue
  if (isLinkedToSelection) {
    linkedIssueMenuOptions.push({
      destructive: true,
      key: "unlink-selection",
      label:
        uiFigma.selection.type === FigmaSelectionType.PAGE ? "Remove link to page" : "Remove link to current selection",
      async value() {
        // If nothing is selected, get the issue linked to the current page.
        // Otherwise, find all issue links with a node in the current
        // selection.
        const selectedIssueLinks = uiFigma.selection.isPage
          ? issueLinks.filter(issueLink => issueLink.node.id === uiFigma.currentPage.id)
          : intersection(issueLinks, uiFigma.selection.nodes, (issueLink, node) => issueLink.node.id === node.id);

        await removeIssueLink.trigger(selectedIssueLinks);
      },
    });
  }

  const isLinkedToPage = issueLinks.some(issueLink => issueLink.node.type === "PAGE");

  // When no nodes are selected, show an option to link the issue to the
  // current page
  if (uiFigma.selection.isPage && !isLinkedToPage) {
    linkedIssueMenuOptions.push({
      key: "link-to-page",
      label: "Link to page",
      async value() {
        await linkIssue.trigger({
          file: uiFigma.file,
          fileKey: uiFigma.fileKey,
          issue: linkedIssue,
          page: uiFigma.currentPage,
          selection: new FigmaSelection({
            nodes: [],
            selectionType: FigmaSelectionType.PAGE,
          }),
        });
      },
    });
  }
  // When there's a selection, show the option to link the issue to the current
  // selection
  else if (!isLinkedToSelection) {
    linkedIssueMenuOptions.push({
      key: "link-to-selection",
      label: "Link to current selection",
      async value() {
        await linkIssue.trigger({
          file: uiFigma.file,
          fileKey: uiFigma.fileKey,
          issue: linkedIssue,
          page: uiFigma.currentPage,
          // Create a selection for linking with nodes that aren't currently
          // linked to the issue
          selection: new FigmaSelection({
            nodes: difference(uiFigma.selection.nodes, linkedNodes, "id"),
            selectionType: FigmaSelectionType.NODES,
          }),
        });
      },
    });
  }

  useLayoutEffect(() => {
    // Scroll the list item into view when it's not in view and actively
    // selected.
    if (isActivelySelected) {
      requestAnimationFrame(() => {
        relativeRef.current?.scrollIntoView(true);
      });
    }
  }, [isActivelySelected]);

  return (
    <ListItemRelativeContainer ref={relativeRef}>
      <ListItemFlex
        onClick={e => {
          e.stopPropagation();
          if (!isActivelySelected) {
            selectNodes(linkedNodes.map(({ id }) => id));
          }
        }}
        isActivelySelected={isActivelySelected}
        isHighlighted={isHighlighted}
        isPassivelySelected={isPassivelySelected}
        data-isactivelyselected={isActivelySelected}
        data-ishighlighted={isHighlighted}
        animate={{
          height: !shouldReduceMotion ? height || "auto" : undefined,
        }}
        transition={{
          duration: 0.125,
        }}
        key={linkedIssue.id}
      >
        <div ref={elementRef}>
          <ListItemPadding>
            <Flex
              align="center"
              gap={gridSpace(1)}
              style={{
                marginBottom: gridSpacePx(0.5),
              }}
            >
              <StatusInputControl
                teamId={linkedIssue.team.id}
                selectedStatus={linkedIssue.state}
                onSelectStatus={async selectedStatus => {
                  if (!selectedStatus || selectedStatus === linkedIssue.state) {
                    return;
                  }

                  await updateIssue.trigger({
                    issue: linkedIssue,
                    stateId: selectedStatus.id,
                  });
                }}
              >
                <LinkedIssueListItemIconButton
                  isActivelySelected={isActivelySelected}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  fill={linkedIssue.state.color}
                  inset="left"
                  style={{
                    // Inset the icon an additional pixel so the roundness of
                    // the workflow state icon left-aligns with linked node
                    // icons.
                    marginLeft: gridSpacePx(-1.5),
                  }}
                  aria-label={linkedIssue.state.name}
                >
                  <WorkflowStateIcon state={linkedIssue.state} />
                </LinkedIssueListItemIconButton>
              </StatusInputControl>
              <IssueIdentifier url={linkedIssue.url}>{linkedIssue.identifier}</IssueIdentifier>
              <IssueTitle>{linkedIssue.title}</IssueTitle>
              <Select
                contentProps={{
                  // Match the right inset padding on the item icon button
                  collisionPadding: panelXPadding() - gridSpace(2),
                }}
                items={linkedIssueMenuOptions}
                onSelect={async item => {
                  await item.value();
                }}
              >
                <LinkedIssueListItemIconButton
                  inset="right"
                  isActivelySelected={isActivelySelected}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  aria-label="Open linked issue menu"
                >
                  <MenuIcon />
                </LinkedIssueListItemIconButton>
              </Select>
            </Flex>
            <Flex className="relative" gap={gridSpace(1)} noMinWidth>
              <AnimatePresence initial={false} mode="popLayout">
                {!isActivelySelected ? (
                  <LinkedIssueContent
                    key="summarized-linked-issue"
                    style={{
                      alignItems: "center",
                      gap: gridSpacePx(2),
                      justifyContent: "space-between",
                    }}
                  >
                    <Flex align="center" gap={gridSpace(1)} noMinWidth>
                      <ClickableNodeSummary
                        onClick={e => {
                          e.stopPropagation();
                          selectNodes(issueLinks.map(issueLink => issueLink.node.id));
                        }}
                      >
                        <NodeSummary nodes={issueLinks.map(issueLink => issueLink.node)} />
                      </ClickableNodeSummary>
                      <Text type="micro" color="labelMuted">
                        •
                      </Text>
                      {descriptionWithoutImages ? (
                        <LinkedIssueListItemCondensedDescription className="mr-1">
                          {descriptionWithoutImages}
                        </LinkedIssueListItemCondensedDescription>
                      ) : (
                        <Text color="labelMuted">No description</Text>
                      )}
                    </Flex>
                    <Flex align="center" gap={gridSpace(1)}>
                      {numberOfImagesReplaced > 0 && (
                        <Flex align="center" gap={gridSpace(0.5)}>
                          <StyledImageIcon />
                          <Text type="regular" color="labelMuted">
                            {numberOfImagesReplaced}
                          </Text>
                        </Flex>
                      )}
                      <UserInputControl
                        selectedTeam={linkedIssue.team}
                        selectedUser={linkedIssue.assignee}
                        onSelectUser={async newAssignee => {
                          if (newAssignee?.id === linkedIssue.assignee?.id) {
                            return;
                          }

                          await updateIssue.trigger({
                            issue: linkedIssue,
                            assigneeId: newAssignee?.id,
                          });
                        }}
                      >
                        <LinkedIssueListItemIconButton
                          inset="right"
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          aria-label="Open assignee menu"
                        >
                          <UserAvatar
                            modelId={linkedIssue.assignee?.id}
                            name={linkedIssue.assignee?.name}
                            size="tiny"
                            src={linkedIssue.assignee?.avatarUrl}
                          />
                        </LinkedIssueListItemIconButton>
                      </UserInputControl>
                    </Flex>
                  </LinkedIssueContent>
                ) : (
                  <LinkedIssueContent
                    key="expanded-linked-issue"
                    style={{
                      flexDirection: "column",
                      gap: gridSpacePx(4),
                      paddingTop: gridSpacePx(1),
                    }}
                  >
                    {linkedIssue.description ? (
                      <LinkedIssueListItemDescription multiline={5}>
                        {linkedIssue.description || "No description."}
                      </LinkedIssueListItemDescription>
                    ) : (
                      <Text color="labelMuted">No description.</Text>
                    )}
                    <NodeListFlex>
                      <NodeList selectableNodes nodes={linkedNodes} removableIssueLinks={issueLinks} />
                    </NodeListFlex>
                    <LinkedIssueInputControls linkedIssue={linkedIssue} />
                  </LinkedIssueContent>
                )}
              </AnimatePresence>
            </Flex>
          </ListItemPadding>
        </div>
      </ListItemFlex>
    </ListItemRelativeContainer>
  );
});

/**
 * A relative wrapper around the list item to provide positioning for the
 * options menu. This allows it to render outside of the height animation
 * without being cut-off by overflow: hidden.
 */
const ListItemRelativeContainer = styled.div`
  position: relative;
`;

const ListItemFlex = styled(motion.div)<{
  isActivelySelected: boolean;
  isHighlighted?: boolean;
  isPassivelySelected: boolean;
}>`
  @keyframes highlight-selected {
    0% {
      background-color: var(--figma-color-bg);
      border-bottom-color: var(--figma-color-border);
    }
    100% {
      background-color: var(--figma-color-bg-selected);
      border-bottom-color: var(--figma-color-border-selected);
    }
  }

  @keyframes previous-highlight-selected {
    0% {
      border-bottom-color: var(--figma-color-border);
    }
    100% {
      border-bottom-color: var(--figma-color-border-selected);
    }
  }

  ${props => css`
    --figma-color-bg: ${props.isHighlighted || props.isActivelySelected
      ? "var(--figma-color-bg-selected)"
      : props.isPassivelySelected
      ? "var(--figma-color-bg-hover)"
      : undefined};
    --figma-color-bg-hover: ${props.isHighlighted || props.isActivelySelected
      ? "var(--figma-color-bg-selected-hover)"
      : undefined};
    --figma-color-border: ${props.isHighlighted || props.isActivelySelected
      ? "var(--figma-color-border-selected)"
      : undefined};
  `}

  animation: ${props => (props.isHighlighted ? "highlight-selected 0.6s forwards ease-in-out" : undefined)};
  background-color: var(--figma-color-bg);

  border-bottom: 1px solid var(--figma-color-border);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  /** Update the border on the previous list item when this one is selected. */
  &:has(+ [data-isactivelyselected="true"]) {
    border-bottom-color: var(--figma-color-border-selected);
  }
  &:has(+ [data-ishighlighted="true"]) {
    animation: previous-highlight-selected 0.6s ease-in-out;
  }

  --hover-background-color: ${props => {
    if (props.isActivelySelected) {
      return "var(--figma-color-bg-selected)";
    } else {
      return "var(--figma-color-bg-hover)";
    }
  }};

  &:hover {
    background-color: var(--hover-background-color);
    // FIXME: Doesn't prevent transition from running on mouseout
    transition: none;
  }
  &:has([data-state="open"]) {
    background-color: var(--hover-background-color);
  }

  // Remove the bottom border on the last list item
  .linked-issue-list-item-motion:last-child & {
    border-bottom-color: transparent;
  }
`;

/**
 * Padding for the list item content separate from the ref tracking height
 * through clientRect.
 */
const ListItemPadding = styled.div`
  padding: ${gridSpacePx(3)} var(--panel-x-padding);
`;

const LinkedIssueListItemIconButton = styled(IconButton)<{ isActivelySelected?: boolean }>`
  &:disabled {
    background-color: transparent;
    // NOTE: This is temporary to disable interactive styles on buttons that
    // haven't been implemented yet.
    opacity: 1;
  }

  &[data-state="open"],
  &:enabled:hover {
  background-color: ${props => {
    return props.isActivelySelected ? "var(--figma-color-bg-selected-hover)" : "var(--figma-color-border)";
  }};
`;

/**
 * Motion component for the summarized & expanded issue content.
 */
const LinkedIssueContent = styled(motion.div)`
  display: flex;
  flex-grow: 1;
  min-width: 0;
`;

LinkedIssueContent.defaultProps = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
  },
  exit: { opacity: 0 },
  transition: { duration: 0.125 },
};

const ClickableNodeSummary = styled.div`
  color: ${props => props.theme.color.labelMuted};
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom-color: var(--color-text);
  }
`;

const StyledImageIcon = styled(ImageIcon)`
  color: ${props => props.theme.color.labelMuted};
  height: 15px;
  width: 15px;
`;
