import { useEffect, useState } from "react";
import type { Team, User } from "shared/types";
import { useUsers } from "~/queries/useUsers";

// FIXME: Duplicated from UserInputControl
const unassignedUser = {
  id: null,
  name: "Unassigned",
  teams: [],
} as unknown as User;

/**
 * The assignees object to display filtered users in the current selected team
 * and those in other teams.
 */
interface Assignees {
  /** Users in the selected team. */
  inSelectedTeam: User[];
  /** Users not in the selected team. */
  inOtherTeams: User[];
}

/**
 * Given a selected team, a selected user, and a query string to filter users,
 * return a list of assignees split into the selected team and a list of those
 * that don't belong to it. This is used for the assignee dropdown in issue
 * creation and updating.
 *
 * The selected user is added as the second option if they exist in the list of
 * users. The current user is added as the next option in the appropriate team.
 *
 * @param params The parameters to use for generating lists of available
 * assignees.
 * @param params.currentUser The current user.
 * @param params.selectedTeam The selected team.
 * @param params.selectedUser The selected user.
 * @param params.userQuery The query string to filter users.
 * @returns The assignees object.
 */
export function useAssignees(params: {
  currentUser?: User;
  selectedTeam: Team | null;
  selectedUser: User | null;
  userQuery: string;
}): Assignees {
  const { currentUser, selectedTeam, selectedUser, userQuery } = params;

  const userSearch = useUsers(userQuery);
  const [assignees, setAssignees] = useState<Assignees>({
    inSelectedTeam: [],
    inOtherTeams: [],
  });

  // Partition users into those in the selected team and those in other teams.
  const [usersInSelectedTeam, usersInOtherTeams] = userSearch.data?.reduce(
    (acc, user) => {
      // Selected & current user are manually added as a second option
      if ([currentUser?.id, selectedUser?.id].includes(user.id)) {
        return acc;
      }

      const userInSelectedTeam = user.teams.some(team => team.id === selectedTeam?.id);
      if (userInSelectedTeam) {
        acc[0].push(user);
      } else {
        acc[1].push(user);
      }

      return acc;
    },
    [[], []] as [User[], User[]]
  ) ?? [[], []];

  useEffect(() => {
    // When the user query, selected team, or selected user changes, update the
    // list of assignees.
    const newAssignees: Assignees = {
      inSelectedTeam: [],
      inOtherTeams: [],
    };

    // While the user search is loading, don't update the assignees
    if (!userSearch.data) {
      return;
    }

    // Adds the current user to the appropriate
    function addCurrentUserToTeam() {
      if (!currentUser) {
        return;
      }

      const currentUserInSelectedTeam = currentUser.teams.some(team => team.id === selectedTeam?.id);

      if (currentUserInSelectedTeam) {
        newAssignees.inSelectedTeam.push(currentUser);
      } else {
        newAssignees.inOtherTeams.push(currentUser);
      }
    }

    const currentUserIsSelectedUser = currentUser?.id === selectedUser?.id;

    // When there's a filter for users, use special keywords to add unassigned
    // and current user.
    if (userQuery) {
      // Only add unassigned if it matches the text
      if ("unassigned".startsWith(userQuery.toLowerCase())) {
        newAssignees.inSelectedTeam.push(unassignedUser);
      }

      // Add the current user if the query starts with "me" or the current user
      // is in the search results
      if (
        currentUser &&
        (userSearch.data.some(user => user.id === currentUser.id) || "me".startsWith(userQuery.toLowerCase()))
      ) {
        if (currentUserIsSelectedUser) {
          newAssignees.inSelectedTeam.push(currentUser);
        } else {
          addCurrentUserToTeam();
        }
      }

      // Add the selected user if they exist in the filtered set of users.
      // Don't add the selected user if they're the current user since they've
      // already been added to the assignees list above.
      if (!currentUserIsSelectedUser && selectedUser && userSearch.data?.some(user => user.id === selectedUser?.id)) {
        newAssignees.inSelectedTeam.push(selectedUser);
      }
    } else {
      newAssignees.inSelectedTeam.push(unassignedUser);

      if (selectedUser?.id) {
        newAssignees.inSelectedTeam.push(selectedUser);
      }

      if (!currentUserIsSelectedUser) {
        addCurrentUserToTeam();
      }
    }

    newAssignees.inSelectedTeam.push(...usersInSelectedTeam);
    newAssignees.inOtherTeams.push(...usersInOtherTeams);

    setAssignees(newAssignees);
  }, [currentUser, selectedTeam, selectedUser, userQuery, userSearch.data]);

  return assignees;
}
