import styled from "styled-components";
import { TextTitle } from "~/components/TextTitle";

export const IssueTitle = styled(TextTitle)`
  flex-grow: 1;
  min-width: 0;
`;

IssueTitle.defaultProps = {
  truncate: true,
};
