import { useEffect, useState } from "react";

/**
 * Sets a delay on returning some loading state when true. This can be used to
 * show loading indicators only after a certain amount of time has passed,
 * preventing a flash of loading.
 */
export function useDelayedLoadingIndicator(isLoading: boolean, delay: number = 250): boolean {
  const [isDelayedLoading, setIsDelayedLoading] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsDelayedLoading(true);
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
      setIsDelayedLoading(false);
    };
  }, [isLoading, delay]);

  return isDelayedLoading;
}
