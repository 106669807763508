import React from "react";
import styled from "styled-components";
import type { Issue } from "shared/types";
import { observer } from "mobx-react-lite";
import { Text } from "@linear/orbiter/components/Text";
import { Flex } from "@linear/orbiter/components/Flex";
import { ProjectIcon } from "@linear/orbiter/icons/decorative/ProjectIcon";
import { useKeyPressEvent } from "@linear/orbiter/hooks/useKeyPressEvent";
import { KeyboardHelper } from "@linear/orbiter/utils/KeyboardHelper";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { useLinkIssue } from "~/queries/useLinkIssue";
import { uiFigma } from "~/store/UIFigma";
import { UserName } from "~/components/UserName";
import { AddIssueAttachments } from "./AddIssueAttachments";
import { AddIssueNestedHeader } from "./AddIssueNestedHeader";
import { CycleIconSmall, NoCycleIcon } from "./CycleIcon";
import { FormButton } from "./FormButton";
import { IssueIdentifier } from "./IssueIdentifier";
import { IssuePriorityIcon } from "./IssuePriorityIcon";
import { TextTitle } from "./TextTitle";
import { UserAvatar } from "./UserAvatar";
import { WorkflowStateIcon } from "./WorkflowStateIcon";
import { Markdown } from "./Markdown";

interface LinkIssueFormProps {
  issue: Issue;
  onCancelLinkingIssue: () => void;
  onIssueLinked: (linkedIssue: Issue) => void;
}

export const LinkIssueForm = observer(function LinkIssueForm_(props: LinkIssueFormProps) {
  const { issue, onCancelLinkingIssue, onIssueLinked } = props;
  const linkIssueRequest = useLinkIssue({
    onIssueLinked(linkedIssue) {
      onIssueLinked(linkedIssue);
    },
  });

  async function linkIssue() {
    return await linkIssueRequest.trigger({
      file: uiFigma.file,
      fileKey: uiFigma.fileKey,
      issue,
      page: uiFigma.currentPage,
      selection: uiFigma.selection,
    });
  }

  // Add Mod+Enter keyboard event to submit the form
  useKeyPressEvent(event => {
    return KeyboardHelper.isModKey(event) && event.key === "Enter";
  }, linkIssue);

  const isLoading = linkIssueRequest.isMutating || linkIssueRequest.isLinkingIssue;
  const isValidSelection = uiFigma.selection.isPage || uiFigma.selection.nodes.length > 0;

  // Show the page CTA when there aren't any compatible nodes selected
  const cta = uiFigma.selection.isPage ? "Link to page" : "Link to selection";

  return (
    <StyledFlex column grow={1}>
      <AddIssueNestedHeader onBack={onCancelLinkingIssue}>
        <IssueIdentifier url={issue.url}>{issue.identifier}</IssueIdentifier>
      </AddIssueNestedHeader>
      <IssueTextFlex column gap={gridSpace(4)}>
        <TextTitle>{issue.title}</TextTitle>
        {issue.description && <IssueToLinkDescription>{issue.description}</IssueToLinkDescription>}
      </IssueTextFlex>
      <IssueDetailsFlex wrap gap={gridSpace(4)}>
        <Flex align="center" gap={gridSpace(2)}>
          <WorkflowStateIcon state={issue.state} />
          <Text color="labelMuted">{issue.state.name}</Text>
        </Flex>
        <Flex align="center" gap={gridSpace(1.5)}>
          <IssuePriorityIcon priority={issue.priority} />
          <Text color="labelMuted">{issue.priorityLabel}</Text>
        </Flex>
        <Flex align="center" gap={gridSpace(2)}>
          <UserAvatar name={issue.assignee?.name} src={issue.assignee?.avatarUrl} size="tiny" />
          <Text color="labelMuted">{issue.assignee ? <UserName user={issue.assignee} /> : "Unassigned"}</Text>
        </Flex>
        <Flex align="center" gap={gridSpace(1.5)}>
          {issue.cycle ? (
            <>
              <CycleIconSmall />
              <Text color="labelMuted" truncate>
                {issue.cycle.name ?? `Cycle ${issue.cycle.number}`}
              </Text>
            </>
          ) : (
            <>
              <NoCycleIcon />
              <Text color="labelMuted" truncate>
                No cycle
              </Text>
            </>
          )}
        </Flex>
        {issue.project && (
          <Flex align="center" gap={gridSpace(1.5)} noMinWidth>
            <ProjectIcon />
            <Text color="labelMuted" truncate>
              {issue.project?.name}
            </Text>
          </Flex>
        )}
      </IssueDetailsFlex>
      <AddIssueAttachments />
      <FormButtonFlex>
        <FormButton className="w-full" disabled={isLoading || !isValidSelection} onClick={linkIssue}>
          {isLoading ? "Linking…" : cta}
        </FormButton>
      </FormButtonFlex>
    </StyledFlex>
  );
});

const StyledFlex = styled(Flex)`
  background-color: var(--figma-color-bg);
  width: 100%;
`;

const IssueTextFlex = styled(Flex)`
  margin: ${gridSpacePx(2)} var(--panel-x-padding);
`;

const IssueToLinkDescription = styled(Markdown)`
  max-height: ${gridSpacePx(100)};
  overflow-y: auto;
`;

const IssueDetailsFlex = styled(Flex)`
  margin: ${gridSpacePx(5)} var(--panel-x-padding);
`;

const FormButtonFlex = styled(Flex)`
  margin: ${gridSpacePx(2)} var(--panel-x-padding) ${gridSpacePx(6)};
`;
