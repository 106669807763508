import * as React from "react";
import { useKeyboardEventListeners } from "./useKeyboardEventListeners";

type KeyPredicate = (event: React.KeyboardEvent | KeyboardEvent) => boolean;
type KeyFilter = null | undefined | string | ((event: React.KeyboardEvent | KeyboardEvent) => boolean);
type Handler = (event: React.KeyboardEvent | KeyboardEvent) => void;

// Forked from react-use
const createKeyPredicate = (keyFilter: KeyFilter): KeyPredicate =>
  typeof keyFilter === "function"
    ? keyFilter
    : typeof keyFilter === "string"
    ? (event: React.KeyboardEvent | KeyboardEvent) => event.key === keyFilter
    : keyFilter
    ? () => true
    : () => false;

/**
 * Register callbacks for keyboard shortcuts.
 *
 * @param key The key or key filter to match before firing the callback
 * @param keydown A keydown handler
 * @param keyup A keyup handler
 * @param options Any options for the hook.
 */
export const useKeyPressEvent = (
  key: string | KeyFilter,
  keydown?: Handler | null | undefined,
  keyup?: Handler | null | undefined,
  options?: { runWithInputElementFocus?: boolean }
): void => {
  const listeners = useKeyboardEventListeners();
  const predicate = React.useMemo(() => createKeyPredicate(key), [key]);

  const keydownListeners = React.useMemo(
    () =>
      keydown
        ? [
            {
              callback: (event: React.KeyboardEvent | KeyboardEvent) => {
                if (isKeyboardEvent(event) && predicate(event)) {
                  keydown(event);
                }
              },
              runWithInputElementFocus: options?.runWithInputElementFocus || false,
            },
          ]
        : [],
    [keydown, options?.runWithInputElementFocus]
  );

  const keyupListeners = React.useMemo(
    () =>
      keyup
        ? [
            {
              callback: (event: React.KeyboardEvent | KeyboardEvent) => {
                if (isKeyboardEvent(event) && predicate(event)) {
                  keyup(event);
                }
              },
              runWithInputElementFocus: options?.runWithInputElementFocus || false,
            },
          ]
        : [],
    [keyup, options?.runWithInputElementFocus]
  );

  React.useEffect(() => {
    return listeners.addListeners("keydown", ...keydownListeners);
  }, [keydownListeners]);

  React.useEffect(() => {
    return listeners.addListeners("keyup", ...keyupListeners);
  }, [keyupListeners]);
};

/**
 * Register callbacks for keyboard shortcuts.
 */
export const RegisterKeyPressEvent = (props: {
  /** The key or key filter to match before firing the callback. Note: `key` is reserved for React. */
  targetKey: string | KeyFilter;
  /** A keydown handler */
  onKeyDown?: Handler | null;
  /** A keyup handler */
  onKeyUp?: Handler | null;
  /** Whether to run the handler even when an input element has focus. */
  runWithInputElementFocus?: boolean;
}) => {
  const { targetKey, onKeyDown, onKeyUp, runWithInputElementFocus } = props;
  useKeyPressEvent(targetKey, onKeyDown, onKeyUp, { runWithInputElementFocus });

  return null;
};

function isKeyboardEvent(event: React.KeyboardEvent | KeyboardEvent): event is KeyboardEvent | React.KeyboardEvent {
  return "nativeEvent" in event ? event.nativeEvent instanceof KeyboardEvent : event instanceof KeyboardEvent;
}
