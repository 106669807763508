import { useEffect, useState } from "react";

/**
 * Loads an image manually and calls a callback when it's loaded. Does not call
 * the callback if any of the imageSrcs are null.
 *
 * @param imageSrc The image source or sources to load.
 * @param onImageLoaded Callback when the image or images have loaded.
 */
export function useLoadImage(imageSrc: null | string | string[]): boolean {
  const [hasLoadedImage, setHasLoadedImage] = useState(false);

  async function loadImage(src: string) {
    const image = new Image();
    image.src = src;
    await image.decode();
  }

  useEffect(() => {
    async function loadImageSrc() {
      if (imageSrc === null) {
        return;
      }

      if (Array.isArray(imageSrc)) {
        await Promise.all(imageSrc.map(loadImage));
      } else {
        await loadImage(imageSrc);
      }

      setHasLoadedImage(true);
    }

    void loadImageSrc();
  }, [imageSrc]);

  return hasLoadedImage;
}
