import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import type { Team } from "shared/types";
import { Combobox, type ComboboxItemList } from "~/components/Combobox";
import { useTeams } from "~/queries/useTeams";
import { store } from "~/store";

interface TeamInputControlProps extends React.PropsWithChildren {
  onSelectTeam: (team: Team) => void;
  selectedTeam: Team | null;
}

interface TeamsLists {
  usersTeams: Team[];
  otherTeams: Team[];
}

/**
 * Create a partition of two lists of teams that do and don't belong to the
 * current user, filtered by a search query.
 *
 * @param args.allTeams All teams in the organization.
 * @param args.currentUserTeams Teams that the current user belongs to.
 * @param args.query The search query.
 * @param args.selectedTeam The currently selected team.
 * @returns A partition of two lists of teams that do and don't belong to the
 * current user, filtered by a search query.
 */
function getDisplayedTeams(args: {
  allTeams?: Team[];
  currentUserTeams?: Team[];
  query: string;
  selectedTeam: Team | null;
}): TeamsLists {
  const { allTeams = [], currentUserTeams = [], query, selectedTeam } = args;

  // Filter teams by the queried name or key
  let filteredTeams = allTeams;
  if (query) {
    filteredTeams = allTeams.filter(team => {
      const keywords = [team.name.toLowerCase(), team.key.toLowerCase()];
      return keywords.some(keyword => keyword.includes(query.toLowerCase()));
    });
  }

  // If a filtered team is getting added to a list of teams, make it the first
  // option in that list
  function addToTeamList(team: Team, list: Team[]): Team[] {
    if (team.id === selectedTeam?.id) {
      list.unshift(team);
    } else {
      list.push(team);
    }

    return list;
  }

  const [userTeams, otherTeams] = filteredTeams.reduce(
    (acc, team) => {
      if (currentUserTeams.some(userTeam => userTeam.id === team.id)) {
        acc[0] = addToTeamList(team, acc[0]);
      } else {
        acc[1] = addToTeamList(team, acc[1]);
      }
      return acc;
    },
    [[], []] as [Team[], Team[]]
  );

  return {
    usersTeams: userTeams,
    otherTeams,
  };
}

/**
 * Button and context menu for selecting a team in the user's organization.
 */
export const TeamInputControl = observer(function TeamInputControl_(props: TeamInputControlProps) {
  const { children, onSelectTeam, selectedTeam } = props;

  const teamsRequest = useTeams();
  const currentUserTeams = store.user?.teams;
  const [teamsQuery, setTeamsQuery] = useState("");

  // Partition teams into two lists that the current user does and doesn't
  // belong to, filtered by a search query. The first team in each list will be
  // the current selected team.
  const displayedTeams = useMemo(() => {
    return getDisplayedTeams({
      allTeams: teamsRequest.data,
      currentUserTeams,
      query: teamsQuery,
      selectedTeam,
    });
  }, [teamsRequest.data, currentUserTeams, teamsQuery, selectedTeam]);

  // If there isn't a selected team after loading teams, select the first one
  // that will be displayed.
  useEffect(() => {
    const firstTeam = displayedTeams.usersTeams[0] ?? displayedTeams.otherTeams[0];

    if (!selectedTeam && firstTeam) {
      onSelectTeam(firstTeam);
    }
  }, [displayedTeams]);

  const itemLists: ComboboxItemList<Team>[] = [
    {
      key: "current-user-teams",
      items: displayedTeams.usersTeams.map(team => ({
        key: team.id,
        label: team.name,
        value: team,
      })),
    },
  ];

  if (displayedTeams.otherTeams.length > 0) {
    itemLists.push({
      key: "other-teams",
      label: "Other teams",
      items: displayedTeams.otherTeams.map(team => ({
        key: team.id,
        label: team.name,
        value: team,
      })),
    });
  }

  return (
    <Combobox
      itemLists={itemLists}
      isLoading={teamsRequest.isLoading}
      emptyMessage="No teams found"
      placeholder="Select a team…"
      onSelect={item => {
        if (item.value) {
          onSelectTeam(item.value);
        }
      }}
      onSearch={value => {
        setTeamsQuery(value);
      }}
      selectedItem={
        selectedTeam
          ? {
              key: selectedTeam.id,
              label: selectedTeam.name,
              value: selectedTeam,
            }
          : {
              key: "empty-team",
              label: "",
              value: null,
            }
      }
    >
      {children}
    </Combobox>
  );
});
