import styled from "styled-components";
import { gridSpacePx } from "~/styles/gridSpace";

export const PopoverMenuList = styled.ul<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${gridSpacePx(1)};
  margin: 0;
  padding: ${gridSpacePx(1)} 0;
  max-height: ${gridSpacePx(45)};
  overflow-y: ${props => (props.isOpen ? "auto" : "hidden")};

  // Prevent the height of the list from rendering out of the viewport
  @media (max-height: ${gridSpacePx(120)}) {
    max-height: ${gridSpacePx(37)};
  }
`;
