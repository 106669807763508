import React, { useEffect, useMemo, useRef, useState } from "react";
import type { Team, User } from "shared/types";
import { observer } from "mobx-react-lite";
import { Combobox, type ComboboxItemList } from "~/components/Combobox";
import { debounce } from "~/utils/debounce";
import { useUsers } from "~/queries/useUsers";
import type { PopoverMenuItemShape } from "~/components/Popover/PopoverMenuItem";
import { useAssignees } from "~/hooks/useAssignees";
import { store } from "~/store";
import { UserAvatar } from "../UserAvatar";

const unassignedUser = {
  id: null,
  name: "Unassigned",
  teams: [],
} as unknown as User;

interface UserInputControlProps extends React.PropsWithChildren {
  /** Callback when a user is selected. Value is null when the unassigned user is selected. */
  onSelectUser: (selectedUser: User | null) => void;
  /** The currently selected user. */
  selectedUser: User | null;
  /** The current selected team. */
  selectedTeam: Team | null;
}

function userToItem(user: User, showFullUserNames: boolean = true): PopoverMenuItemShape<User> {
  let label: string;
  let sublabel: string;

  if (showFullUserNames || user.name === "Unassigned") {
    label = user.name;
    sublabel = user.displayName;
  } else {
    label = user.displayName;
    sublabel = user.name;
  }

  return {
    image: <UserAvatar name={user.name} modelId={user.id} src={user.avatarUrl} size="tiny" />,
    key: user.id,
    label,
    sublabel,
    value: user,
  };
}

/**
 * Button and context menu for selecting a team in the user's organization.
 */
export const UserInputControl = observer(function UserInputControl_(props: UserInputControlProps) {
  const { children, onSelectUser, selectedUser, selectedTeam } = props;

  const [userQuery, setUserQuery] = useState<string>("");

  const userSearch = useUsers(userQuery);
  const previousTeamId = useRef<string | undefined>(selectedTeam?.id);

  // A partitioned list of users in the selected team and users in other teams
  const assignees = useAssignees({
    currentUser: store.user,
    selectedTeam,
    selectedUser,
    userQuery,
  });

  const debouncedUserSearch = useMemo(() => {
    return debounce((query: string) => {
      setUserQuery(query);
    }, 250);
  }, []);

  // When the team changes and the selected user isn't in the team, clear the
  // selected user.
  useEffect(() => {
    if (previousTeamId.current !== selectedTeam?.id) {
      previousTeamId.current = selectedTeam?.id;

      const selectedUserIsInSelectedTeam = selectedUser?.teams.some(team => team.id === selectedTeam?.id);
      if (!selectedUserIsInSelectedTeam) {
        onSelectUser(null);
      }
    }
  }, [previousTeamId, selectedTeam?.id, selectedUser?.id]);

  const comboboxItemLists: ComboboxItemList<User>[] = [
    {
      key: "users-in-selected-team",
      items: assignees.inSelectedTeam.map(assignee => {
        return userToItem(assignee, store.userSettings?.showFullUserNames);
      }),
    },
  ];

  // Only show users in other teams if the selected team isn't private
  if (selectedTeam?.private === false) {
    comboboxItemLists.push({
      key: "users-in-other-teams",
      label: "Users from other teams",
      items: assignees.inOtherTeams.map(assignee => {
        return userToItem(assignee, store.userSettings?.showFullUserNames);
      }),
    });
  }

  return (
    <Combobox
      isLoading={userSearch.isLoading}
      itemLists={comboboxItemLists}
      onSelect={item => {
        onSelectUser(item.value);
      }}
      onSearch={debouncedUserSearch}
      placeholder="Assign to…"
      selectedItem={
        selectedUser
          ? {
              key: selectedUser.id,
              label: selectedUser.name,
              sublabel: selectedUser.displayName,
              image: (
                <UserAvatar
                  name={selectedUser.name}
                  modelId={selectedUser.id}
                  src={selectedUser.avatarUrl}
                  size="tiny"
                />
              ),
              value: selectedUser,
            }
          : {
              image: <UserAvatar name={unassignedUser.name} size="tiny" />,
              key: unassignedUser.id,
              label: unassignedUser.name,
              value: unassignedUser,
            }
      }
    >
      {children}
    </Combobox>
  );
});
