import React, { useState } from "react";
import { Priority } from "shared/types";
import { Priority0Icon } from "@linear/orbiter/icons/base/Priority0Icon";
import { Priority1Icon } from "@linear/orbiter/icons/base/Priority1Icon";
import { Priority2Icon } from "@linear/orbiter/icons/base/Priority2Icon";
import { Priority3Icon } from "@linear/orbiter/icons/base/Priority3Icon";
import { Priority4Icon } from "@linear/orbiter/icons/base/Priority4Icon";
import { Combobox } from "~/components/Combobox";

interface PriorityInputControlProps extends React.PropsWithChildren {
  /** Callback when a priority option is selected. */
  onSelectPriority: (priority: PriorityOption | null) => void;
  /** The currently selected priority option. */
  selectedPriority: PriorityOption | null;
}

/**
 * Interface for an option in the priority combobox.
 */
export interface PriorityOption {
  /** The icon associated with the priority. */
  icon: React.ReactNode;
  /** The label associated with the priority. */
  name: string;
  /** The value of the priority option. */
  value: Priority;
}

/** All available priority options. */
const priorityOptions: PriorityOption[] = [
  { icon: <Priority0Icon />, name: "No priority", value: Priority.None },
  { icon: <Priority1Icon />, name: "Urgent", value: Priority.Urgent },
  { icon: <Priority2Icon />, name: "High", value: Priority.High },
  { icon: <Priority3Icon />, name: "Medium", value: Priority.Medium },
  { icon: <Priority4Icon />, name: "Low", value: Priority.Low },
];

/**
 * Converts a priority value to a priority option.
 *
 * @param priority The priority value to convert.
 * @returns The priority option.
 */
export function priorityToPriorityOption(priority: Priority): PriorityOption {
  const foundPriority = priorityOptions.find(priorityOption => priorityOption.value === priority);

  if (!foundPriority) {
    throw new Error(`Invalid priority: ${priority}`);
  }

  return foundPriority;
}

/**
 * Button and context menu for selecting an issue priority.
 */
export function PriorityInputControl(props: PriorityInputControlProps) {
  const { children, onSelectPriority, selectedPriority } = props;

  const [displayedPriorityOptions, setDisplayedPriorityOptions] = useState<PriorityOption[]>(priorityOptions);

  function onPrioritySearch(query: string) {
    if (!query) {
      setDisplayedPriorityOptions(priorityOptions);
      return;
    }

    const filteredOptions = priorityOptions.filter(option => option.name.toLowerCase().includes(query.toLowerCase()));
    setDisplayedPriorityOptions(filteredOptions);
  }

  return (
    <Combobox
      items={displayedPriorityOptions.map(priorityOption => ({
        image: priorityOption.icon,
        key: priorityOption.name,
        label: priorityOption.name,
        value: priorityOption,
      }))}
      placeholder="Change priority…"
      onSelect={item => {
        onSelectPriority(item.value);
      }}
      onSearch={onPrioritySearch}
      selectedItem={
        selectedPriority
          ? {
              key: selectedPriority.name,
              image: selectedPriority.icon,
              label: selectedPriority.name,
              value: selectedPriority,
            }
          : {
              image: <Priority0Icon />,
              key: "No priority",
              label: "No priority",
              value: null,
            }
      }
    >
      {children}
    </Combobox>
  );
}
