/** Helper for keyboard functionality. */
export class KeyboardHelper {
  /**
   * Returns whether the mod key for the given keyboard event has been pressed. This is Command on Mac and Ctrl on
   * Windows and Linux.
   *
   * @param event The event to check
   * @param checkAsKeycode When true, check is performed using "key" property. It should be used when we need to react on keyup event.
   * @returns True if the mod key was pressed, false otherwise.
   */
  public static isModKey(
    event: KeyboardEvent | React.KeyboardEvent | Pick<KeyboardEvent, "key" | "metaKey" | "ctrlKey">,
    checkAsKeycode = false
  ): boolean {
    if (checkAsKeycode) {
      return isAppleDevice() ? event.key === "Meta" : event.key === "Control";
    }
    return isAppleDevice() ? event.metaKey : event.ctrlKey;
  }

  /**
   * Returns whether the given key is being pressed while not composing a character.
   *
   * @param event The event to check
   * @param key The key to check
   * @returns True if the key was pressed without composition open, false otherwise.
   */
  public static isKey(event: KeyboardEvent | React.KeyboardEvent, key: string): boolean {
    const isComposing =
      ("isComposing" in event && event.isComposing) || ("nativeEvent" in event && event.nativeEvent.isComposing);
    return event.key === key && !isComposing;
  }

  /**
   * Returns whether the Escape key is being pressed while not composing a character. See:
   * https://linear.app/linear/issue/USP-3607/esc-key-while-inputting-text
   *
   * Note that macOS "Accent menu" is not considered to be composing when first opened, so the Escape key will still be
   * considered if you open the accent menu and then press Escape without first using arrows to navigate the menu.
   *
   * @param event The event to check
   * @returns True if the Escape key was pressed, false otherwise.
   */
  public static isEscape(event: KeyboardEvent | React.KeyboardEvent): boolean {
    return KeyboardHelper.isKey(event, "Escape");
  }

  /**
   * Returns whether the Enter key is being pressed while not composing a character.
   *
   * @param event The event to check
   * @returns True if the Enter key was pressed, false otherwise.
   */
  public static isEnter(event: KeyboardEvent | React.KeyboardEvent): boolean {
    return KeyboardHelper.isKey(event, "Enter");
  }

  /**
   * Returns whether the Space key is being pressed while not composing a character.
   *
   * @param event The event to check
   * @returns True if the Space key was pressed, false otherwise.
   */
  public static isSpace(event: KeyboardEvent | React.KeyboardEvent): boolean {
    return KeyboardHelper.isKey(event, " ");
  }

  /**
   * Check if any modifiers are pressed.
   *
   * @param event the event to check.
   * @returns true if any modifier (ctrl, shift, cmd, option) is pressed.
   */
  public static hasAnyModifier(event: KeyboardEvent | React.KeyboardEvent): boolean {
    return event.ctrlKey || event.shiftKey || event.metaKey || event.altKey;
  }

  /** User displayable system modifier key symbol. */
  public static get systemModKeyText(): string {
    return isAppleDevice() ? "⌘" : "Ctrl";
  }

  /** User displayable system alt key symbol. */
  public static get systemAltKeyText(): string {
    return isAppleDevice() ? "⌥" : "Alt";
  }
}

const isAppleDevice = () => {
  return typeof window !== "undefined" && /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);
};
