import React from "react";
import styled from "styled-components";
import type { Issue } from "shared/types";
import { observer } from "mobx-react-lite";
import { Flex } from "@linear/orbiter/components/Flex";
import { LoadingIcon } from "@linear/orbiter/icons/base/LoadingIcon";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { WorkflowStateIcon } from "~/components/WorkflowStateIcon";
import { IssueIdentifier } from "~/components/IssueIdentifier";
import { TextTitle } from "~/components/TextTitle";
import { useLinkIssue } from "~/queries/useLinkIssue";
import { uiFigma } from "~/store/UIFigma";
import { LinkButton } from "./LinkButton";

interface IssueToLinkListItemProps {
  issue: Issue;
  maxIssueIdentifierCharacters?: number;
  onIssueLinked: (linkedIssue: Issue) => void;
  onIssueSelect: (issue: Issue) => void;
}

export const IssueToLinkListItem = observer(function IssueToLinkListItem_(props: IssueToLinkListItemProps) {
  const { issue, maxIssueIdentifierCharacters = 0, onIssueSelect, onIssueLinked } = props;

  const linkIssue = useLinkIssue({
    onIssueLinked(linkedIssue) {
      onIssueLinked(linkedIssue);
    },
  });

  // Pad the issue identifier so that the issue title is aligned
  const issueIdentifierPadding = "".padEnd(Math.max(maxIssueIdentifierCharacters - issue.identifier.length, 0), " ");

  const isLoading = linkIssue.isMutating || linkIssue.isLinkingIssue;

  return (
    <IssueToLinkListItemContainer
      className="flex items-center"
      onClick={() => {
        onIssueSelect(issue);
      }}
    >
      <Flex align="center" key={issue.id} gap={gridSpace(2)}>
        <WorkflowStateIcon state={issue.state} />
        <Flex>
          <IssueIdentifier url={issue.url}>{issue.identifier}</IssueIdentifier>
          <code>
            <pre className="m-0 p-0">{issueIdentifierPadding}</pre>
          </code>
        </Flex>
        <TextTitle truncate className="flex-grow-1">
          {issue.title}
        </TextTitle>
        <LinkIssueButton
          disabled={isLoading}
          icon={isLoading ? <LoadingIcon /> : null}
          onlyIcon={isLoading}
          aria-label="Link issue"
          aria-busy={isLoading}
          onClick={async e => {
            e.stopPropagation();

            await linkIssue.trigger({
              file: uiFigma.file,
              fileKey: uiFigma.fileKey,
              issue,
              page: uiFigma.currentPage,
              selection: uiFigma.selection,
            });
          }}
        >
          Link
        </LinkIssueButton>
      </Flex>
    </IssueToLinkListItemContainer>
  );
});

const LinkIssueButton = styled(LinkButton)`
  align-self: flex-end;
  color: var(--color-accent);
  margin-left: ${gridSpacePx(2)};
  font-weight: var(--font-weight-bold);
`;

const IssueToLinkListItemContainer = styled.div`
  border-bottom: 1px solid var(--figma-color-border);
  padding: ${gridSpacePx(3.5)} var(--panel-x-padding);
  position: relative;

  &:hover {
    background-color: var(--figma-color-bg-hover);
  }

  &:last-child {
    border-bottom: none;
  }

  /* Overlays the border on the left side to give the item a gap */
  &:before {
    background: var(--figma-color-bg);
    bottom: -1px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: ${gridSpacePx(9.5)};
  }
`;
