import { type BaseMessage, Message } from "./index";

interface AuthenticationMessageData {
  accessToken: string | undefined;
}

export class AuthenticationMessage implements BaseMessage {
  public readonly message = Message.AUTHENTICATION;
  public constructor(public readonly data: AuthenticationMessageData) {}
}
