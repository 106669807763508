import React from "react";
import { observer } from "mobx-react-lite";
import type { User } from "shared/types";
import { store } from "~/store";

interface UserNameProps {
  /** The user to display a name for. */
  user?: User;
}

/**
 * Displays a user's name in the format specified by the user's settings.
 *
 * @param props {UserNameProps} The component props.
 */
export const UserName = observer(function UserName_(props: UserNameProps) {
  const { user } = props;
  const showFullUserNames = store.userSettings?.showFullUserNames ?? false;

  if (!user) {
    return null;
  }

  if (showFullUserNames) {
    return <>{user.name}</>;
  } else {
    return <>{user.displayName}</>;
  }
});
