import { TeamFragment } from "~/queries/fragments/TeamFragment";
import { UserFragment } from "~/queries/fragments/UserFragment";

/* A template string fragment for fetching Issues across the application. */
export const IssueFragment = `
  assignee {
    ${UserFragment}
  }
  attachments {
    edges {
      node {
        id
        title
        sourceType
        url
      }
    }
  }
  createdAt
  creator {
    id
  }
  cycle {
    name
    number
  }
  description
  id
  identifier
  priority
  priorityLabel
  project {
    color
    id
    name
  }
  title
  state {
    id
    color
    name
    position
    team {
      states {
        edges {
          node {
            color
            name
            type
          }
        }
      }
    }
    type
  }
  team {
    ${TeamFragment}
  }
  url
`;
