import type { IssueLinkFromAttachment } from "shared/IssueLink";
import useSWRMutation from "swr/mutation";
import type { FetchedIssue } from "~/types";
import { fetchLinear } from "~/utils/fetchLinear";

const RenameIssueAttachment = `
  mutation RenameIssueAttachment(
    $attachmentId: String!,
    $title: String!,
    $subtitle: String
  ) {
    attachmentUpdate(
      id: $attachmentId,
      input: {
        title: $title,
        subtitle: $subtitle
      }
    ) {
      success
    }
  }
`;

interface RenameIssueAttachmentReturn {
  attachment: {
    issue: FetchedIssue;
  };
}

/**
 * Mutation that updates attachments with new titles and subtitles.
 */
export function useRenameIssueAttachments() {
  return useSWRMutation(
    RenameIssueAttachment,
    async (
      query,
      {
        arg,
      }: {
        arg: {
          issueLinks: IssueLinkFromAttachment[];
        };
      }
    ) => {
      const { issueLinks } = arg;

      const renamedAttachmentRequests = issueLinks.map(async issueLink => {
        const response = await fetchLinear<RenameIssueAttachmentReturn>([
          query,
          {
            attachmentId: issueLink.attachmentId,
            subtitle: issueLink.node.type !== "PAGE" ? issueLink.node.name : undefined,
            title: `${issueLink.file.name}/${issueLink.page.name}`,
          },
        ]);
        return response;
      });

      return await Promise.all(renamedAttachmentRequests);
    }
  );
}
