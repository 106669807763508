import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { BackArrowIcon } from "@linear/orbiter/icons/figma/BackArrowIcon";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { LargeIconButton } from "./IconButton";

interface AddIssueNestedHeaderProps extends React.PropsWithChildren {
  onBack: () => void;
}

/**
 * Header for screens in the add issue flow that are nested.
 */
export function AddIssueNestedHeader(props: AddIssueNestedHeaderProps) {
  const { children, onBack } = props;

  return (
    <HeaderFlex align="center" gap={gridSpace(2)}>
      <LargeIconButton inset="left" onClick={onBack} aria-label="Go back">
        <BackArrowIcon />
      </LargeIconButton>
      <div className="flex-grow-1 text-center">{children}</div>
      <HiddenIconButton inset="right" aria-hidden aria-label="Go back">
        <BackArrowIcon />
      </HiddenIconButton>
    </HeaderFlex>
  );
}

/** Centers the header text by mirroring the same back button on the right-side. */
const HiddenIconButton = styled(LargeIconButton)`
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
`;

const HeaderFlex = styled(Flex)`
  margin: ${gridSpacePx(2)} var(--panel-x-padding);
`;
