const gridUnit = 4;

/**
 * Helper for consistent pixel spacing in grid units.
 */
export function gridSpacePx(spacing: number): string {
  return `${spacing * gridUnit}px`;
}

export function gridSpace(spacing: number): number {
  return spacing * gridUnit;
}
