import * as React from "react";

export function ComponentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" aria-hidden {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.80515 2.84781L5.99417 0.667969L8.18319 2.84781L5.99417 5.02766L3.80515 2.84781ZM2.84014 8.18716L0.660156 5.99828L2.84014 3.8094L5.02012 5.99828L2.84014 8.18716ZM8.18704 9.153L5.99801 11.3328L3.80899 9.153L5.99801 6.97315L8.18704 9.153ZM11.3304 5.99955L9.15044 3.81067L6.97046 5.99955L9.15044 8.18843L11.3304 5.99955Z"
        fill="currentColor"
      />
    </svg>
  );
}
