import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export function SearchIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <svg>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.82264 10.8833C8.89957 11.5841 7.74835 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1C9.53757 1 12 3.46243 12 6.5C12 7.74835 11.5841 8.89957 10.8833 9.82264L14.7803 13.7197C15.0732 14.0126 15.0732 14.4874 14.7803 14.7803C14.4874 15.0732 14.0126 15.0732 13.7197 14.7803L9.82264 10.8833ZM10.5 6.5C10.5 8.70914 8.70914 10.5 6.5 10.5C4.29086 10.5 2.5 8.70914 2.5 6.5C2.5 4.29086 4.29086 2.5 6.5 2.5C8.70914 2.5 10.5 4.29086 10.5 6.5Z"
        />
      </svg>
    </Icon>
  );
}
