import type { Theme } from "@linear/orbiter/styles/Theme";
import { lightThemeRefresh } from "@linear/orbiter/styles/themes/lightThemeRefresh";
const lightTheme = lightThemeRefresh();
/**
 * Orbiter theme using CSS variables for Figma.
 */
export const figmaTheme: Theme = {
  ...lightTheme,
  color: {
    ...lightTheme.color,
    bgBase: "var(--figma-color-bg)",
    bgBaseHover: "var(--figma-color-bg-hover)",
    bgBorder: "var(--figma-color-border)",
    bgSelected: "var(--figma-color-bg-selected)",
    bgSelectedBorder: "var(--figma-color-border-selected)",

    controlSelectedBg: "var(--figma-color-bg-hover)",

    labelBase: "var(--figma-color-text)",
    labelMuted: "var(--figma-color-text-secondary)",
    labelFaint: "var(--figma-color-text-tertiary)",
    labelTitle: "var(--figma-color-text)",

    redBase: "var(--figma-color-text-danger)",
  },
};
