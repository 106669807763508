import { toJS } from "mobx";
import type { IssueLink } from "shared/IssueLink";

/** Helper class for working with Issue Links. */
export class IssueLinksHelper {
  /**
   * Converts an observed or non-observed array of observed IssueLinks to a
   * plain JS array. This can be necessary when passing issue links between the
   * plugin and UI.
   *
   * @param issueLinks An array of IssueLinks.
   */
  public static toJS(issueLinks: IssueLink[]) {
    return toJS(issueLinks.map(issueLink => toJS(issueLink)));
  }
}
