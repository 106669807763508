import * as React from "react";

export function FilterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentColor" aria-hidden {...props}>
      <path
        d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
        strokeOpacity="0.9"
      />
      <path d="M4 5.5H12" strokeOpacity="0.9" />
      <path d="M5 8H11" strokeOpacity="0.9" />
      <path d="M6 10.5H10" strokeOpacity="0.9" />
    </svg>
  );
}
