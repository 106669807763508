import React, { useMemo, useRef, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { useSelect } from "downshift";
import styled from "styled-components";
import { gridSpace } from "~/styles/gridSpace";
import { panelXPadding } from "~/styles/panelXPadding";
import { PopoverMenu } from "./Popover/PopoverMenu";
import { PopoverMenuList } from "./Popover/PopoverMenuList";
import { PopoverMenuItem, type PopoverMenuItemShape } from "./Popover/PopoverMenuItem";

interface FilterAndSortProps<FilterValue, SortValue> extends React.PropsWithChildren {
  /** Styling and anchoring props for the popover content. */
  contentProps?: Popover.PopperContentProps;
  /** A list of filters to display. */
  filters: PopoverMenuItemShape<FilterValue>[];
  /** The subset of filters that are currently selected. */
  selectedFilters: PopoverMenuItemShape<FilterValue>[];
  /** A list of sorters to display. */
  sorters: PopoverMenuItemShape<SortValue>[];
  /** The sorter that is currently selected. */
  selectedSorter: PopoverMenuItemShape<SortValue>;
  /** Callback when a filter has been selected. */
  onFiltersChange: (selectedFilters: PopoverMenuItemShape<FilterValue>[]) => void;
  /** Callback when a sorter has been selected. */
  onSortChange: (selectedSort: PopoverMenuItemShape<SortValue>) => void;
}

export function FilterAndSort<FilterValue, SortValue>(props: FilterAndSortProps<FilterValue, SortValue>) {
  const { children, contentProps, filters, sorters, selectedSorter, selectedFilters, onFiltersChange, onSortChange } =
    props;
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const items = useMemo(() => {
    return [...filters, ...sorters];
  }, [filters, sorters]);

  const { getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex, setHighlightedIndex } = useSelect({
    items,
    isOpen,
    onSelectedItemChange(change) {
      if (!change.selectedItem) {
        return;
      }

      const isFilter = filters.includes(change.selectedItem as PopoverMenuItemShape<FilterValue>);
      if (isFilter) {
        const selectedFilter = change.selectedItem as PopoverMenuItemShape<FilterValue>;
        if (selectedFilters.includes(selectedFilter)) {
          onFiltersChange(selectedFilters.filter(filter => filter.key !== change.selectedItem?.key));
        } else {
          onFiltersChange([...selectedFilters, selectedFilter]);
        }
      } else {
        const newSorter = change.selectedItem as PopoverMenuItemShape<SortValue>;
        onSortChange(newSorter);
      }

      // Reselect the highlighted item
      const selectedHighlightedIndex = items.findIndex(item => item.key === change.selectedItem?.key);
      setHighlightedIndex(selectedHighlightedIndex);
    },
    selectedItem: null,
  });

  const menuProps = getMenuProps(undefined, {
    suppressRefError: true,
  });

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger
        asChild
        {...getToggleButtonProps({
          tabIndex: undefined,
          onBlur(e) {
            // Prevent Downshift from closing the popover when Radix focuses it
            // after the popover is opened.
            if (contentRef.current?.contains(e.relatedTarget)) {
              e.target.focus();
            } else {
              setIsOpen(false);
            }
          },
        })}
      >
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="start"
          asChild
          collisionPadding={panelXPadding()}
          side="bottom"
          sideOffset={gridSpace(1)}
          ref={contentRef}
          {...contentProps}
        >
          {/*
           * Wrapper around motion prevents Radix from assuming the popover
           * is off screen and closed.
           */}
          <div
            style={{
              zIndex: 2,
            }}
          >
            <PopoverMenu isOpen={isOpen}>
              <PopoverMenuList isOpen={isOpen} {...menuProps}>
                {filters.map((filter, index) => {
                  const isSelectedFilter = selectedFilters.includes(filter);
                  return (
                    <PopoverMenuItem
                      {...filter}
                      isHighlighted={highlightedIndex === index}
                      isSelected={isSelectedFilter}
                      itemProps={getItemProps({
                        item: filter,
                        index,
                      })}
                      key={filter.key}
                    />
                  );
                })}
              </PopoverMenuList>
              <SortMenuList isOpen={isOpen} {...menuProps}>
                {sorters.map((sorter, index) => (
                  <PopoverMenuItem
                    {...sorter}
                    itemProps={getItemProps({ item: sorter, index: index + filters.length })}
                    key={sorter.key}
                    isSelected={selectedSorter === sorter}
                    isHighlighted={highlightedIndex === index + filters.length}
                  />
                ))}
              </SortMenuList>
            </PopoverMenu>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const SortMenuList = styled(PopoverMenuList)`
  border-top: 1px solid var(--figma-color-border);
`;
