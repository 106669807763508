import styled from "styled-components";
import { Button } from "@linear/orbiter/components/Button";
import { gridSpacePx } from "~/styles/gridSpace";

/**
 * A big 'ol button for confirming a form.
 */
export const FormButton = styled(Button)`
  background-color: var(--color-accent);
  border-radius: 6px;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 1); /* Always use light text on success button. */
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  height: ${gridSpacePx(8)};
  padding: 0 11px;

  &:not(:disabled):hover {
    background-color: var(--color-accent-hover);
  }

  &:not(:disabled):active {
    background-color: var(--color-accent-hover);
  }
`;
