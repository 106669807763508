import useSWR, { type SWRResponse } from "swr";
import type { Project } from "shared/types";
import { SortHelper } from "@linear/common/utils/SortHelper";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import type { Connection } from "~/types";

const ProjectsInTeamQuery = `
  query ProjectsInTeam($teamId: String!, $nameFilter: String) {
    team(id: $teamId) {
      projects(filter: {
        name: { startsWithIgnoreCase: $nameFilter }
      }
    ) {
      edges {
        node {
          color
          id
          name
        }
      }
    }
  }
}
`;

/**
 * Find users in a team by name or display name.
 */
export function useProjectsInTeam(teamId: string | null, nameFilter?: string): SWRResponse<Project[]> {
  return useSWR(teamId ? [ProjectsInTeamQuery, { teamId, nameFilter }] : null, async args => {
    const response = await fetchLinear<{
      team: {
        projects: Connection<Project>;
      };
    }>(args);

    const projects = flattenConnection(response.team.projects);
    return SortHelper.natural(projects, "name");
  });
}
