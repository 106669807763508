import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

export function Priority4Icon(props: IconProps) {
  return (
    <Icon {...props} aria-label="Low Priority">
      <svg>
        <rect x="1.5" y="8" width="3" height="6" rx="1" />
        <rect x="6.5" y="5" width="3" height="9" rx="1" fillOpacity="0.4" />
        <rect x="11.5" y="2" width="3" height="12" rx="1" fillOpacity="0.4" />
      </svg>
    </Icon>
  );
}
