/**
 * Messages sent between the Figma plugin and the UI.
 */
export enum Message {
  /** Message to initialize the UI. */
  INIT = "INIT",

  /** Message the UI to open a URL in the browser. */
  OPEN_IN_BROWSER = "OPEN_IN_BROWSER",

  /**
   * Message the UI that attachments with Figma links can't be found and need
   * to be removed.
   */
  ATTACHMENTS_WITH_REMOVED_NODES = "ATTACHMENTS_WITH_REMOVED_NODES",

  /** Message that includes the current access token or undefined to clear it. */
  AUTHENTICATION = "AUTHENTICATION",

  /**
   * For debugging, resets all plugin storage.
   */
  CLEAR_STORAGE = "CLEAR_STORAGE",

  /** The current page in Figma. */
  CURRENT_PAGE = "CURRENT_PAGE",

  /** Information about the file in Figma. */
  CURRENT_FILE = "CURRENT_FILE",

  /** Associates a Linear issue with nodes on the Figma canvas. */
  LINK_ISSUE = "LINK_ISSUE",

  /** Map of issue ids to linked nodes on the Figma canvas. */
  ISSUE_LINKS = "ISSUE_LINKS",

  /** Removes a list of issue links. */
  REMOVE_ISSUE_LINKS = "REMOVE_ISSUE_LINKS",

  /** Message the UI that nodes in a linked issue have been removed from Figma. */
  REMOVED_ISSUE_LINK_NODES = "REMOVED_ISSUE_LINK_NODES",

  /** Message the UI that nodes in a linked issue have been renamed in Figma. */
  RENAMED_ISSUE_LINK_NODES = "RENAMED_ISSUE_LINK_NODES",

  /**
   * Message from the UI instructing Figma to update the selection to a given
   * list of nodes.
   * */
  SELECT_NODES = "SELECT_NODES",

  /** Message from the plugin that nodes in the canvas have been selected. */
  SELECTED_NODES = "SELECTED_NODES",
  /** Message from the plugin that page has been selected. */
  SELECTED_PAGE = "SELECTED_PAGE",

  /** Updates issue links for all issues found with Figma URLs. */
  UPDATE_ALL_ISSUE_LINKS = "UPDATE_ALL_ISSUE_LINKS",

  /**
   * Updates issue links with the issues linked to nodes on the current Figma
   * page.
   */
  UPDATE_ISSUE_LINKS = "UPDATE_ISSUE_LINKS",

  /** Updates UI that plugin data has been synced. */
  UPDATED_ALL_ISSUE_LINKS = "UPDATED_ALL_ISSUE_LINKS",

  /** UI has finished it's initial render. */
  UI_READY = "UI_READY",
}

export interface BaseMessage {
  message: Message;
  data: unknown;
}
