import useSWR, { type SWRResponse } from "swr";
import type { User } from "shared/types";
import { SortHelper } from "@linear/common/utils/SortHelper";
import { fetchLinear } from "~/utils/fetchLinear";
import { flattenConnection } from "~/utils/flattenConnection";
import type { Connection, FetchedUser } from "~/types";

const UsersQuery = `
  query UsersQuery($nameFilter: String) {
    users(filter: {
      or: [
        { name: { startsWithIgnoreCase: $nameFilter } }
        { displayName: { startsWithIgnoreCase: $nameFilter } }
      ]
    }) {
      edges {
        node {
          avatarUrl
          displayName
          id
          name
          teams {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

interface UsersQueryReturn {
  users: Connection<FetchedUser>;
}

/**
 * Searches for users by name or display name.
 *
 * @param nameFilter The name or display name to search for. Explicitly pass
 * null to disable the query.
 */
export function useUsers(nameFilter: string | null): SWRResponse<User[]> {
  return useSWR(
    [UsersQuery, { nameFilter }],
    async args => {
      const response = await fetchLinear<UsersQueryReturn>(args);

      const users = flattenConnection(response.users).map(user => {
        return {
          ...user,
          teams: flattenConnection(user.teams),
        } as User;
      });

      return SortHelper.natural(users, "name");
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );
}
