import React from "react";
import ReactMarkdown from "react-markdown";
import styled, { css } from "styled-components";
import remarkGfm from "remark-gfm";
import reset from "@linear/orbiter/styles/reset";
import { fontSize } from "@linear/orbiter/styles/mixins";
import { gridSpacePx } from "~/styles/gridSpace";
import { openExternalLink } from "~/utils/links";

export interface MarkdownProps {
  /** Additional styles on the Markdown renderer. */
  className?: string;
  /** The markdown content. */
  children: string;
  /** Disable interactions with hyperlinks. */
  disableLinks?: boolean;
  /** Styles the markdown content. */
  isStyled?: boolean;
}

/**
 * Basic Markdown component
 */
export function Markdown(props: MarkdownProps) {
  const { children, className, disableLinks, isStyled } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLAnchorElement;
    if (target?.href && target.href.startsWith("http")) {
      event.preventDefault();

      if (disableLinks !== true) {
        openExternalLink(target.href);
      }
    }
  };

  return (
    <div onClick={handleClick}>
      <StyledMarkdown isStyled={isStyled} className={className} children={children} remarkPlugins={[remarkGfm]} />
    </div>
  );
}

/** Reset styles for markdown */
const StyledMarkdown = styled(ReactMarkdown)<{ isStyled?: boolean }>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: ${fontSize("regular")};
  }

  a {
    color: var(--accent-color);
    text-decoration: underline;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  dl,
  ul,
  ol {
    padding-left: ${gridSpacePx(3)};
  }

  blockquote {
    border-left: ${gridSpacePx(1)} solid var(--figma-color-border);
    padding-left: ${gridSpacePx(2)};
  }

  pre {
    background-color: var(--figma-color-bg-hover);
    border-radius: ${gridSpacePx(1)};
    padding: ${gridSpacePx(2)};
    overflow: scroll;
  }

  code {
    background-color: var(--figma-color-bg-hover);
    border-radius: ${gridSpacePx(1)};
    padding: ${gridSpacePx(0.5)};
  }

  pre code {
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  dl,
  ol,
  ul,
  pre,
  table,
  blockquote {
    margin-top: 0;
    margin-bottom: 1.2em;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  th {
    font-weight: var(--font-weight-bold);
  }

  th,
  td {
    border: 1px solid var(--figma-color-border);
    padding: ${gridSpacePx(1)};
  }

  .contains-task-list {
    display: flex;
    flex-direction: column;
    gap: ${gridSpacePx(1)};
    list-style-type: none;
    padding-left: 0;
    // Better left-align the task list with other lists
    margin-left: ${gridSpacePx(-1)};
  }

  .task-list-item {
    align-items: center;
    display: flex;
    white-space: break-spaces; // Allow for spacing in interpolated text

    input {
      margin: 0 ${gridSpacePx(1)};
    }
  }

  *:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.isStyled === false
      ? css`
          ${reset}

          del {
            text-decoration: none;
          }

          em {
            font-style: normal;
          }

          a {
            text-decoration: none;
          }

          code,
          pre {
            font-family: var(--font-monospace);
          }
        `
      : undefined}
`;
