import * as React from "react";

export function ImageIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" aria-hidden {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4348 3.43475L3.43482 3.43475L3.43482 9.72777L5.58138 7.58121L5.93493 7.22765L6.28848 7.58121L12.142 13.4348H13.4348L13.4348 3.43475ZM3.43482 13.4348L3.43482 11.142L5.93493 8.64187L10.7278 13.4348L3.43482 13.4348ZM3.43482 2.43475C2.88253 2.43475 2.43481 2.88246 2.43481 3.43475V13.4348C2.43481 13.9871 2.88253 14.4348 3.43482 14.4348H13.4348C13.9871 14.4348 14.4348 13.9871 14.4348 13.4348V3.43475C14.4348 2.88247 13.9871 2.43475 13.4348 2.43475H3.43482ZM11.4348 6.43476C11.4348 6.98704 10.9871 7.43476 10.4348 7.43476C9.88252 7.43476 9.4348 6.98704 9.4348 6.43476C9.4348 5.88247 9.88252 5.43475 10.4348 5.43475C10.9871 5.43475 11.4348 5.88247 11.4348 6.43476ZM12.4348 6.43476C12.4348 7.53933 11.5394 8.43476 10.4348 8.43476C9.33023 8.43476 8.4348 7.53933 8.4348 6.43476C8.4348 5.33019 9.33023 4.43475 10.4348 4.43475C11.5394 4.43475 12.4348 5.33019 12.4348 6.43476Z"
        fill="currentColor"
      />
    </svg>
  );
}
