import * as Sentry from "@sentry/react";
import { GraphQLClient, ClientError } from "graphql-request";
import type { Variables } from "graphql-request/dist/types";
import { Config } from "~/config";
import { store } from "~/store";

type FetchLinearArgs = [query: string, variables?: Variables];

const graphQLClient = new GraphQLClient(Config.GRAPHQL_URL, {
  credentials: `include`,
});

/**
 * Main fetch function used for graphQL requests to the API.
 */
export async function fetchLinear<ResponseData>(args: FetchLinearArgs): Promise<ResponseData> {
  const [query, variables] = args;
  const headers = new Headers(
    store.accessToken
      ? {
          Authorization: `Bearer ${store.accessToken}`,
        }
      : {}
  );

  try {
    const response = await graphQLClient.request<ResponseData>(query, variables, headers);
    return response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    Sentry.captureException(e);

    const error = e instanceof ClientError ? e.response.errors?.[0] : undefined;

    if (error) {
      if (["AUTHENTICATION_ERROR"].includes(error.extensions?.code)) {
        store.logout();
      }

      throw new Error(error.extensions?.userPresentableMessage ?? error.message);
    } else {
      throw e;
    }
  }
}
