import { runInAction } from "mobx";
import { type IssueLink, type IssueLinkFromAttachment, IssueLinkType } from "shared/IssueLink";
import useSWRMutation from "swr/mutation";
import { store } from "~/store";
import { fetchLinear } from "~/utils/fetchLinear";

const RemoveLinkedIssue = `
  mutation RemoveLinkIssue(
    $attachmentId: String!,
  ) {
    attachmentDelete(
      id: $attachmentId
    ) {
      success
    }
  }
`;

interface RemoveLinkedIssueReturn {
  attachmentDelete: {
    success: boolean;
  };
}

/**
 * Mutation that removes attachments by ID.
 */
export function useRemoveIssueAttachments() {
  return useSWRMutation(
    RemoveLinkedIssue,
    async (
      query,
      {
        arg: attachmentIds,
      }: {
        arg: string[];
      }
    ) => {
      const removeAttachmentRequests = attachmentIds.map(async attachmentId => {
        const response = await fetchLinear<RemoveLinkedIssueReturn>([
          query,
          {
            attachmentId,
          },
        ]);
        return response;
      });

      await Promise.all(removeAttachmentRequests);
    }
  );
}

/** Finds all attachment IDs in a list of issue links and removes them. */
export function useRemoveIssueLinks() {
  const mutation = useRemoveIssueAttachments();

  return {
    ...mutation,
    async trigger(issueLinks: IssueLink[]) {
      const attachmentIds = issueLinks
        .filter(issueLink => {
          return issueLink.linkType === IssueLinkType.ATTACHMENT;
        })
        .map((issueLink: IssueLinkFromAttachment) => {
          return issueLink.attachmentId;
        });

      await mutation.trigger(attachmentIds);

      runInAction(() => {
        store.removeIssueLinks(issueLinks);
      });
    },
  };
}
