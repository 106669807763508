import type { Issue } from "shared/types";
import { type BaseMessage, Message } from "./Message";

export class UpdateAllIssueLinksMessage implements BaseMessage {
  public readonly message = Message.UPDATE_ALL_ISSUE_LINKS;
  public readonly data: Record<Issue["id"], Issue>;

  public constructor(data: Record<Issue["id"], Issue>) {
    this.data = data;
  }
}

export class UpdateIssueLinksMessage implements BaseMessage {
  public readonly message = Message.UPDATE_ISSUE_LINKS;
  public readonly data: Record<Issue["id"], Issue>;

  public constructor(data: Record<Issue["id"], Issue>) {
    this.data = data;
  }
}
