import { useContext } from "react";
import { type KeyboardEventListeners, KeyboardEventListenersContext } from "../context/KeyboardEventListeners";

/**
 * Hook to get current keyboard event listeners.
 */
export const useKeyboardEventListeners = (): KeyboardEventListeners => {
  const keyboardEventListeners = useContext(KeyboardEventListenersContext);
  if (!keyboardEventListeners) {
    throw Error("Trying to call useKeyboardEventListeners outside KeyboardEventListenersContext");
  }
  return keyboardEventListeners;
};
