import useSWRMutation from "swr/mutation";
import { Config } from "~/config";
import { fetchLinear } from "~/utils/fetchLinear";

const OAuthAuthStringChallenge = `
  mutation OauthAuthStringChallenge($appId: String! $scope: [String!]!) {
    oauthAuthStringChallenge(appId: $appId, scope: $scope) {
      success
      authString
    }
  }
`;

interface OAuthAuthStringChallengeReturn {
  oauthAuthStringChallenge: {
    success: boolean;
    authString: string;
  };
}

export function useOAuthAuthStringChallenge({ scope }: { scope: string[] }) {
  return useSWRMutation([OAuthAuthStringChallenge, { appId: Config.OAUTH_CLIENT_ID, scope }], async args => {
    const response = await fetchLinear<OAuthAuthStringChallengeReturn>(args);
    return response.oauthAuthStringChallenge;
  });
}
